import { useEffect, useState } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";

// redux
import { useSelector, useDispatch } from 'react-redux';
// import { login } from '../../redux/slices/loginSlice.js';

// custom hooks
// import useSnackbar from "../../SnackBar/useSnackBar.js";

import { loadingSVG } from '../../loadingSVG.js';

import config from '../../config.js';
let api = config.apiUrl;


export const ConfirmEmail = () => {

    let [params] = useSearchParams();
    //console.log('params', params);

    let emailToken = params.get('token');
    //console.log('email token', emailToken);

    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(false);
    const [redirecting, setRedirecting] = useState(false);

    const navigate = useNavigate();
    // const dispatch = useDispatch();
    // const snackbar = useSnackbar();


    useEffect(() => {

        if(response === 'validated') {
            //console.log('in response validated');

            setRedirecting(true);

            setTimeout(() => {
                navigate('/login');
              }, 4000);
          
        }

    },[response]);



    useEffect(() => {

        if(!emailToken) {
            // go to login if no token
            navigate('/login');
            return;
        }

        confirmEmailToken();
        

        async function confirmEmailToken() {

            setLoading(true);
    
            let url = api + `users/verifyEmail/${emailToken}`;
    
            try {
    
                let request = await fetch(url, {
                    method: "GET",
                    credentials: "include",
                });
        
                let response = await request.json();
                //console.log('response', response);
                setLoading(false);
        
                if(response.success) {
                    setResponse('validated');
        
                } else {
                    setResponse('unvalidated');
                }
    
            } catch(err) {
            setLoading(false);
            }
    
        }


    }, []);
      

    if(!emailToken) {
        return;
    }


    return (
        <div className="app-root">
            <div className="confirm-email-container">
                {/* <h1>Confirming Your Email</h1> */}
                { loading ? 
                <div className="loading" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <p>confirming...</p>
                    <div>{loadingSVG}</div>
                </div> : null }
                { response === false && null }
                { response === 'validated' && <p>Your email was validated!</p> }
                { response === 'unvalidated' &&
                    <div> 
                        <p>There was an issue validating your email</p>
                        <Link to={`/verifyEmail`}>Click here to get a new confirmation email</Link>
                    </div> }
                { redirecting && 
                <div className="loading" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <p>Redirecting you to the login page</p>
                    <div>{loadingSVG}</div>
                </div> }
            </div>
        </div>
    );

};