//modules
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addNode } from '../../../../redux/slices/nodeMapSlice.js';

//resources
import chevronLight from './chevronLight.svg';
import chevronDark from './chevronDark.svg';

//tooltip
import ToolTipComponent from '../../../../tooltip/ToolTipComponent.js';

// Control nodes
import { controlNodes } from '../../../../nodes/nodes.js';


//styles
import './ControlNodes.css';

export default function ControlNodes() {

    // effects
    //----------------------------------------------------------------------------------------------

    //redux
    const dispatch = useDispatch();

    // component logic
    //----------------------------------------------------------------------------------------------

    const [expanded, setExpanded] = useState(true);
    useEffect( () => {}, [expanded]);


    // const [isDropdownOpen, setIsDropdownOpen] = useState(false);


    // defined functions
    //----------------------------------------------------------------------------------------------

    function handleAddNode(e) {
        let template = e.currentTarget.getAttribute('template');
        //console.log('template', template);
        // let template = e.target.value;
        dispatch(addNode({template}));
    }


    const nodes = controlNodes;

    // component structure
    //----------------------------------------------------------------------------------------------

    return (
        <div className={'edit-menu-section-container'}>

            <div className={'edit-menu-section-label'} onClick={() => setExpanded(!expanded)}>
                <div className={'edit-menu-section-label-line'}></div>
                <img className={`edit-menu-section-label-icon ${!expanded ? 'edit-menu-section-label-icon-collapsed' : ''}`} src={chevronDark} alt={''}></img>
                <div className={'edit-menu-section-label-text'}>Control Nodes</div>
                <ToolTipComponent tooltip={'controlNodes'}/>
                <div className={'edit-menu-section-label-line'}></div>
            </div>

            <div className={`edit-menu-section-expanded ${!expanded ? 'edit-menu-section-collapsed' : ''}`}>
                
                { Object.keys(nodes).map( (key) => {

                    const node = nodes[key];
                    const templateId = key;
                    const category = node.template.category;
                    const title = node.title;
                    const description = node.description;
                    const icon = node.icon;

                    return (
                        <button className={`add-node-button add-${category}-node-button`} template={key} title={`Add ${title} node`} onClick={handleAddNode} key={key}>
                            <img className={'add-node-button-icon'} src={icon} alt={''}></img>
                            <div className={'add-node-button-text'}>{title}</div>
                        </button>
                    )
                })}

            </div>
        </div>
    );
}