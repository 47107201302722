// Components
import IoNumberField from './IoVariableFieldComponents/IoNumberField';
import IoTextField from './IoVariableFieldComponents/IoTextField';
import IoImageField from './IoVariableFieldComponents/IoImageField';
import IoAudioField from './IoVariableFieldComponents/IoAudioField';

// Styles
import './IoVariableField.css';

export default function IoVariableField({inputVariable, outputVariable}) {

    // Component logic
    //----------------------------------------------------------------------------------------------

    let variable = null;
    let ioType = null;

    if (typeof inputVariable !== 'undefined') {
        variable = inputVariable;
        ioType = 'input';
    }

    if (typeof outputVariable !== 'undefined') {
        variable = outputVariable;
        ioType = 'output';
    }
    
    // Component
    //----------------------------------------------------------------------------------------------

    return (
        <>
            { variable.showToUser ?
                <div className={'io-variable-field'}>
                    { variable.type === 'text' ? <IoTextField variable={variable} ioType={ioType} /> : null}
                    { variable.type === 'number' ? <IoNumberField variable={variable} ioType={ioType} /> : null}
                    { variable.type === 'image' ? <IoImageField variable={variable} ioType={ioType} /> : null}
                    { variable.type === 'audio' ? <IoAudioField variable={variable} ioType={ioType} /> : null}
                </div>
            : null }
        </>
    );

}