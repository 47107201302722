import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

// Reducers
import userReducer from './redux/slices/loginSlice.js';
import themeReducer from './redux/slices/themeSlice.js';
import snackbarReducer from './redux/slices/snackBarSlice.js';
import nodeMapReducer from './redux/slices/nodeMapSlice.js';
import requestReducer from './redux/slices/requestSlice.js';

// Storage Persistence
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Middleware
import loggingMiddleware from './redux/middleware/checkToken.js';

// const customStateReconciler = (inboundState, originalState, reducedState, persistConfig) => {
//   // Use the default state reconciliation strategy (autoMergeLevel2) from redux-persist
//   const autoMergeLevel2 = require('redux-persist/lib/stateReconciler/autoMergeLevel2').default;
//   const reconciledState = autoMergeLevel2(inboundState, originalState, reducedState, persistConfig);

//   // Set the requestItems array to an empty array on every page refresh
//   reconciledState.request.requestItems = [];

//   return reconciledState;
// };

// config for redux-persist
const persistConfig = {
    timeout: 100,
    key: 'root',
    storage,
    // stateReconciler: customStateReconciler,
    blacklist: ['request', 'nodeMap'] // Exclude the 'request' and 'nodeMap' slices from persisting
  };

  // Put all reducers here
const rootReducer = combineReducers({
    user: userReducer,
    theme: themeReducer,
    snackbar: snackbarReducer,
    nodeMap: nodeMapReducer,
    request: requestReducer
  });

const persistedReducer = persistReducer(persistConfig, rootReducer);


export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ 
    serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    }
  }).concat(loggingMiddleware),
});


export const persistor = persistStore(store);