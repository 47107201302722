  const dev = {
    apiUrl: "http://localhost:7000/api/v1/",
  };
  
  const prod = {
    apiUrl: "https://api.edison-ai.com/api/v1/",
  };

  const config = process.env.NODE_ENV === "production" ? prod : dev;
  
  export default config;