import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  theme: 'dark',
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {

    // doesn't actually mutate the state because it uses the Immer library,
    switchTheme: (state, action) => {
        state.theme = action.payload.theme
    },

  }

});


// Action creators are generated for each case reducer function
export const { switchTheme } = themeSlice.actions;
export default themeSlice.reducer;