// Icon
import icon from './icon.svg';

// Util
import { util } from '../../../nodeMapProcessing/util.js';

const title = 'Decision';
const description = '';

// JSON template
//----------------------------------------------------------------------------------------------------

// Node JSON template
const template = {
      
	category: "control",
    name: "decision",
    type: "decision",
    position: null,

    settings: {
      canEditInputs: false,
      canEditOutputs: true
    },

    contents: null,

    inputs: {

      "text match": {
        
        name: 'text match',
        dataType: 'string',
        requireContent: true,
        allowStreaming: true,
        contents: null,
      },

      "passthrough": {
        
        name: 'passthrough',
        dataType: null,
        requireContent: true,
        allowStreaming: true,
        contents: null,
      }
    },

    outputs: {

      "Output 1": {
        name: 'yes',
        type: null,
        contents: null,
        destinations: {},
        clearOnSend: true
      },

      "Output 2": {
        name: 'no',
        type: null,
        contents: null,
        destinations: {},
        clearOnSend: true
      }
    }
  }

// Processing function
//----------------------------------------------------------------------------------------------------

class process {

	// Callbacks
	//--------------------------------------------------

	constructor() {

		this.onUpdateCallback =     () => { console.warn('no onUpdate callback')};
		this.onStreamCallback =     () => { console.warn('no onStream callback')};
		this.onCompleteCallback =   () => { console.warn('no onComplete callback')};
		this.onWarningCallback =    () => { console.warn('no onWarning callback')};
		this.onErrorCallback =      () => { console.warn('no onError callback')};
		this.onGiveUpCallback =     () => { console.warn('no onGiveUp callback')};

	}

	// Set callback functions
	onUpdate(callback) { this.onUpdateCallback = callback; }
	onStream(callback) { this.onStreamCallback = callback; }
	onComplete(callback) { this.onCompleteCallback = callback; }
	onWarning(callback) { this.onWarningCallback = callback; }
	onError(callback) { this.onErrorCallback = callback; }
	onGiveUp(callback) { this.onGiveUpCallback = callback; }

	// Fire functions easily
	update(node) { this.onUpdateCallback(node); }
	stream(node) { this.onStreamCallback(node); }
	finish(node) { this.onCompleteCallback(node); }
	warn(message) { this.onWarningCallback(message); }
	error(message) { this.onErrorCallback(message); }
	giveUp(message) { this.onGiveUpCallback(message); }


	// Run node
	//--------------------------------------------------

	// Node processing function
	run (node, extras) {

      	try {

          	// Give up if required inputs aren't full
		    if (util.hasEmptyRequiredInputs(node)) {
				this.giveUp();
				return;
          	}

			// Get text-match and pass-through inputs
			const textMatch = util.convertToString(node.inputs['text match'].contents);
			const passthrough = node.inputs?.['passthrough']?.contents;

			let foundMatch = false;

			// Match textMatch against each output
			for (const outputId in node.outputs) {

				const output = node.outputs[outputId];
				let regexPattern = null;

				// Attempt to create a regex object from the output name
				try {
					regexPattern = output.name.startsWith('/') ? new RegExp(output.name.slice(1, -2), output.name.slice(-1)) : new RegExp(output.name, 'i');
				}
				
				catch (error) {
					console.warn('Invalid regular expression in output name:', output.name);
					regexPattern = new RegExp(output.name, 'i'); // Use the string as provided if not a valid regex
				}

				// Check if the contents match the regex
				if (regexPattern.test(textMatch)) {
					output.contents = passthrough;
					foundMatch = true;
				}
			}

			// Give up if no matches found
		    if (!foundMatch) {
				this.warn('found no matches');
				this.giveUp();
				return;
          	}

			// Clear inputs and finish node
			util.clearInputs(node);
			this.finish(node);
			
			return;
      	}

		catch (e) {
			this.error(e.message);
			this.giveUp();
			return;
		}
  	}
}

// Export
//----------------------------------------------------------------------------------------------------

export const decision = {
    template,
    process,
	icon,
	title,
	description
}