import { useSelector, useDispatch } from 'react-redux';
import { updateNodeSetting } from '../../../../../../redux/slices/nodeMapSlice.js';

export default function ModelSelect({nodeId, setting, value, label}) {

    const dispatch = useDispatch();

    const modelName = useSelector((state) => state.nodeMap.nodeMap.nodes[nodeId].settings.modelName);
    const availableModels = useSelector((state) => state.nodeMap.nodeMap.nodes[nodeId].settings.availableModels);

    // component logic
    //----------------------------------------------------------------------------------------------------

    if (!nodeId || !setting) {
        return null;
    }

    // defined functions
    //----------------------------------------------------------------------------------------------------

    function handleChangeNodeSetting(e) {
        const newValue = e.currentTarget.value;
        //console.log(`Changing '${nodeId}' setting '${setting}' to '${newValue}'`);
        dispatch(updateNodeSetting({nodeId, setting, newValue}));
    }

    // component
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={'node-setting-container'}>

            <label className={'node-setting-label'}>{label}</label>

            <select className={"model-select"} title="Select AI Model" value={value} onChange={handleChangeNodeSetting}>
                <option value={''}>Select Model</option>
                { availableModels.map((model) => { return <option value={model} key={model}>{titleCase(model)}</option> })}
            </select>
        </div>
    );

    function titleCase(str) {
        return str.replace(/\b\w/g, function (match) {
          return match.toUpperCase();
        });
    }
}