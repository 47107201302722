// Modules
import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateNodeSetting } from '../../../../../../../redux/slices/nodeMapSlice.js';

// Styles
import './TextBox.css';

export default function TextBox({nodeId, element, value}) {

    // Define important variables

    const setting = element.setting;
    const label = element.label;
    const title = element.title;
    const minLength = element.minLength;
    const maxLength = element.maxLength;

    // Correct empty value (causes issues)
    if (!value) {
        value = '';
    }

    // Define dispatch function
    const dispatch = useDispatch();
    
    // Updating node setting
    //----------------------------------------------------------------------------------------------------

    function handleChangeNodeSetting(e) {

        const newValue = e.currentTarget.value;
        dispatch(updateNodeSetting({nodeId, setting, newValue}));
    }

    // Component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={'node-setting-container'}>

            <label className={'node-setting-label'}>{label || ''}</label>

            <input

                className={'textbox'}
                title={title || label || setting || ''}
                type={'text'}
                value={value || ''}
                minLength={minLength || ''}
                maxLength={minLength || ''}

                onChange={handleChangeNodeSetting}

                style={{minWidth: `${value.length + 3}ch`}}
            ></input>

        </div>
    );
}