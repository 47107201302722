// Modules
import { useState, useEffect, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';

// Resources
import playIcon from './playIcon.svg';
import pauseIcon from './pauseIcon.svg';
import downloadIcon from './downloadIcon.svg';

// Styles
import './AudioPlayer.css';

export default function AudioPlayer({audioUrl}) {

    console.log(audioUrl);

    // Handle loading of audio
    //----------------------------------------------------------------------------------------------

    const audioRef = useRef(null);
    const wavesurferRef = useRef(null);

    useEffect(() => {
        audioRef.current = new Audio(audioUrl);
        return () => {
            audioRef.current.pause();
            audioRef.current = null;
        };
    }, [audioUrl]);


    // Handle play / pause behavior
    //----------------------------------------------------------------------------------------------

    const [playState, setPlayState] = useState(false);

    useEffect(() => {
        if (playState) { audioRef.current.play(); }
        else { audioRef.current.pause(); }
    }, [playState]);

    function handlePlayPauseClick(e) {
        if (!audioUrl) { return; }
        setPlayState(!playState);
    }


    // Manage rendering audio waveform
    //----------------------------------------------------------------------------------------------

    const audioTrackContainerRef = useRef(null);

    useEffect(() => {

        const wavesurfer = WaveSurfer.create({
            container: audioTrackContainerRef.current,
            waveColor: 'rgb(25, 25, 25)',
            progressColor: 'rgb(50, 50, 50)',
            minPxPerSec: 1,
            normalize: true,
            interact: false,
            audioRate: 1,
        });

        wavesurfer.load(audioUrl);
        wavesurferRef.current = wavesurfer;

        return () => {
            wavesurfer.destroy();
        }
    }, [audioUrl]);


    // Update waveform progress as audio plays
    //----------------------------------------------------------------------------------------------

    useEffect(() => {
        if (audioRef.current) {
            let intervalId = null;
    
            audioRef.current.ontimeupdate = () => {
                const currentTime = audioRef.current.currentTime;
                const totalTime = audioRef.current.duration;
                if (totalTime) {
                    const progress = currentTime / totalTime;
                    if (wavesurferRef.current) {
                        wavesurferRef.current.seekTo(progress);
                    }
    
                    if (progress === 1) {
                        setPlayState(false);
                    }
                }
            };
    
            if (playState) {
                intervalId = setInterval(() => {
                    const currentTime = audioRef.current.currentTime;
                    const totalTime = audioRef.current.duration;
                    const progress = currentTime / totalTime;
                    if (wavesurferRef.current) {
                        wavesurferRef.current.seekTo(progress);
                    }
                }, 25); // Update every 100ms (10 times per second)
            }
    
            return () => {
                if (audioRef.current) {
                    audioRef.current.ontimeupdate = null;
                }
                if (intervalId) {
                    clearInterval(intervalId);
                }
            };
        }
    }, [audioUrl, playState]);


    // Handle audio seek on click
    //----------------------------------------------------------------------------------------------

    const handleAudioSeek = (event) => {
        const container = document.querySelector('.audio-track-container');
        const clickPosition = event.pageX - container.getBoundingClientRect().left;
        const containerWidth = container.offsetWidth;
        const clickPositionInPercentage = clickPosition / containerWidth;
        const newCurrentTime = audioRef.current.duration * clickPositionInPercentage;
        audioRef.current.currentTime = newCurrentTime;

        if (!playState) { setPlayState(true); }
    }

    // Handle preview seek on mouse move
    //----------------------------------------------------------------------------------------------

    const handlePreviewSeek = (event) => {

        if (playState) { return; }

        const container = document.querySelector('.audio-track-container');
        const mousePosition = event.pageX - container.getBoundingClientRect().left;
        const containerWidth = container.offsetWidth;
        let mousePositionInPercentage = mousePosition / containerWidth;

        // Check boundaries
        if (mousePositionInPercentage < 0) mousePositionInPercentage = 0;
        if (mousePositionInPercentage > 1) mousePositionInPercentage = 1;

        wavesurferRef.current.seekTo(mousePositionInPercentage); // Preview WaveSurfer's progress
    }

    const resetPreviewSeek = (event) => {

        if (playState) { return; }

        if (audioRef.current) {
            const currentTime = audioRef.current.currentTime;
            const totalTime = audioRef.current.duration;
            if (totalTime) {
                const progress = currentTime / totalTime;
                wavesurferRef.current.seekTo(progress);
            }
        } else {
            wavesurferRef.current.seekTo(0);
        }
    }


    // Handle downloading of file
    //----------------------------------------------------------------------------------------------

    const handleDownloadClick = () => {
        const downloadLink = document.createElement('a');
        downloadLink.href = audioUrl;
        downloadLink.download = 'audio.mp3'; // Set the desired file name here
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    

    // Component
    //----------------------------------------------------------------------------------------------

    return (
        <div className={'custom-audio-player'}>

            {/* button for playing / pausing audio */}
            <div className={'play-pause-container'} onClick={handlePlayPauseClick} title={playState ? 'pause' : 'play'}>
                { playState ? 
                    <img className={'pause-icon'} src={pauseIcon} alt={''}></img>
                :
                    <img className={'play-icon'} src={playIcon} alt={''}></img>
                }
            </div>

            {/* trackbar with waveform */}
            <div className={'audio-track-container'} onMouseMove={handlePreviewSeek} onMouseLeave={resetPreviewSeek} onClick={handleAudioSeek} ref={audioTrackContainerRef}></div>

            {/* button for downloading audio file */}
            <div className={'file-download-button'} title={'download file'} onClick={handleDownloadClick}>
                <img className={'file-download-icon'} src={downloadIcon} alt={''}></img>
            </div>

        </div>
    );
}