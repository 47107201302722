import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

// Redux
import { store } from './store';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

// Route Components
import App from './App.js';
import { Login } from './routes/Login/Login.js';
import { Register } from './routes/Register/Register.js';
import { Password } from './routes/Password/Password.js';
import { ConfirmPassword } from './routes/Password/ConfirmPassword.js';
import Profile from './routes/Profile/Profile.js';
import { ConfirmEmail } from './routes/ConfirmEmail/ConfirmEmail.js';
import { NewEmailToken } from './routes/ConfirmEmail/NewEmailToken.js';

// Main error component
import ErrorPage from "./routes/ErrorPage/ErrorPage.js";

// Notification component
import Snackbar from './SnackBar/SnackBar.js';

// Context Menu Component
// import { ContextMenuComponent } from './ContextMenu/ContextMenuComp.js';

// Auth route Wrapper
import ProtectedRoutes from './routes/protected.js';

// React Router v6 
import { createBrowserRouter, 
  RouterProvider, 
  createRoutesFromElements, 
  Route,
  Navigate } from "react-router-dom";

// Main stylesheet
import './index.css';

// Router setup
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorPage />}>
          <Route path="/" element={<Navigate to="/sandbox/" replace />} />
          <Route path="/sandbox/:nodeMapId?" element={<App />} />
          <Route path="profile" element={<Profile />} />

          {/* logged in users have no access to these routes */}
          <Route element={<ProtectedRoutes />}>
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="confirm/:emailToken?" element={<ConfirmEmail />} />
              <Route path="verifyEmail" element={<NewEmailToken />} />
              <Route path="password" element={<Password />} />
              <Route path="confirmPasswordToken/:token?" element={<ConfirmPassword />} />
          </Route>
    </Route>
  )
);


let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* <React.StrictMode> */}
        <RouterProvider router={router} />
        <Snackbar />
        {/* <ContextMenuComponent /> */}
      {/* </React.StrictMode> */}
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
