// Modules
import { EdisonAI } from '../../../../EdisonAI/EdisonAI.js';
import icon from './icon.png';
import { util } from '../../../../nodeMapProcessing/util.js';
import config from '../../../../config';

const title = 'Vicuna 13b';
const description = '';

// JSON template
//----------------------------------------------------------------------------------------------------

// Node JSON template
const template = {

	category: "language",
	name: "Vicuna 13b",
	type: "vicuna13b",
	position: null,

	settings: {
		showIcon: true,
		infoLink: 'https://replicate.com/replicate/vicuna-13b',

		canEditInputs: true,
		modelType: 'natural-language-model',
		modelProvider: 'Replicate',

		max_length: 500,
		temperature: 0.75,
		top_p: 1,
		repetition_penalty: 1,
		seed: -1,
		stream: true,
	},

	stats: {
		processing: {
			meanTime: 5000
		}
	},

	guiMaxWidth: 400,
	gui: [
		{
			group: 'horizontal', minWidth: '300px', elements: [
				{ element: 'numberbox', label: 'max length', setting: 'max_length', min: 0, step: 25 },
				{ element: 'numberbox', label: 'seed', setting: 'seed' },
			]
		},
		{
			group: 'vertical', elements: [
				{ element: 'slider', label: 'temperature', setting: 'temperature', min: 0, max: 2, step: 0.01 },
				{ element: 'slider', label: 'repetition pentlty', setting: 'repetition_penalty', min: 0, max: 2, step: 0.01 },
				{ element: 'slider', label: 'top p', setting: 'top_p', min: 0, max: 1, step: 0.01 },
			],
		},
		// Advanced settings
		{
			group: 'horizontal',
			elements: [
				{ element: 'toggleShowAdvanced', setting: 'showAdvanced' },
			]
		},
		{
			group: 'horizontal',
			elements: [
				{ element: 'checkbox', label: 'stream data', setting: 'stream', advanced: true },
				{ element: 'checkbox', label: 'tolerate errors', setting: 'tolerateErrors', advanced: true },
				{ element: 'numberbox', label: 'max retries', setting: 'maxRetries', min: 0, advanced: true, displayCondition: { setting: 'tolerateErrors', value: [true] } },
			]
		},
	],

	contents: "",

	inputs: {
		"Input 1": {

			name: null,
			dataType: 'string',
			requireContent: true,
			contents: null,
		}
	},

	outputs: {
		"Output 1": {

			name: null,
			dataType: 'string',
			contents: null,
			destinations: {},
			clearOnSend: true
		}
	}
}

// Processing function
//----------------------------------------------------------------------------------------------------

class process {

	// Callbacks
	//--------------------------------------------------

	constructor() {

		this.onUpdateCallback = () => { console.warn('no onUpdate callback') };
		this.onStreamCallback = () => { console.warn('no onStream callback') };
		this.onCompleteCallback = () => { console.warn('no onComplete callback') };
		this.onWarningCallback = () => { console.warn('no onWarning callback') };
		this.onErrorCallback = () => { console.warn('no onError callback') };
		this.onGiveUpCallback = () => { console.warn('no onGiveUp callback') };

	}

	// Set callback functions
	onUpdate(callback) { this.onUpdateCallback = callback; }
	onStream(callback) { this.onStreamCallback = callback; }
	onComplete(callback) { this.onCompleteCallback = callback; }
	onWarning(callback) { this.onWarningCallback = callback; }
	onError(callback) { this.onErrorCallback = callback; }
	onGiveUp(callback) { this.onGiveUpCallback = callback; }

	// Fire functions easily
	update(node) { this.onUpdateCallback(node); }
	stream(node) { this.onStreamCallback(node); }
	finish(node) { this.onCompleteCallback(node); }
	warn(message) { this.onWarningCallback(message); }
	error(message) { this.onErrorCallback(message); }
	giveUp(message) { this.onGiveUpCallback(message); }


	// Run node
	//--------------------------------------------------

	// Node processing function
	async run(node, extras) {

		const apiToken = extras.apiToken;

		try {

			// Clear contents first
			node.contents = null;

			// Do nothing if node has required inputs that are empty
			const hasEmptyRequired = util.hasEmptyRequiredInputs(node);

			if (hasEmptyRequired) {
				//console.log('has empty required!');
				//console.log(util.deepCopy(node))
				return false;
			}

			const prompt = util.concatenateInputContents(node);

			// Before request
			//----------------------------------------

			const model = 'vicuna-13b';
			const input = prompt;
			const settings = {
				max_length: node.settings.max_length,
				temperature: node.settings.temperature,
				top_p: node.settings.top_p,
				repetition_penalty: node.settings.repetition_penalty,
				seed: node.settings.seed
			}

			const vicuna13b = new EdisonAI();


			// If we would like to stream data
			if (node.settings.stream) {

				let aggregate = '';

				vicuna13b.onUpdate((data) => {

					console.log('update: ', data);

					if (data.result) {
						aggregate += data.result;
					}

					util.setContents(node, aggregate + ' ❚');
					util.clearInputs(node);
					util.fillOutputs(node, 'streaming');

					this.stream(node);
					return;
				});
			}

			vicuna13b.onComplete((data) => {

				console.log('complete: ', data);

				util.setContents(node, data.result);
				util.clearInputs(node);
				util.fillOutputs(node);

				this.finish(node);
				return;
			});

			vicuna13b.onError((data) => {
				console.warn('VICUNA-13B ERROR: ', data.error);
				this.error(data.error);
				return;
			});

			await vicuna13b.run(model, input, settings, apiToken);

			return;
		}

		catch (e) {
			this.error(e.message);
			return;
		}
	}
}

// Export
//----------------------------------------------------------------------------------------------------

export const vicuna13b = {
	template,
	process,
	icon,
	title,
	description
}