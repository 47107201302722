import { useSelector, useDispatch } from 'react-redux';
import { updateNodeSetting } from '../../../../../../redux/slices/nodeMapSlice.js';

export default function SelectDropdown({nodeId, setting, options, value, label}) {

    const dispatch = useDispatch();

    // component logic
    //----------------------------------------------------------------------------------------------------

    if (!nodeId || !setting) {
        return null;
    }

    // defined functions
    //----------------------------------------------------------------------------------------------------

    function handleChangeNodeSetting(e) {
        const newValue = e.currentTarget.value;
        dispatch(updateNodeSetting({nodeId, setting, newValue}));
    }
    
    // component
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={'node-setting-container'}>

            <label className={'node-setting-label'}>{label}</label>

            <select className={"model-select"} title={label} value={value} onChange={handleChangeNodeSetting}>
                { options.map((option) => { return <option value={option} key={option}>{option}</option> })}
            </select>
        </div>
    );
}