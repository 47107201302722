//modules

import { useEffect, useState } from 'react';

//components
import EditMenu from './EditMenu/EditMenu';
import NodeMaps from './NodeMaps/NodeMaps';
import Usage from './Usage/Usage';
import SaveButton from './SaveButton/SaveButton.js';

//resources
import listDark from './listDark.svg';
import listLight from './listLight.svg';

import editDark from './editDark.svg';
import editLight from './editLight.svg';

import usageDark from './usageDark.svg';
import usageLight from './usageLight.svg';

import accountDark from './accountDark.svg';
import accountLight from './accountLight.svg';

import settingsDark from './settingsDark.svg';
import settingsLight from './settingsLight.svg';

//styles
import './Menu.css';
import PositionIndicator from './ZoomIndicator/PositionIndicator';

export default function Menu() {

    // Option Select Functionality
    //----------------------------------------------------------------------------------------------------

    const [selected, setSelected] = useState('edit');
    const [animate, setAnimate] = useState(false);
    // const [visible, setVisible] = useState(false);

    useEffect(() => {
        setAnimate('in');
      }, [selected]);


    function handleOptionSelect (e) {

        const option = e.currentTarget.getAttribute('option');
        //console.log('Selecting option: ', option);

        if (option === selected) {
            setSelected(null);
        } else {
            setSelected(option);
        }

        setAnimate('out');
        // setTimeout(() => setAnimate('in'), 300);
    }

    ////console.log('Selected: ', selected);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={'menu-and-content-container'}>
        <div className={'menu'}>

            <div className={'menu-buttons'}>

                <div className={`menu-button${selected === 'maps' ? ' menu-button-selected' : ''}`} title={'view your created nodemaps'} option={'maps'} onClick={handleOptionSelect}>
                    <div className={'menu-button-label'}>My Maps</div>
                    <img className={'menu-button-image'} src={listDark} alt={''}></img>
                </div>

                <div className={`menu-button${selected === 'edit' ? ' menu-button-selected' : ''}`} title={'edit this nodemap'} option={'edit'} onClick={handleOptionSelect}>
                    <div className={'menu-button-label'}>Edit</div>
                    <img className={'menu-button-image'} src={editDark} alt={''}></img>
                </div>

                <div className={`menu-button${selected === 'usage' ? ' menu-button-selected' : ''}`} title={'view your recent usage'} option={'usage'} onClick={handleOptionSelect}>
                    <div className={'menu-button-label'}>Usage</div>
                    <img className={'menu-button-image'} src={usageDark} alt={''}></img>
                </div>

                <div className={`menu-button${selected === 'account' ? ' menu-button-selected' : ''}`} title={'view your account information'} option={'account'} onClick={handleOptionSelect}>
                    <div className={'menu-button-label'}>Account</div>
                    <img className={'menu-button-image'} src={accountDark} alt={''}></img>
                </div>

                <div className={`menu-button${selected === 'settings' ? ' menu-button-selected' : ''}`} title={'view settings panel'} option={'settings'} onClick={handleOptionSelect}>
                    <div className={'menu-button-label'}>Settings</div>
                    <img className={'menu-button-image'} src={settingsDark} alt={''}></img>
                </div>

            </div>

            <div className={'menu-content-and-save-button-container'}>

                <div
                    className={`menu-content ${
                        animate === 'in'
                        ? 'menu-content-slide-in'
                        : animate === 'out'
                        ? 'menu-content-slide-out'
                        : ''
                    }`}
                >

                {selected === 'maps' ? <NodeMaps /> : null}
                {selected === 'edit' ? <EditMenu /> : null}
                {selected === 'usage' ? <Usage /> : null}
                
                </div>

                <div className={'save-button-container'}><SaveButton /></div>
                <div className={'position-indicator-container'}><PositionIndicator /></div>

            </div>
        </div>
        
        </div>
    );
}