// Import node types
//----------------------------------------------------------------------------------------------------

// Basic nodes
import { input } from './control/input/input.js';
import { output } from './control/output/output.js';
import { text } from './control/text/text.js';
import { replace } from './control/replace/replace.js';
import { combine } from './control/combine/combine.js';
import { decision } from './control/decision/decision.js';
import { memory } from './control/memory/memory.js';
import { custom } from './control/custom/custom.js';

// Language nodes
import { gpt3 } from './models/language/gpt3/gpt3.js';
import { gpt3p5 } from './models/language/gpt3p5/gpt3p5.js';
import { gpt4 } from './models/language/gpt4/gpt4.js';
import { vicuna13b } from './models/language/vicuna13b/vicuna13b.js';

// Image nodes
import { dalle2 } from './models/image/dalle2/dalle2.js';
import { stableDiffusion } from './models/image/stableDiffusion/stableDiffusion.js';
import { blip } from './models/image/blip/blip.js';

// Audio nodes
import { primeVoice } from './models/audio/primeVoice/primeVoice.js';

// Aggregate and export
//----------------------------------------------------------------------------------------------------

// Giant aggretator object
const nodes = {

    // Basic nodes
    input, output, text, replace, combine, decision, memory, custom,

    // Language model nodes
    gpt3, gpt3p5, gpt4, vicuna13b,

    // Image nodes
    dalle2, stableDiffusion, blip,
  
    // Audio nodes
    primeVoice
}

const controlNodes = { input, output, text, replace, combine, decision, memory, custom}
const languageNodes = { gpt3, gpt3p5, gpt4, vicuna13b }
const imageNodes = { dalle2, stableDiffusion, blip }
const audioNodes = { primeVoice }

// Export
export {
    nodes,
    controlNodes,
    languageNodes,
    imageNodes,
    audioNodes
};