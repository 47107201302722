
import './modal.css';

const Modal = (props) => {

  return (
    <div className='modal-overlay' onClick={props.toggle}>
        <div onClick={(e) => e.stopPropagation()} className="modal">
            {props.children}
        </div>
    </div>
  );

  
};

export default Modal;