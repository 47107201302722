// Modules
import { useState } from 'react';

// Resources
import chevronDark from './chevronDark.svg';

// Styles
import './Item.css';

// Component
export default function RecentsList({recent}) {

    // Initialization
    //----------------------------------------------------------------------------------------------------

    // Get and format cost
    const cost = recent.cost;
    const formattedCost = formatCost(cost)

    // Get and format date
    const date = recent.date;
    const formattedDate = formatDate(date);

    // Get other info
    const model = recent.model;
    const prompt = recent.prompt;
    const output = recent.output;

    // Formatting functions
    //----------------------------------------------------------------------------------------------------

    // Format in dollars with three decimal places
    function formatCost(cost) {
        return "$" + Number(cost).toFixed(6);
    }

    // Format date properly
    function formatDate(dateString) {
        const date = new Date(dateString);
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
    
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    
        if (areDatesEqual(date, today)) {
            // If the date is today, return the time
            const options = { hour: '2-digit', minute: '2-digit' };
            return new Intl.DateTimeFormat('en-US', options).format(date);
        } else if (areDatesEqual(date, yesterday)) {
            // If the date is yesterday, return 'Yesterday'
            return 'Yesterday';
        } else if (date >= oneWeekAgo) {
            // If the date is within the past week (but not yesterday or today), return the day of the week
            const options = { weekday: 'short' };
            return new Intl.DateTimeFormat('en-US', options).format(date);
        } else {
            // If the date is more than a week ago, return the date
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            return new Intl.DateTimeFormat('en-US', options).format(date);
        }
    }

    function areDatesEqual(date1, date2) {
        return date1.getUTCFullYear() === date2.getUTCFullYear() &&
            date1.getUTCMonth() === date2.getUTCMonth() &&
            date1.getUTCDate() === date2.getUTCDate();
    }


    // Expand / Collapse
    //----------------------------------------------------------------------------------------------------

    const [expanded, setExpanded] = useState(false);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={'recent-usage-item'}>

            <div className={'recent-usage-item-info'}>

                <img className={`recent-usage-item-button ${!expanded ? 'recent-usage-item-button-collapsed' : ''}`} src={chevronDark} alt={''} onClick={() => {setExpanded(!expanded)}}></img>

                <div className={'recent-usage-item-time'}>{formattedDate}</div>
                <div className={'recent-usage-item-model'}>{model}</div>
                <div className={'recent-usage-item-cost'}>{formattedCost}</div>
            </div>

            { expanded ?
            
                <div className={'recent-usage-item-io'}>

                    <div className={'recent-usage-item-input'}>
                        <div className={'recent-usage-item-input-label'}>input</div>
                        <div className={'recent-usage-item-input-content'}>{prompt}</div>
                    </div>

                    <div className={'recent-usage-item-output'}>
                        <div className={'recent-usage-item-input-label'}>output</div>
                        <div className={'recent-usage-item-input-content'}>{output}</div>
                    </div>
                    
                </div>
            
            : null}

        </div>
    );
}