import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateIoVariableContents } from '../../../../redux/slices/nodeMapSlice.js'

export default function IoTextField({variable, ioType}) {

    // Update nodemap variable value
    //----------------------------------------------------------------------------------------------

    const dispatch = useDispatch();

    function handleUpdateValue(e) {
        const contents = e.currentTarget.value;
        const variableName = variable.name;

        dispatch(updateIoVariableContents({ioType, variableName, contents}))
    }

    function handleClearIoVariableContents(e) {

        const variableName = variable.name;
        const contents = null;

        dispatch(updateIoVariableContents({ioType, variableName, contents}))
    }

    // Process certain objects into strings
    //----------------------------------------------------------------------------------------------

    let stringContent = variable.contents;

    try {
        const object = JSON.parse(stringContent);
        if (object.content) { stringContent = object.content; }
    }

    catch (e) {
    }

    const textAreaRef = useRef(null);
    const currentHeight = useRef(150);

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.scrollTo(0, textAreaRef.current.scrollHeight);
            currentHeight.current = textAreaRef.current.scrollHeight;
            textAreaRef.current.style.height = currentHeight.current + 'px';
        }
    }, [stringContent]);

    // Max / min
    //----------------------------------------------------------------------------------------------

    const length = stringContent?.length || 0;
    const min = variable.minLength;
    const max = variable.maxLength;

    const state = variable.state || null;
    const message = variable.message || null;

    // Component
    //----------------------------------------------------------------------------------------------

    return (
        <>
            <div className={'io-variable-field-label-and-clear-button'}>

                    <div className={'label-and-length'}>
                        <div className={'io-variable-field-label'}>{variable.name || ''}</div>
                        { max ?
                            <>
                                { length < max ? <div className={'io-variable-field-min-max'}>{`(${length}/${max})`}</div> : null }
                                { length > max ? <div className={'io-variable-field-min-max-over'}>{`(${length}/${max})`}</div> : null }
                            </>
                        : null }
                    </div>

                <div className={'io-variable-clear-button'} onClick={handleClearIoVariableContents}>clear</div>
            </div>

            <textarea
            className={'text-input-field'}
            variablename={variable.name}
            iotype={ioType}
            placeholder={variable.name}
            value={stringContent || ''}
            onChange={handleUpdateValue}
            ref={textAreaRef}>
            </textarea>

            { state === 'error' ?
                <div className={'io-variable-field-error-message'}>{message}</div>
            : null }
        </>
    );

}