// Icon
import icon from './icon.svg';

// Util
import { util } from '../../../nodeMapProcessing/util.js';

const title = 'Memory';
const description = '';

// JSON template
//----------------------------------------------------------------------------------------------------

// Node JSON template
const template = {
      
    category: "control",
    name: "memory",
    type: "memory",
    position: null,

    settings: {
      memoryTextContent: "This is a memory node - it's like a text node that can take input",
      textBoxDimensions: {width: 350, height: 250},
      useNewLine: false,
      addTextTo: "end",
      messageMode: false,
      maxTokens: 100,
      replaceContents: false,
      useSession: true,
      canEditInputs: true,
      mode: 'text',

      // Dummy example:
      memoryMessagesContent: [
        { "role": "user", "content": "This is some message content", "time": 1685232995885 },
        { "role": "assistant", "content": "Some additional message content for your worries", "time": 1685232995885 },
        { "role": "user", "content": "A third message just to keep you going!", "time": 1685232995885 },
      ]
    },

    gui: [
      
      { group: 'horizontal', elements: [
        {element: 'textarea', setting: 'memoryTextContent', title: 'Memory node text content', displayCondition: {setting: 'mode', value: ['text']} },
        {element: 'chatMessages', setting: 'memoryMessagesContent', displayCondition: {setting: 'mode', value: ['messages']} }
      ]},

      { group: 'horizontal', elements: [
        {element: 'dropdown', label: 'mode', setting: 'mode', options: ['text', 'messages']},
        {element: 'numberbox', label: 'max tokens', setting: 'maxTokens', min: 0, displayCondition: {setting: 'mode', value: ['text', 'messages']}},
        {element: 'numberbox', label: 'max messages', setting: 'maxMessages', min: 0, displayCondition: {setting: 'mode', value: ['messages']}},
        {setting: 'useNewLine', displayCondition: {setting: 'mode', value: ['text']}}
      ] },
    ],

    contents: "",

    inputs: {
      "Input 1": {
        
        name: null,
        type: 'text',
        dataType: null,
        requireContent: true,
        contents: null,
      }
    },

    outputs: {
      "Output 1": {
        
        name: null,
        type: 'text',
        dataType: null,
        contents: null,
        destinations: {}
      }
    }
}

// Processing function
//----------------------------------------------------------------------------------------------------

class process {

	// Callbacks
	//--------------------------------------------------

	constructor() {

		this.onUpdateCallback =     () => { console.warn('no onUpdate callback')};
		this.onStreamCallback =     () => { console.warn('no onStream callback')};
		this.onCompleteCallback =   () => { console.warn('no onComplete callback')};
		this.onWarningCallback =    () => { console.warn('no onWarning callback')};
		this.onErrorCallback =      () => { console.warn('no onError callback')};
		this.onGiveUpCallback =     () => { console.warn('no onGiveUp callback')};

	}

	// Set callback functions
	onUpdate(callback) { this.onUpdateCallback = callback; }
	onStream(callback) { this.onStreamCallback = callback; }
	onComplete(callback) { this.onCompleteCallback = callback; }
	onWarning(callback) { this.onWarningCallback = callback; }
	onError(callback) { this.onErrorCallback = callback; }
	onGiveUp(callback) { this.onGiveUpCallback = callback; }

	// Fire functions easily
	update(node) { this.onUpdateCallback(node); }
	stream(node) { this.onStreamCallback(node); }
	finish(node) { this.onCompleteCallback(node); }
	warn(message) { this.onWarningCallback(message); }
	error(message) { this.onErrorCallback(message); }
	giveUp(message) { this.onGiveUpCallback(message); }


	// Run node
	//--------------------------------------------------

	// Node processing function
	run (node, extras) {

        try {
      
            // For messages mode mode
            if (node.settings.mode === 'text') {
      
                // Memory nodes initialize with no contents
                if (!node.settings.memoryTextContent) {
                    node.settings.memoryTextContent = [];
                }
      
                // Do nothing if node has required inputs that are empty
                const hasEmptyRequired = util.hasEmptyRequiredInputs(node);
      
                if (!hasEmptyRequired) {
      
                    // Add all inputs to list of messages in memory
                    for (let inputId in node.inputs) {
                        const input = node.inputs[inputId];
                        const contents = input.contents;
      
                        // Use message content if chatMessage
                        if (contents.content) {
      
                            if (node.settings.useNewLine) {
                                node.settings.memoryTextContent += '\n';
                            }
                            
                            node.settings.memoryTextContent += contents.content;
                            continue;
                        }
      
                        // If input contains array
                        if (Array.isArray(contents)) {
                            for (let message of contents) {
                                if (message.content) {
      
                                    if (node.settings.useNewLine) {
                                        node.settings.memoryTextContent += '\n';
                                    }
      
                                    node.settings.memoryTextContent += message.content;
                                }
                            }
      
                            // End early, continue to next input
                            continue;
                        }
      
                        if (typeof contents === 'string') {
      
                            if (node.settings.useNewLine) {
                                node.settings.memoryTextContent += '\n';
                            }
      
                            node.settings.memoryTextContent += contents;
                        }
                    }
                }
      
                node.contents = node.settings.memoryTextContent;
            }
      
            // For messages mode mode
            if (node.settings.mode === 'messages') {
      
                // Memory nodes initialize with no contents
                if (!node.settings.memoryMessagesContent) {
                    node.settings.memoryMessagesContent = [];
                }
      
                // Do nothing if node has required inputs that are empty
                const hasEmptyRequired = util.hasEmptyRequiredInputs(node);
      
                if (!hasEmptyRequired) {
      
                    // Add all inputs to list of messages in memory
                    for (let inputId in node.inputs) {
                        
                        const input = node.inputs[inputId];
                        const contents = input.contents;
      
                        // Normal chatMessages get pushed automatically
                        if (contents.content) {
                            node.settings.memoryMessagesContent.push(contents);
                            continue;
                        }
      
                        // Strings get converted to chatMessages first
                        if (typeof contents === 'string') {
                            node.settings.memoryMessagesContent.push({ role: 'assistant', time: Date.now(), content: contents });
                            continue;
                        }
      
                        // Arrays get special treatment
                        if (Array.isArray(contents)) {
                            for (let message of contents) {
                                if (message.content) {
                                    node.settings.memoryMessagesContent.push(message);
                                }
                            }
      
                            continue;
                        }
                    }
      
                }
      
                node.contents = node.settings.memoryMessagesContent;
            }
      
            // Clear inputs and fill outputs
            util.clearInputs(node);
            util.fillOutputs(node);
      
            //console.log('Processed Memory Node: ', util.deepCopy(node));
      
            this.finish(node);
            return;
        }

		catch (e) {
			this.error(e.message);
			this.giveUp();
			return;
		}
  	}
}

// Export
//----------------------------------------------------------------------------------------------------

export const memory = {
    template,
    process,
    icon,
    title,
    description
}