// Modules
import { useSelector, useDispatch } from 'react-redux';
import { updateViewportZoom } from '../../../redux/slices/nodeMapSlice.js';

// Resources
import plusIcon from './plusIconDark.svg';
import minusIcon from './minusIocnDark.svg';

// Syles
import './ZoomIndicator.css';

export default function ZoomIndicator() {


    // Display Zoom
    //----------------------------------------------------------------------------------------------

    const viewportZoom = useSelector((state) => state.nodeMap.nodeMap?.vars?.viewportZoom);
    const viewportZoomPercent = (viewportZoom*100).toFixed(0) + '%';

    // Zoom in / Zoom out
    //----------------------------------------------------------------------------------------------

    const dispatch = useDispatch();

    // Decrease zoom level
    function handleZoomOut(e) {

        // Set new zoom and correct
        let newZoom = (viewportZoom * 0.85).toFixed(2);
        if (newZoom < 0.25) { newZoom = 0.25; }
        if (newZoom > 1.25) { newZoom = 1.25; }

        dispatch(updateViewportZoom({ newZoom }));
    }

    // Increase zoom level
    function handleZoomIn(e) {

        // Set new zoom and correct
        let newZoom = (viewportZoom * 1.25).toFixed(2);
        if (newZoom < 0.25) { newZoom = 0.25; }
        if (newZoom > 1.25) { newZoom = 1.25; }

        dispatch(updateViewportZoom({ newZoom }));
    }

    // Component
    //----------------------------------------------------------------------------------------------

    return (
        <div className={'zoom-indicator'}>

            <img className={'zoom-indicator-button'} src={minusIcon} alt={''} onClick={handleZoomOut}></img>

            <div className={'zoom-indicator-level-display'}>{viewportZoomPercent}</div>

            <img className={'zoom-indicator-button'} src={plusIcon} alt={''} onClick={handleZoomIn}></img>

        </div>
    );

}