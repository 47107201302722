// Components
import { useRef } from 'react';
import Item from './Item/Item.js';
import { useSmoothScroll } from '../../../../utils/useSmoothScroll.js';

// Styles
import './RecentsList.css';

// Component
export default function RecentsList({ recents }) {

    // Initialization
    //----------------------------------------------------------------------------------------------------

    const scrollRef = useSmoothScroll();

    // Component
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={'recent-usage-container'} ref={scrollRef} style={{ overflowY: 'scroll' }}>
            <div className={'recent-usage'}>
                {recents.map((recent) => <Item recent={recent} />)}
            </div>
        </div>
    );
}