import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  requestItems: [],
  nodeMapData: null,
  nodeMapsData: null,
  demoNodeMapsData: null,
  createNodeMapData: null,
  deleteNodeMapData: null,
  logoutData: null
};

const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    
    addRequest: (state, action) => {
      state.requestItems.push(action.payload);
    },

    removeRequest: (state) => {
      state.requestItems.shift();
    },

    updateRequest: (state, action) => {
      // state.nodeMapsData = action.payload.nodeMaps;
      const { key, value } = action.payload;
      //console.log('key', key, 'value', value);
      state[key] = value;
    },

    resetData: (state, action) => {
      const { key, value } = action.payload;
      //console.log('keyyyyyyyyyyyyyyyyy', key, 'value', value);
      state[key] = value;
    },

    resetRequests: (state) => {
      //console.log('resetting requests');
      Object.assign(state, initialState);
    },

  }

});

export const {addRequest, removeRequest, updateRequest, resetRequests, resetData} = requestSlice.actions;
export default requestSlice.reducer;