import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { updateRequest, removeRequest } from '../redux/slices/requestSlice.js';

import config from "../config.js";

const useFetchManager = () => {

  const dispatch = useDispatch();

  const [queue, setQueue] = useState([]);
  const [prevRequestItemsLength, setPrevRequestItemsLength] = useState(0);
  const [reAuthenticating, setReauthenticating] = useState(false);

  // Add a state to track if processQueue is running
  // const [processing, setProcessing] = useState(false);
  
  // fetch items array in redux store
  const requestItems = useSelector((state) => state.request.requestItems);

  //console.log('fetch manager new state...');
  //console.log('requestItems', requestItems);
  //console.log('queue', queue);
  //console.log('prevRequestItemsLength', prevRequestItemsLength);




  useEffect(() => {

    if (requestItems.length > prevRequestItemsLength) {
      const newItems = requestItems.slice(prevRequestItemsLength);
      //console.log('new items', newItems);

      setQueue((prevQueue) => [...prevQueue, ...newItems]);
      //console.log('request items length', requestItems.length);
      setPrevRequestItemsLength(requestItems.length);
    }

  }, [requestItems]);





  useEffect(() => {
    //console.log('in use effect process queue', queue);
    processQueue();

  }, [queue]);




  const processQueue = async () => {

    // Return early if processing
    // if (processing) return;

    // Set processing to true
    // setProcessing(true);

    //console.log('in process queue');
    setQueue(prevQueue => {

      //console.log('prev queue', prevQueue);
      if (prevQueue.length === 0) return prevQueue;
  
      const currentRequest = prevQueue[0];
      //console.log('current request', currentRequest);
      const { url, options, customs } = currentRequest;
      //console.log('url', url, 'options', options, 'customs', customs);
  
      (async () => {
        //console.log('in async function');
        try {
          const request = await fetch(url, options);
          const response = await request.json();
  
          //console.log('responseeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', response);
  
          // Process the data or update the state as needed
          processData(url, options, customs, response);
          
          // dispatch all caught errors to their respective data handlers to display proper messaging
        } catch (err) {
          console.error('Fetch error:', err, err.message);
        }


        // Set processing to false
        // setProcessing(false);
      })();

      // remove the count from the prevRequestItems
      // setPrevRequestItemsLength((prevState) => {
      //   //console.log('prevRequestItemsLength', prevState);
      //   return prevState - 1 });
  
      // Remove the processed request from the queue
      //console.log('prev Queue', prevQueue, 'slice', prevQueue.slice(1));
      return prevQueue.slice(1);
    });
  };



  const processData = async (url, options, customs, response) => {

    // check if access token is expired
    if(response.message === 'access token not valid') {
        // set state to trigger reauth useEffect
        setReauthenticating(true);
        return;
    }

    const { key } = customs;

    if (key) {
      //console.log('key', key, 'customs', customs);
      dispatch(updateRequest({ key: key, value: response }));
  }


    dispatch(removeRequest());

    setPrevRequestItemsLength((prevState) => {
      //console.log('prevRequestItemsLength', prevState);
      return prevState - 1 });

    return;

  };





  useEffect(() => {

    if(!reAuthenticating) {
      // process backlog
    }


  },[reAuthenticating]);





  useEffect(() => {

    if(reAuthenticating) {

      reAuth();

      async function reAuth() {

        try {

        let endpoint = config.apiUrl + 'users/reauthenticate';
        let request = await fetch(endpoint, {
          method: "POST",
          credentials: "include",
          headers: {
          "Content-Type": "application/json",
          },
        });

        let response = await request.json();


        if(response.success === true) {
          localStorage.setItem('token', response.accessToken);
          setReauthenticating(false);
          return;

        } else {
           // change user state to loggedOut
            // dispatch(logout());
  
            // remove access token from local storage
            localStorage.removeItem('token');
        
            // send user back to login page
            // navigate('/login');
            return;
        }

        } catch(err) {
            //console.log(err.message);
        }


      };

    }

    // processData();

  }, [reAuthenticating]);



};

export default useFetchManager;