// Icon
import icon from './icon.svg';

// Util
import { util } from '../../../nodeMapProcessing/util.js';

const title = 'Custom';
const description = '';

// JSON template
//----------------------------------------------------------------------------------------------------

// Node JSON template
const template = {

	category: "control",
	name: "Custom Code",
	type: "custom",
	position: null,

	settings: {
		canEditInputs: true,
		canEditOutputs: true,
		textBoxDimensions: { width: 550, height: 375 },
		showLogs: false,

		customCode:

`function run (node) {

	// Give up if required inputs aren't full
	if (util.hasEmptyRequiredInputs(node)) {
		actions.giveUp();
		return;
	}
	
	// Combine all node inputs
	const combinedInputs = util.concatenateInputContents(node);
	
	// Set node / output contents
	util.setContents(node, combinedInputs);
	util.clearInputs(node);
	util.fillOutputs(node);
	
	// Call next nodes
	actions.finish(node);
	return;
}`

	},

	gui: [
		{ group: 'horizontal', elements: [{ setting: 'customCode' }] }
	],

	contents: null,

	inputs: {

		"Input 1": {

			name: '',
			type: null,
			allowStreaming: true,
			requireContent: true,
			contents: null,
		}
	},

	outputs: {

		"Output 1": {
			name: '',
			type: null,
			contents: null,
			destinations: {},
			clearOnSend: true
		},
	}
}

// Processing function
//----------------------------------------------------------------------------------------------------

class process {

	// Callbacks
	//--------------------------------------------------

	constructor() {

		this.onUpdateCallback = () => { console.warn('no onUpdate callback') };
		this.onStreamCallback = () => { console.warn('no onStream callback') };
		this.onCompleteCallback = () => { console.warn('no onComplete callback') };
		this.onWarningCallback = () => { console.warn('no onWarning callback') };
		this.onErrorCallback = () => { console.warn('no onError callback') };
		this.onGiveUpCallback = () => { console.warn('no onGiveUp callback') };

	}

	// Set callback functions
	onUpdate(callback) { this.onUpdateCallback = callback; }
	onStream(callback) { this.onStreamCallback = callback; }
	onComplete(callback) { this.onCompleteCallback = callback; }
	onWarning(callback) { this.onWarningCallback = callback; }
	onError(callback) { this.onErrorCallback = callback; }
	onGiveUp(callback) { this.onGiveUpCallback = callback; }

	// Fire functions easily
	update(node) { this.onUpdateCallback(node); }
	stream(node) { this.onStreamCallback(node); }
	finish(node) { this.onCompleteCallback(node); }
	warn(message) { this.onWarningCallback(message); }
	error(message) { this.onErrorCallback(message); }
	giveUp(message) { this.onGiveUpCallback(message); }


	// Run node
	//--------------------------------------------------

	// Node processing function
	run(node, extras) {

		try {

			const actions = { 
				update: this.onUpdateCallback,
				stream: this.onStreamCallback,
				finish: this.onCompleteCallback,
				warn: this.onWarningCallback,
				error: this.onErrorCallback, 
				giveUp: this.onGiveUpCallback
			}

			// Construct custom code string
			const functionString = 'run(node);' + node.settings.customCode;
			const customFunction = Function('node', 'util', 'actions', 'extras', functionString).bind(this);
			customFunction(node, util, actions, extras);
		}

		catch (e) {
			this.error(e.message);
			return;
		}
	}
}

// Export
//----------------------------------------------------------------------------------------------------

export const custom = {
	template,
	process,
	icon,
	title,
	description
}