//modules
import { useRef } from 'react';

//components
import SandboxNode from './SandboxNode/SandboxNode';

export default function SandboxNodes({nodeMap, viewportPosition, viewportZoom}) {

    // component logic
    //----------------------------------------------------------------------------------------------------

    const nodes = nodeMap?.nodes;

    if (!nodes) {
        return null;
    }

    // component
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={"sandbox-nodes"} id="sandboxNodesDiv">
            { Object.keys(nodes).map((nodeId) => { return <SandboxNode nodeId={nodeId} node={nodes[nodeId]} viewportPosition={viewportPosition} viewportZoom={viewportZoom} key={nodeId}/> })}
        </div>
    );
}