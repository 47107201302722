// Modules
import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateNodeSetting } from '../../../../../../../redux/slices/nodeMapSlice.js';

// Styles
import './ToggleShowAdvanced.css';

export default function ToggleShowAdvanced({nodeId, element, value}) {

    // Define important variables

    const setting = element.setting;
    const label = element.label;
    const title = element.title;
    const minLength = element.minLength;
    const maxLength = element.maxLength;

    if (typeof value === 'undefined') {
        value = false;
    }

    // Define dispatch function
    const dispatch = useDispatch();
    
    // Updating node setting
    //----------------------------------------------------------------------------------------------------

    function handleChangeNodeSetting(newValue) {
        dispatch(updateNodeSetting({nodeId, setting, newValue}));
    }

    // Component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={'node-setting-container'}>

            <div className={'toggleadvanced'} onClick={() => {handleChangeNodeSetting(!value)}}>advanced settings</div>

        </div>
    );
}