import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { hideSnackbar } from '../redux/slices/snackBarSlice.js';

import './snackBar.css';

const Snackbar = () => {

  const dispatch = useDispatch();

  const [queue, setQueue] = useState([]);
  const [currentSnackBar, setCurrentSnackBar] = useState(null);
  const [prevSnackbarItemsLength, setPrevSnackbarItemsLength] = useState(0);

  // used for preventing the same messages displaying multiple times in a row
  const [lastMessage, setLastMessage] = useState(null);
  const [lastTimestamp, setLastTimestamp] = useState(null);
  const [customPosition, setCustomPosition] = useState(null);

  const snackbarItems = useSelector((state) => state.snackbar.snackbarItems);
  const defaultDuration = useSelector((state) => state.snackbar.duration);

  // //console.log('state updates....');
  // //console.log('queue', queue);
  // //console.log('current snackbar', currentSnackBar);
  // //console.log('snackbarItems', snackbarItems);
  // //console.log('prevsnackbarItems', prevSnackbarItemsLength);


  useEffect(() => {


    // //console.log('in use effect', 'snackbaritems', snackbarItems, 'prevItemslength', prevSnackbarItemsLength);
    if (snackbarItems.length > prevSnackbarItemsLength) {

      const newItems = snackbarItems.slice(prevSnackbarItemsLength);
      // //console.log('new items', newItems);

      let filteredItems;

      setQueue((prevQueue) => {

       // //console.log('previous queue', prevQueue);

      //  if(prevQueue.length === 0) {
      //   //console.log('if previous queue length is zero');
      //   filteredItems = newItems;
      //   return [...prevQueue, ...newItems];
      //  }

       
       // time limit to begin showing a duplicate of the previous message
       const timeThreshold = 5000;

       // if we need multiple diff messages defined back will need to add loops here

       // if new item message is different from previous item message add it to the queue
       if(newItems[0].message !== lastMessage) {
        // //console.log('if current item message is not equal to prev item message');
        filteredItems = newItems;
        return [...prevQueue, ...newItems];
       }


       if(newItems[0].timestamp - lastTimestamp >= timeThreshold) {
          // //console.log('allotted time past');
          // //console.log('new items timestamp', newItems[0].timestamp, 'prev timestamp', lastTimestamp);
          // //console.log('subtraction', newItems[0].timestamp - lastTimestamp);
          // //console.log('compare timestamps', newItems[0].timestamp - lastTimestamp >= timeThreshold);
          filteredItems = newItems;
          return [...prevQueue, ...newItems];
       }

        // //console.log('original filtered items', filteredItems);
        // //console.log('prev queue', ...prevQueue, 'filtered items', filteredItems);

        // return [...prevQueue, ...filteredItems];
        filteredItems = [];
        return [...prevQueue];
      });

  
      // Update prevSnackbarItemsLength by adding the length of filteredItems
      setPrevSnackbarItemsLength(prev => prev + filteredItems.length);
      // //console.log('prev snackbar items length', prevSnackbarItemsLength);

    }


  }, [snackbarItems, prevSnackbarItemsLength]);




  // handles current snackbar 
  useEffect(() => {

    // //console.log('current snackbar', currentSnackBar, 'queue', queue);

    // if queue length is zero that means nothing made it through
    // so we can remove that item from snackbarItems in redux store
    if(queue.length === 0) {
      // //console.log('if queue length equal to zero dispatch and remove first item');
      dispatch(hideSnackbar());
    }

    if (!currentSnackBar && queue.length > 0) {

      const nextSnackBar = queue[0];
      setCurrentSnackBar(nextSnackBar);

      // set custom position if it exists
      if(nextSnackBar.x && nextSnackBar.y) {
        setCustomPosition({ x: nextSnackBar.x, y: nextSnackBar.y});
      } // else {
      //   setCustomPosition(null);
      // }

      // sets message to be used to not process duplicates
      setLastMessage(nextSnackBar.message);
      setLastTimestamp(nextSnackBar.timestamp);

      setQueue((prevQueue) =>  {
        // //console.log('prev queue', prevQueue);
        // //console.log('prev queue slice', prevQueue.slice(1));
        return prevQueue.slice(1)});
    }

  }, [queue, currentSnackBar]);




  useEffect(() => {
    
    if (currentSnackBar) {

      // check for custom duration
      let duration = !currentSnackBar.duration ? defaultDuration : currentSnackBar.duration;

      const timeoutId = setTimeout(() => {
        handleClose(500);

      }, duration);  // nextSnackBar.duration);

      return () => clearTimeout(timeoutId);
    }
  }, [currentSnackBar]);


// dispatch reset snackbar

  const handleClose = (time) => {
    setCurrentSnackBar(null);
    setCustomPosition(null);
    setPrevSnackbarItemsLength((prev) => prev - 1);

    setTimeout(() => {
      // setPrevSnackbarItemsLength((prev) => prev - 1);

      // //console.log('after close dispatch to remove item');
      dispatch(hideSnackbar());

    }, time);

  };



  if (!currentSnackBar) {
    return null;
  }


  return (
    <div className={`snackbar ${currentSnackBar.type} ${currentSnackBar ? 'show' : 'hide'}`}
      style={customPosition ? {position: 'absolute', top: customPosition.y, left: customPosition.x, bottom: 'auto'} : null}>
      <p className="snackbar-message">{currentSnackBar.message}</p>
    </div>
  );
};

export default Snackbar;