import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addRequest, updateRequest } from '../redux/slices/requestSlice.js';

// get current api url
import config from '../config.js';
let api = config.apiUrl;


export const useFetch = () => {

    const user = useSelector((state) => state.user.user);
    
    const dispatch = useDispatch();

    // useCallback
    const fetchData = useCallback(async (method, url, customs = null, payload = null) => {
       
        //console.log('url', url);

        try {
        
            // set endpoint
            url = api + url;

            // get JWT token
            let accessToken = localStorage.token;
            // //console.log('access token', accessToken);
            
            // initialize headers object
            const headers = { 'Content-Type': 'application/json' };
            
            // set credentials
            const credentials = 'include';
            
            // add access token if it exists
            if(accessToken) {
                headers.Authorization = `Bearer ${accessToken}`;
            }

            // //console.log('headers', headers);

            // build options object
            const options = {
                method,
                credentials,
                headers,
            };

            // //console.log('user in wrapper fetch', user);
            let userName = user ? user.userName : null;
            // //console.log('userName', userName);
             

            // if there's a payload and it's not a get request add it to the options object
            if(payload && method !== 'GET') {

                // passed payload should never include username 
                payload.userName = userName;
                
                // add payload to body
                options.body = JSON.stringify(payload);
                // //console.log('options if payload', options);
            }

            
            // making POST and no user or payload

            // if no payload then create one
            if(!payload && method !== 'GET') {

                payload = { userName: userName };
                options.body = JSON.stringify(payload);
                // //console.log('options if no payload', options);
            }

            

            // //console.log('options after checks', options);

            let requestData = {
                url: url,
                options: options,
                customs: customs
            };

            //console.log('request data', requestData);

            const { key } = customs;

            if (key) { 
                //console.log('key', key, 'customs', customs);
                dispatch(updateRequest({ key: key, value: 'loading' }));
            }


             // dispatch here
            dispatch(addRequest(requestData));
           

        } catch(err) {
            //console.log('in fetchWrapper', err.message);
        }

    }, [user, dispatch]);


    return fetchData;

};




// const result = useMemo(() => {

//     if(shouldFetch) {
//         return fetchData(method, url, payload);
//     }
//     return null;

// }, [method, url, payload, fetchData]);

// return [loading, result];