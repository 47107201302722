// import { useEffect, useState } from 'react';

// function useDebounce(value, delay) {

//   const [debouncedValue, setDebouncedValue] = useState(value);

//   useEffect(() => {
//     const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

//     return () => {
//       clearTimeout(timer);
//     }
//   }, [value, delay]);

//   return debouncedValue;
// }

// export default useDebounce;



// import { useState, useCallback, useRef, useEffect } from 'react';

// function useDebounce(callback, delay) {

//   let callbackRef = useRef(callback);
//   let timerRef = useRef();
//   const [isFirstCall, setIsFirstCall] = useState(true);

//   //console.log('debouncing', 'callbackref', callbackRef, 'timerRef', timerRef);
  
//   // do I need this?
//   useEffect(() => {
//     callbackRef.current = callback;

//   }, [callback]);


//   const debouncedCallback = useCallback( (...args) => {

//       //console.log('args', ...args);
//       //console.log('timer ref', timerRef);
//       clearTimeout(timerRef.current);

//     //   timerRef.current = setTimeout(() => {

//     //     callbackRef.current(...args);
//     //   }, delay);

//     if (isFirstCall) {

//         // invoke callback function
//         callbackRef.current(...args);
//         setIsFirstCall(false);

//         // if not first event add timer to each one for debouncing
//       } else {
//         timerRef.current = setTimeout(() => {
//           callbackRef.current(...args);

//         }, delay);

//       }

//     },[delay, isFirstCall]);


//   return debouncedCallback;
// };


// export default useDebounce;





import { useState, useEffect } from 'react';

function useDebounce(value, delay) {

    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);


    useEffect(() => {

        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);

        }, delay);

        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.

        return () => {
          clearTimeout(handler);
        };

      },[value, delay]); // Only re-call effect if value or delay changes
    
    return debouncedValue;

  };


  export default useDebounce;