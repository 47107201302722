//modules
import { useDispatch } from 'react-redux';
import { addNodeOutput } from '../../../../../redux/slices/nodeMapSlice';

//components
import NodeOutput from './NodeOutput/NodeOutput';

//resources
import plusIconDark from './plusIconDark.svg';
import plusIconLight from './plusIconLight.svg';

export default function NodeOutputs({nodeId, outputs, editable}) {

    // effects
    //----------------------------------------------------------------------------------------------------

    const dispatch = useDispatch();

    // no outputs means do not render
    if (!outputs) { return null; }

    
    // defined functions
    //----------------------------------------------------------------------------------------------------

    function handleAddNodeOutput(e) {
        //console.log(`Adding output to ${nodeId}`);
        dispatch(addNodeOutput({nodeId}));
    }
    
    // dragging connections funtionality
    //----------------------------------------------------------------------------------------------------


    // component
    //----------------------------------------------------------------------------------------------------

    // DO NOT USE INDEX FOR KEY!!!
    return (
        <div className={'node-outputs'}>
            { Object.keys(outputs).map((outputId, index) => { return <NodeOutput key={index} nodeId={nodeId} outputId={outputId} output={outputs[outputId]} editable={editable}/> })}
            <div className={'add-node-output-button-container'}>
                {editable ? <img className={'add-node-output-button'} src={plusIconDark} alt={''} title={'Add Output'} onClick={handleAddNodeOutput}></img> : null }
            </div>
        </div>
    );
}