//modules
import { useSelector, useDispatch } from 'react-redux';
import { addOutputVariable } from '../../../../../redux/slices/nodeMapSlice.js';

//components
import OutputVariable from './OutputVariable/OutputVariable'

//resources
import plusIconLight from './plusIconLight.svg';
import plusIconDark from './plusIconDark.svg';

export default function OutputVariables() {
    
    const dispatch = useDispatch();
    
    let outputVariables = useSelector(state => state?.nodeMap?.nodeMap?.io?.outputVariables);
    if (!outputVariables) { outputVariables = {}; }

    if (!outputVariables) {
        return null;
    }

    // defined functions
    //----------------------------------------------------------------------------------------------

    function handleAddOutputVariable(e) {
        dispatch(addOutputVariable());
    }

    // component structure
    //----------------------------------------------------------------------------------------------

    return (
        <div className={'output-variables-container'}>

            <div className={'output-variables-section-title'}>Output Variables</div>

            <div className={'output-variables'}>
                { Object.keys(outputVariables).map((key, index) => { return <OutputVariable outputVariable={outputVariables[key]} key={index}/> })}
            </div>

            <div className={'add-output-variable-button'} onClick={handleAddOutputVariable}>
                <img className={'add-output-variable-button-icon'} src={plusIconDark}></img>
                <div className={'add-output-variable-button-text'}>Add Output Variable</div>
            </div>

        </div>
    );
}