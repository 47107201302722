import { useSelector, useDispatch } from 'react-redux';
import { updateNodeSetting } from '../../../../../../../redux/slices/nodeMapSlice.js';

export default function CheckBox({nodeId, element, value}) {

    const dispatch = useDispatch();

    const label = element.label;
    const title = element.title;
    const setting = element.setting;

    if (!nodeId || !setting) {
        return null;
    }

    // defined functions
    //----------------------------------------------------------------------------------------------------

    function handleChangeNodeSetting(e) {
        const newValue = e.currentTarget.checked;
        dispatch(updateNodeSetting({nodeId, setting, newValue}));
    }

    // component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={'node-setting-container'}>

            <label className={'node-setting-label'}>{label || setting || ''}</label>

            <input
                type={'checkbox'}
                className={'node-setting-checkbox'}
                title={title || label || setting || ''}
                checked={value}
                onChange={handleChangeNodeSetting}
            ></input>

        </div>
    );
}