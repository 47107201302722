import { nodeMapUtils } from './nodeMapUtils.js';
import { step } from './stepFunction.js';
import config from '../config.js';

async function processNodeMap(nodeMap, extras) {

    console.group('Preprocessing nodeMap');
    console.log(structuredClone(nodeMap));

    extras.nodeMap = nodeMap;
    nodeMap.state = 'processing';
    nodeMap.message = null;

    // Reset state of all output Variables, nodes, node inputs
    nodeMapUtils.resetInputVariables(nodeMap.io.inputVariables);
    nodeMapUtils.resetOutputVariables(nodeMap.io.outputVariables);
    nodeMapUtils.resetNodes(nodeMap.nodes);

    // Port io.chat to io.inputVairables, check type and prescence
    nodeMapUtils.prepareChat(nodeMap.io.chat, nodeMap.io.inputVariables);
    nodeMapUtils.prepareInputVariables(nodeMap.io.inputVariables);
    nodeMapUtils.checkInputVariables(nodeMap.io.inputVariables);

    // If errors, return early with error mesages
    const errors = nodeMapUtils.checkForErrors(nodeMap);

    console.log(structuredClone(nodeMap));
    console.groupEnd();

    if (errors) {
        nodeMap.state = 'error';
        nodeMap.message = errors;

        console.log('Errors before processing: ', errors);
        console.log('Terminating process');

        extras.updateThisNodeMap(nodeMap);
        return;
    }

    extras.updateThisNodeMap(nodeMap);

    // Get entry points and begin processing
    const nodes = nodeMapUtils.getEntryPoints(nodeMap, ['input', 'text', 'memory']);
    console.log('Entry points: ', nodes)

    // Concurrently process nodes in list of entry points
    const promises = nodes.map(node => step(node, extras));
    await Promise.all(promises);
}

export {
    processNodeMap
};