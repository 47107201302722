import React from 'react';
import { ContextMenuComponent } from './ContextMenu/ContextMenuComp.js';

const withContextMenu = (WrappedComponent) => {
  return (props) => (
    <>
      <WrappedComponent {...props} />
      <ContextMenuComponent />
    </>
  );
};

export default withContextMenu;