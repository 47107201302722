// Modules
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateNodeSetting } from '../../../../../../redux/slices/nodeMapSlice.js';

// Code Editor
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-twilight.css';

// Styles
import './customCodeBox.css';

export default function TextContentBox({ nodeId, setting, value, dimensions }) {
  const dispatch = useDispatch();
  const viewportZoom = useSelector( (state) => state.nodeMap.nodeMap.vars.viewportZoom || 1.00 );

  function handleChangeNodeSetting(newValue) {
    dispatch(updateNodeSetting({ nodeId, setting, newValue }));
  }

  function handleResize(e) {
    const textBoxDimensions = {
      width: e.currentTarget.clientWidth,
      height: e.currentTarget.clientHeight,
    };
    dispatch(updateNodeSetting({ nodeId, setting: 'textBoxDimensions', newValue: textBoxDimensions }));
  }

  function forceRedrawConnections(e) {
    if (e.button === 0) {
      const redrawConnectionsEvent = new CustomEvent('redrawConnections');
      window.dispatchEvent(redrawConnectionsEvent);
    }
  }

  const requireConfig = {
    url: 'node_modules/monaco-editor/min/vs/loader.js',
    paths: {
      vs: 'node_modules/monaco-editor/min/vs'
    }
  };

  if (!nodeId || !setting) {
    return null;
  }

  return (
    <div className={'code-editor-container'} style={{width: `${dimensions.width}px`, height: `${dimensions.height}px`}}>
      <Editor
        value={value}
        onValueChange={code => handleChangeNodeSetting(code)}
        highlight={code => highlight(code, languages.js)}
        padding={10}
        style={{
          fontFamily: 'monospace',
          fontSize: 12,
          width: '100%',
          height: '100%',
        }}
      />
    </div>
  );
}