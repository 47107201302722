import { useSelector, useDispatch } from 'react-redux';
import { updateNodeSetting } from '../../../../../../redux/slices/nodeMapSlice.js';

export default function SettingCheckbox({nodeId, setting, value}) {

    const dispatch = useDispatch();

    // component logic
    //----------------------------------------------------------------------------------------------------

    if (!nodeId || !setting) {
        return null;
    }

    // defined functions
    //----------------------------------------------------------------------------------------------------

    function handleChangeNodeSetting(e) {
        const newValue = e.currentTarget.checked;
        dispatch(updateNodeSetting({nodeId, setting, newValue}));
    }

    // component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={'node-setting-container'}>

            <label className={'node-setting-label'}>Use New-line</label>

            <input
                type={'checkbox'}
                className={'node-setting-checkbox'}
                title={'Use new-line when adding text content'}
                checked={value}
                onChange={handleChangeNodeSetting}
            ></input>

        </div>
    );
}