import { useSelector, useDispatch } from 'react-redux';
import { updateNodeSetting } from '../../../../../../redux/slices/nodeMapSlice.js';

export default function TextContentBox({nodeId, setting, value, dimensions}) {

    const dispatch = useDispatch();

    // Component logic
    //----------------------------------------------------------------------------------------------------

    if (!nodeId || !setting) {
        return null;
    }

    // Defined functions
    //----------------------------------------------------------------------------------------------------

    function handleChangeNodeSetting(e) {
        const newValue = e.currentTarget.value;
        dispatch(updateNodeSetting({nodeId, setting, newValue}));
    }

    function handleResize(e) {

        const textBoxDimensions = {
            width: e.currentTarget.clientWidth,
            height: e.currentTarget.clientHeight
        }

        dispatch(updateNodeSetting({nodeId, setting: 'textBoxDimensions', newValue: textBoxDimensions}));
    }

    //force redraw connections when dragging/resizing
    function forceRedrawConnections(e) {
        if (e.button === 0) {
            const redrawConnectionsEvent = new CustomEvent('redrawConnections');
            window.dispatchEvent(redrawConnectionsEvent);
        }
    }

    // Component
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={'node-setting-container'}>

        <textarea
            style={{width: dimensions?.width+'px' || 'auto', height: dimensions?.height+'px' || 'auto'}}
            className={"text-content-box"}
            title="Default Text Content"
            value={value}
            onChange={handleChangeNodeSetting}
            onClick={handleResize}
            onMouseMove={forceRedrawConnections}
        ></textarea>

        </div>
    );
}