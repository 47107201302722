import { useEffect, useRef } from "react";

const useSmoothScroll = () => {

	const ref = useRef(null);
	const scrollPos = useRef(0);  // to store the current scroll position
	const targetPos = useRef(0);  // to store the target scroll position
	const lastPos = useRef(0);  // to store the last scroll position
	let scrolling = false;

	useEffect(() => {

		const scrollContainer = ref.current;

		if (!scrollContainer) {
			return;
		}


		const scrollHandler = (e) => {

			// Prevent normal wheel event
			e.preventDefault();

			// Set position to current scroll position if it's different than last time
			if (!scrolling) {
				targetPos.current = scrollContainer.scrollTop;
				scrollPos.current = scrollContainer.scrollTop;
			}

			// Set new target position and max scroll
			const newTargetPos = targetPos.current + e.deltaY * 0.5; // Adjust speed here
			const maxScroll = scrollContainer.scrollHeight - scrollContainer.clientHeight;

			// Limit target position within [0, maxScroll]
			targetPos.current = Math.max(0, Math.min(maxScroll, newTargetPos));

			// Set scrolling to true and begin animation
			if (!scrolling) {
				scrolling = true;
				requestAnimationFrame(scrollAnimation);
			}
		};


		// This function will be called up to 60 times per second
		const scrollAnimation = () => {
			// Lerp (interpolate) the scroll position
			scrollPos.current += (targetPos.current - scrollPos.current) * 0.25;

			// If the difference between the target scroll position and the current scroll position is less than 0.5, set the current scroll position to the target position.
			if (Math.abs(targetPos.current - scrollPos.current) < 0.5) {
				scrollPos.current = targetPos.current;
				scrolling = false;
			}

			// If the scroll position has changed since the last frame, update the container's scroll position and request another frame.
			if (lastPos.current !== scrollPos.current) {
				scrollContainer.scrollTo(0, scrollPos.current);
				lastPos.current = scrollPos.current;
				requestAnimationFrame(scrollAnimation);
			}
		};

		scrollContainer.addEventListener('wheel', scrollHandler, { passive: false });

		return () => {
			scrollContainer.removeEventListener('wheel', scrollHandler);
		};

	}, []);

	return ref;
};

export { useSmoothScroll };
