import { useSelector, useDispatch } from 'react-redux';
import { updateNodeSetting } from '../../../../../../redux/slices/nodeMapSlice.js';

import './ImageDisplay.css';

export default function ImageDisplay({nodeId, images, format, size, number}) {

    const dispatch = useDispatch();

    // Component logic
    //----------------------------------------------------------------------------------------------------

    // Temporary hack
    const image = images || null;

    // Component
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={'image-display'}>

            <div className={'image-display-background'}>{size || 'any size'}</div>
            {images ? <img className={'image-display-image'} src={image}></img> : null}

        </div>
    );
}