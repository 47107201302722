// Styles
import './UsageChart.css';

// Component
export default function UsageChart({days}) {

    // Initialization
    //----------------------------------------------------------------------------------------------------

    if (days === 'loading' || !days) {
        return null;
    }

    // Calculate min / max percentages
    //----------------------------------------------------------------------------------------------------

    let maxUsage = getMaxUsage(days);

    // Correct for extreemly small amounts
    if (maxUsage < 0.001) { maxUsage = 0.001; }
    else if (maxUsage < 0.010) { maxUsage = 0.010; }
    else if (maxUsage < 0.1) { maxUsage = 0.1; }
    else if (maxUsage < 0.5) { maxUsage = 0.5; }

    function getMaxUsage(days) {

        let maxCost = 0;
      
        days.forEach((dayUsage) => {
          if (dayUsage.cost > maxCost) {
            maxCost = dayUsage.cost;
          }
        });
      
        return maxCost;
      }

    // Formatting functions
    //----------------------------------------------------------------------------------------------------

    // Format cost properly
    function formatCost(cost) {

        // Otherwise format in dollars
        return '$' + (cost).toFixed(2);
    }

    // Format date properly
    function formatDate(dateString) {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        const options = { weekday: 'short' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }


    // Component
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={'usage-chart'}>

            {days.map((dayUsage) => {

                const formattedCost = formatCost(dayUsage.cost);
                const percentOfMax = dayUsage.cost / maxUsage;
                const dayName = formatDate(dayUsage.date);
                let height = percentOfMax;

                return (
                    <div className={'chart-bar-container'} style={{height: (height*100) + '%'}}>
                        <div className={'day-cost'}>{formattedCost}</div>
                        <div className={'bar'}></div>
                        <div className={'day-name'}>{dayName}</div>
                    </div>
                );
            })}

        </div>
    );
}