import { util } from "./util.js";

// Preprocess nodeMap
//----------------------------------------------------------------------------------------------------

// Tasks that are nececary before processing nodes
function preProcess(nodeMap) {

    // Delete chat message and history if not needed
    if (!nodeMap.io.inputVariables.chatHistory && !nodeMap.io.inputVariables.chatMessage) {
        nodeMap.io.chat.message = null;
        nodeMap.io.chat.history = null;
    }

    // Send chat stuff to input variables if needed
    prepareChat(nodeMap.io.chat, nodeMap.io.inputVariables);
    checkInputVariables(nodeMap.io.inputVariables);

    // Completely reset nodeMap
    resetNodeMap(nodeMap);

    // Fill inputs of input nodes
    fillInputNodes(nodeMap);

}

function prepareChat(chat, inputVariables) {

    console.log('preparing chat');

    // TO DO: make it so this isn't neccecary
    if (inputVariables.chatMessage && !chat.message) {
        inputVariables.chatMessage.contents = null;
    }

    // Push chat message to history and set chatMessage inputVariable if needed
    if (inputVariables.chatMessage && chat.message) {

        let message = chat.message;
        const role = inputVariables.chatMessage.chatMessageRole;
        const name = inputVariables.chatMessage.chatMessageName;

        // Convert to chatMessage if needed
        if (util.isChatMessage(message)) { message = util.convertToChatMessage(message); }

        message.role = role || 'user';
        message.name = name || undefined;

        inputVariables.chatMessage.contents = structuredClone(message);
        if (!chat.history) { chat.history = []; }
        chat.history.push(structuredClone(message));
        chat.message = null;
    }

    // Set chatHistory input variable to chat history if needed
    if (inputVariables.chatHistory && chat.history) {
        inputVariables.chatHistory.contents = structuredClone(chat.history);
    }
}

// Convert input variables to correct type if neccecary
async function prepareInputVariables(inputVariables) {

    console.log('preparing input variables');

    for (let inputVariableId in inputVariables) {
        const inputVariable = inputVariables[inputVariableId];
        prepareInputVariable(inputVariable);
    }
}

    async function prepareInputVariable(inputVariable) {

        try {

            if (!inputVariable.contents) {
                return;
            }
        
            if (inputVariable.dataType === 'number') {
                inputVariable.contents = util.convertToNumber(inputVariable.contents);
            }

            if (inputVariable.dataType === 'string') {
                inputVariable.contents = util.convertToString(inputVariable.contents);
            }

            if (inputVariable.dataType === 'chatMessage') {

                const message = util.convertToChatMessage(inputVariable.contents);
                message.role = inputVariable.chatMessageRole || 'user';
                message.name = inputVariable.chatMessageName || undefined;

                inputVariable.contents = message;
            }

            if (inputVariable.dataType === 'imageDataUrl') {
                inputVariable.contents = await util.convertToImageUrl(inputVariable.contents);
            }

            if (inputVariable.dataType === 'audioDataUrl') {
                inputVariable.contents = await util.convertToAudioUrl(inputVariable.contents);
            }
            
        }

        catch (e) {
            inputVariable.state = 'error';
            inputVariable.message = e.message;
        }
    }

// Convert input variables to correct type if neccecary
function checkInputVariables(inputVariables) {

    console.log('checking input variables');

    for (let inputVariableId in inputVariables) {
        const inputVariable = inputVariables[inputVariableId];
        checkInputVariable(inputVariable);
    }
}

    function checkInputVariable(inputVariable) {

        // Check for content
        //--------------------------------------------------

        // Return with error if no contents + required
        if (!inputVariable.contents && inputVariable.requireContent) {
            inputVariable.state = 'error';
            inputVariable.message = `${inputVariable.name} requires input`;

            return;
        }

        // Return with no error if no contents
        if (!inputVariable.contents) {
            return;
        }
        

        // Check for min / max value
        //--------------------------------------------------

        if (inputVariable.maxValue) {
            if (inputVariable.contents > inputVariable.maxValue) {
                inputVariable.state = 'error';
                inputVariable.message = `${inputVariable.name} value (${inputVariable.contents}) is greater than the maximum allowed value (${inputVariable.maxValue})`
            }
        }

        if (inputVariable.minValue) {
            if (inputVariable.contents < inputVariable.minValue) {
                inputVariable.state = 'error';
                inputVariable.message = `${inputVariable.name} value (${inputVariable.contents}) is lesser than the minimum allowed value (${inputVariable.minValue})`
            }
        }


        // Check for min / max length
        //--------------------------------------------------

        if (inputVariable.maxLength) {
            if (inputVariable.contents.length > inputVariable.maxLength) {
                inputVariable.state = 'error';
                inputVariable.message = `${inputVariable.name} length (${inputVariable.contents.length}) is greater than the maximum allowed value (${inputVariable.maxLength})`
            }
        }

        if (inputVariable.minLength) {
            if (inputVariable.contents.length < inputVariable.minLength) {
                inputVariable.state = 'error';
                inputVariable.message = `${inputVariable.name} length (${inputVariable.contents.length}) is lesser than the minimum allowed length (${inputVariable.minLength})`
            }
        }

    }

// Fill each input node with respective input variables
function fillInputNodes(nodeMap) {

    const inputVariables = nodeMap.io.inputVariables;
    const nodes = nodeMap.nodes;

    if (!nodeMap.entryPoints) {
        nodeMap.entryPoints = [];
    }

    for (let nodeId in nodes) {
        const node = nodes[nodeId];

        if (node.type === 'input') {
            const inputVariableId = node.settings.inputVariable;
            if (!inputVariableId) { continue; }
            node.contents = structuredClone(inputVariables[inputVariableId].contents);
        }
    }
}

// Get nodes that we should begin processing on
function getEntryPoints(nodeMap, types) {

    const nodes = nodeMap.nodes;

    console.log(types);

    let entryPoints = [];

    for (let nodeId in nodes) {
        const node = nodes[nodeId];

        if (types.includes(node.type)) {
            entryPoints.push(node);
        }
    }

    return entryPoints;
}

// Check errors
//----------------------------------------------------------------------------------------------------

function checkForErrors (nodeMap) {

    console.log('checking nodeMap for errors');

    let errors = [];

    // Check for input variable errors
    const inputVariables = nodeMap.io.inputVariables;

    for (let inputVariableId in inputVariables) {
        const inputVariable = inputVariables[inputVariableId];

        if (inputVariable.state === 'error') {
            errors.push(inputVariable.message);
        }
    }

    if (errors.length > 0) {
        return errors;
    }

    return false;
}

// Check if processing is complete
//----------------------------------------------------------------------------------------------------

function isNodeMapDone (nodeMap) {

    console.log(structuredClone(nodeMap.nodes));

    // For each node in the nodeMap
    for (let nodeId in nodeMap.nodes) {

        // If a node is still processing or streaming, we're not done yet
        if (['streaming', 'processing'].includes(nodeMap.nodes[nodeId].state)) {
            return false;
        }
    }

    return true;
}

// Reset nodeMap
//----------------------------------------------------------------------------------------------------

// Clears all inputs, outputs, node contents, node states, and output variables
function resetNodeMap(nodeMap) {

    // Set state to unprocessed
    nodeMap.state = 'unprocessed';
    nodeMap.message = null;
    nodeMap.entryPoints = null;
    resetNodes(nodeMap.nodes);
    resetOutputVariables(nodeMap.io.outputVariables);
}

    // Reset all nodes
    function resetNodes(nodes) {

        console.log('resetting nodes');

        for (let nodeId in nodes) {
            const node = nodes[nodeId];
            resetNode(node);
        }
    }

        // Reset single node
        function resetNode(node) {
            node.state = 'unprocessed';
            node.message = null;
            node.contents = null;
            node.streamId = null;
            resetInputs(node.inputs);
            resetOutputs(node.outputs);
        }

            // Reset all inputs
            function resetInputs(inputs) {
                for (let inputId in inputs) {
                    const input = inputs[inputId];
                    resetInput(input);
                }
            }

                // Reset single node input
                function resetInput(input) {
                    input.state = 'empty';
                    input.message = null;
                    input.contents = null;
                    input.streamId = null;
                    input.recursion = null;
                }

            // Reset all outputs
            function resetOutputs(outputs) {
                for (let outputId in outputs) {
                    const output = outputs[outputId];
                    resetOutput(output);
                }
            }

                // Reset single output
                function resetOutput(output) {
                    output.state = 'empty';
                    output.message = null;
                    output.contents = null;
                    output.streamId = null;
                }     
                
    // Reset all output variables
    function resetInputVariables(inputVariables) {

        console.log('resetting input variables');

        for (let inputVariableId in inputVariables) {
            const inputVariable = inputVariables[inputVariableId];
            resetInputVariable(inputVariable);
        }
    }

        function resetInputVariable(inputVariable) {

            inputVariable.state = null;
            inputVariable.message = null;
            inputVariable.streamId = null;
        }


    // Reset all output variables
    function resetOutputVariables(outputVariables) {

        console.log('resetting output variables');

        for (let outputVariableId in outputVariables) {
            const outputVariable = outputVariables[outputVariableId];
            resetOutputVariable(outputVariable);
        }
    }

        function resetOutputVariable(outputVariable) {
            outputVariable.state = 'unfilled';
            outputVariable.message = null;
            outputVariable.streamId = null;
            outputVariable.contents = null;
        }

const nodeMapUtils = {
    preProcess,
    getEntryPoints,
    prepareChat,
    prepareInputVariables,
    checkInputVariables,
    resetNodeMap,
    resetNodes,
    resetInputVariables,
    resetOutputVariables,
    checkForErrors,
    isNodeMapDone
};

export { nodeMapUtils };