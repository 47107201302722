//modules
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateNodeMap, updateNode, updateOutputVariable, updateChatHistory } from '../../redux/slices/nodeMapSlice.js';
import { useNavigate } from "react-router-dom";
import { fetchWrapper } from '../../utils/fetchWrapper.js';
import { useFetchWrapper } from '../../utils/useFetchWrapper.js';
import { processNodeMap } from '../../nodeMapProcessing/nodeMapProcessing.js'
import config from '../../config.js';
import useSnackbar from '../../SnackBar/useSnackBar.js';

//components
import ZoomIndicator from './ZoomIndicator/ZoomIndicator';
import IoVariableField from './IoVariableField/IoVariableField';
import ChatMenu from './ChatMenu/ChatMenu';

//resources
import chatLight from './chatLight.svg';
import chatDark from './chatDark.svg';

//syles
import './IoPanel.css';

export default function IoPanel() {

    // component effects
    //----------------------------------------------------------------------------------------------

    let userData = useSelector((state) => state.user.user);
    let userName = userData ? userData.userName : null;

    // component logic
    //----------------------------------------------------------------------------------------------

    const ioVariables = useSelector((state) => state.nodeMap?.nodeMap?.io);

    const inputVariables = ioVariables?.inputVariables || {};
    const outputVariables = ioVariables?.outputVariables || {};

    // io panel expand / collapse
    //----------------------------------------------------------------------------------------------

    const [expanded, setExpanded] = useState(true);

    //listen for "toggleNodeMapsMenu" and toggle when neccecary
    useEffect( () => {
        window.addEventListener('toggleIoPanel', toggleIoPanel);
        return () => { window.removeEventListener('toggleIoPanel', toggleIoPanel); };
    }, [expanded] );

    function toggleIoPanel() {

        //restore: //console.log("toggling nodemaps menu!");
        //restore: //console.log(expanded)
        
        //for some reason using a negation didn't work!

        if (expanded) { setExpanded(false); return; }
        if (!expanded) { setExpanded(true); return; }
    }

    // submitting info and proccesing nodemap
    //----------------------------------------------------------------------------------------------

    const nodeMap = useSelector((state) => state.nodeMap.nodeMap);
    const dispatch = useDispatch();
    const snackbar = useSnackbar();

    async function handleSubmit(e) {

        if (!userData) {
            snackbar('You need an account to process node maps', 'error');
            return;
        }

        const apiToken = userData.apiToken;
        const apiUrl = config.apiUrl;
        
        // Process nodeMap with current input variables
        await processNodeMap(structuredClone(nodeMap), {apiToken, apiUrl, updateThisNodeMap, updateThisNode, updateThisOutputVariable, updateThisChatHistory});
    }

    async function updateThisNodeMap(nodeMap, noPos = true) {

        // Refresh user balance
        var event = new Event('refreshUserBalance');
        window.dispatchEvent(event);

        // Refresh usage history
        event = new Event('refreshUsageHistory');
        window.dispatchEvent(event);

        dispatch(updateNodeMap({nodeMap, noPos}));
    }

    async function updateThisNode(node, noPos = true) {
        dispatch(updateNode({node, noPos}));
    }

    async function updateThisOutputVariable(outputVariable) {
        dispatch(updateOutputVariable({outputVariable}));
    }

    async function updateThisChatHistory(chatHistory) {
        dispatch(updateChatHistory({chatHistory}));
    }

    if (!ioVariables) {
        return null;
    }

    // Show submit button if we DON'T have a chatMessage input
    const showSubmitButton = typeof ioVariables.inputVariables?.chatMessage === 'undefined' || !ioVariables.inputVariables?.chatMessage.showToUser;

    // component
    //----------------------------------------------------------------------------------------------

    return (
        <div className={'io-panel-container'}>

            <img className={'show-hide-io-panel'} src={chatDark} alt={''} onClick={toggleIoPanel}></img>

            <ZoomIndicator />
            
            <div className={`io-panel-collapsable ${!expanded ? 'io-panel-collapsable-collapsed' : ''}`}>  {/* collapsible from 0px to 400px*/}

                <div className={'io-panel'}>

                    {/* section for input variable fields */}
                    <div className={'io-panel-group'}>
                        { Object.keys(inputVariables).map((key, index) => { return <IoVariableField inputVariable={inputVariables[key]} key={index}/> })}
                    </div>

                    <ChatMenu submitFunction={handleSubmit}/>

                    {/* submit button */}
                    { showSubmitButton ?
                        <div className={'io-panel-submit-container'}>
                            <div className={'io-panel-submit-container-line'}></div>
                            <button className={'io-panel-submit-button'} title={'Proccess nodemap'} onClick={handleSubmit}>Submit</button>
                            <div className={'io-panel-submit-container-line'}></div>
                        </div>
                    : null }

                    {/* section for output variable fields */}
                    <div className={'io-panel-group'}>
                        { Object.keys(outputVariables).map((key, index) => { return <IoVariableField outputVariable={outputVariables[key]} key={index}/> })}
                    </div>

                </div>

            </div>
        </div>
    );

}