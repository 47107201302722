import { useEffect, useState } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";

// custom hooks
import useSnackbar from "../../SnackBar/useSnackBar.js";

import { loadingSVG } from '../../loadingSVG.js';

import config from '../../config.js';
let api = config.apiUrl;


export const ConfirmPassword = () => {

    let [params] = useSearchParams();
    //console.log('params', params);

    let passwordToken = params.get('token');
    //console.log('password token', passwordToken);

    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(false);

    const [passPhrase, setPassPhrase] = useState('');
    const [passPhraseStatus, setPassPhraseStatus] = useState('');

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [id, setId] = useState('');
    const [passwordChanged, setPasswordChanged] = useState(false);

    const [redirecting, setRedirecting] = useState(false);

    const navigate = useNavigate();
    const snackbar = useSnackbar();


    useEffect(() => {

        if(passwordChanged) {
            setRedirecting(true);

            setTimeout(() => {
                navigate('/login');
              }, 4000);
          
        }

    },[passwordChanged]);



    useEffect(() => {

        if(!passwordToken) {
            // go to login if no token
            navigate('/login');
            return;
        }

        confirmPasswordToken();

        async function confirmPasswordToken() {

            setLoading(true);
    
            let url = api + `users/verifyPasswordToken/${passwordToken}`;
    
            try {
    
                let request = await fetch(url, {
                    method: "GET",
                    credentials: "include",
                });
        
                let response = await request.json();
                //console.log('response', response);
                setLoading(false);
        
                if(response.success) {
                    setResponse('validated');
        
                } else {
                    setResponse('unvalidated');
                }
    
            } catch(err) {
            setLoading(false);
            setResponse('error');
            }
    
        }


    }, []);




    let handlePasswordPhrase = async (e) => {
        e.preventDefault();

        if(!passPhrase) {
            return;
        }

        setLoading(true);
    
            let url = api + `users/verifyPasswordPhrase/`;
    
            try {
    
                let request = await fetch(url, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({passPhrase})
                  });
        
                let response = await request.json();
                //console.log('response', response);
                setLoading(false);
        
                if(response.success) {
                    snackbar(response.message, 'success');


                    setResponse(false);
                    setPassPhraseStatus('pass');
                    setId(response.id);
        
                } else {
                    snackbar(response.message, 'error');
                    // setPassPhraseStatus('fail');
                }
    
            } catch(err) {
                setLoading(false);
                setResponse('error');
            }
    };




    let handlePasswordChange = async (e) => {
        e.preventDefault();

        if(!password || !confirmPassword) {
            return;
        }

        if(password !== confirmPassword) {
            snackbar('Both password inputs must match', 'error');
            return;
        }

        setLoading(true);
    
            let url = api + `users/updatePassword`;
    
            try {

                let payload = {
                    id: id,
                    password: password
                };
    
                let request = await fetch(url, {
                    method: "PUT",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload)
                  });
        
                let response = await request.json();
                //console.log('response', response);
                setLoading(false);
        
                if(response.success) {
                    // setResponse(false);
                    snackbar(response.message, 'success');
                    setPassPhraseStatus(false);
                    setPasswordChanged(true);
        
                } else {
                    snackbar(response.message, 'error');
                }
    
            } catch(err) {
                setLoading(false);
                setResponse('error');
            }
    };

      

    if(!passwordToken) {
        return;
    }



    return (
        <div className="app-root">
            <div className="confirm-email-container">
                { loading ? 
                <div className="loading" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <p>confirming...</p>
                    <div>{loadingSVG}</div>
                </div> : null }
                { response === false && null }
                { response === 'validated' && 
                    <div className="auth-form-container">
                       <h2 className="auth-title">Verify Code</h2>
                       <form className="login-form" onSubmit={handlePasswordPhrase}>
                           <div className="input-container">
                               <label htmlFor="text/input">code</label>
                               <input
                                   value={passPhrase}
                                   onChange={(e) => setPassPhrase(e.target.value)}
                                   type="text"
                                   placeholder="########-####-####-####-############"
                                   id="phrase"
                                   name="phrase"
                                   requireContent // Add this attribute for validation
                               />
                           </div>
                           <button
                               className="auth-button"
                               type="submit"
                               disabled={!passPhrase}
                           >
                              {loading ? loadingSVG : 'Verify'}
                           </button>
                       </form>
                   </div>
                    }

                { response === 'unvalidated' &&
                    <div> 
                        <p>There was an issue verifying your confirmation link</p>
                        <Link to={`/password`}>Click here to get a new password reset email</Link>
                    </div> }

                { response === 'error' &&
                    <>
                        <p>A network error has occured. Please try refreshing the page.</p> 
                        <p>If the issue persists please contact support</p> 
                    </> }


                { passPhraseStatus === 'pass' && 
                    <div className="auth-form-container">
                    <h2 className="auth-title">New Password</h2>
                    <form className="login-form" onSubmit={handlePasswordChange}>
                        <div className="input-container">
                            <label htmlFor="text/input">password</label>
                            <input
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                                placeholder="********"
                                id="password"
                                name="password"
                                requireContent // Add this attribute for validation
                            />
                        </div>
                        <div className="input-container">
                            <label htmlFor="text/input">confirm password</label>
                            <input
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                type="password"
                                placeholder="********"
                                id="password"
                                name="password"
                                requireContent // Add this attribute for validation
                            />
                        </div>
                        <button
                            className="auth-button"
                            type="submit"
                            disabled={!password || !confirmPassword}
                        >
                           {loading ? loadingSVG : 'Change Password'}
                        </button>
                    </form>
                </div> }


                { redirecting && 
                <div className="loading" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <p>Redirecting you to the login page</p>
                    <div>{loadingSVG}</div>
                </div> }

            </div>
        </div>
    );

};