// Modules
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Config
import config from '../../../../config';

// Styles
import './UserBalanceDisplay.css';

export default function UserBalanceDisplay() {

    const userName = useSelector((state) => state.user.user.userName);
    const userBalanceUrl = config.apiUrl + 'users/balance/' + userName;

    const [accountBalance, setAccountBalance] = useState(null);

    // Effect to handle fetching user balance
    useEffect(() => {
        getUserBalance();
        window.addEventListener("refreshUserBalance", getUserBalance);
        return () => { window.removeEventListener("refreshUserBalance", getUserBalance); };
    }, [userName, userBalanceUrl]);

    // Make request to get current balance
    async function getUserBalance () {

        const rawResponse = await fetch(userBalanceUrl);
        const jsonResponse = await rawResponse.json();

        if (jsonResponse.success) {
            setAccountBalance(jsonResponse.userBalance)
        }
    }

    // Handle low account balance
    //----------------------------------------------------------------------------------------------------

    let lowBalance = false;
    if (accountBalance < 0) {
        lowBalance = true;
    }

    // Component
    //----------------------------------------------------------------------------------------------------

    return (
        <div
        className={`user-balance-display ${lowBalance ? 'user-balance-display-low' : null}`}
        title={lowBalance ? 'low account balance' : null}
        >
            {accountBalance !== null ? `$${accountBalance.toFixed(2)}` : ''}
        </div>
    );
};