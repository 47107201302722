// Modules
import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateNodeSetting } from '../../../../../../../redux/slices/nodeMapSlice.js';

// Styles
import './NumberBox.css';

export default function NumberBox({nodeId, element, value}) {

    // Define important variables

    const setting = element.setting;
    const label = element.label;
    const title = element.title;
    const min = element.min;
    const max = element.max;
    const step = element.step;

    if (!value) {
        value = '';
    }

    // Define dispatch function
    const dispatch = useDispatch();

    // Capture scroll wheel
    //----------------------------------------------------------------------------------------------------

    function handleWheelEvent(e) {
        e.stopPropagation();
    }
    
    // Updating node setting
    //----------------------------------------------------------------------------------------------------

    function handleChangeNodeSetting(e) {
    
        const newValue = Number(e.currentTarget.value);

        // Ensure value is a real number
        if (isNaN(newValue)) {  return; }

        if (newValue < min || newValue > max) { return; }

        dispatch(updateNodeSetting({nodeId, setting, newValue}));
    }

    // Component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={'node-setting-container'} onWheel={handleWheelEvent}>

            <label className={'node-setting-label'}>{label || ''}</label>

            <input

                className={'numberbox'}
                title={title || label || setting || ''}
                type={'number'}
                value={value || ''}
                min={min || ''}
                max={max || ''}
                step={step || ''}

                onChange={handleChangeNodeSetting}

                onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()}

                style={{minWidth: `${value.toLocaleString('fullwide', {useGrouping:false}).length + 3}ch`}}
            ></input>

        </div>
    );
}