//modules
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import useSnackbar from "../../../../SnackBar/useSnackBar.js";

//scripts
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

//resources
import crossLight from './crossLight.svg';
import crossDark from './crossDark.svg';
import shareIconLight from './shareIconLight.svg';
import shareIconDark from './shareIconDark.svg';

//styles
import './NodeMapsListItem.css';

export default function NodeMapsListItem(props) {

    const navigate = useNavigate();
    const snackBar = useSnackbar();

    const { nodeMapId } = useParams();

    const [isDeleted, setIsDeleted] = useState(false);
    const [isCreated, setIsCreated] = useState(false);


    const deleteNodeMap = props.deleteNodeMap;
    const { id, type } = props.id;
    const demo = props.demo;

    // Create time formatter (English).
    TimeAgo.addLocale(en);
    const timeAgo = new TimeAgo('en-US');

    let nodeMap = props.nodeMap;

    // if no nodemap name use id
    let nodeMapName = nodeMap.info.name === null ? `${nodeMap.id}` : nodeMap.info.name;
    
    let nodeMapUrl = '/sandbox/'+nodeMap.id;
    let lastEditedTime = nodeMap.info.dateModified;

    let lastEdited = 'Last Edited';
    lastEdited = timeAgo.format(lastEditedTime);


    useEffect(() => {
        if (id === nodeMap.id) {

            if (type === 'created') {
              return setIsCreated(true);

            } else if (type === 'deleted') {
              return setIsDeleted(true);
            }

            return;
          }
        

      }, [id]);


    //send api request to delete nodemap
    function getNodeMapIdAndDelete(e) {
        let nodeMapIdToDelete = e.target.getAttribute("nodemapid");
        deleteNodeMap(nodeMapIdToDelete);
    }

    //copies nodeMap URL to clipboard
    function shareNodeMap(e) {
        let nodeMapIdToShare = e.target.getAttribute("nodemapid");
        navigator.clipboard.writeText(`http://localhost:3000/sandbox/${nodeMapIdToShare}`);
        snackBar('Nodemap url copied to clipboard', 'success');
    }

    // Navigate to new nodeMap
    // ----------------------------------------------------------------------------------------------------

    const currentNodeMapId = useSelector( (state) => state.nodeMap.nodeMap.id);
    function handleNavigateToNodeMap(nodeMapId) {

        // Don't navigate to the current nodeMap
        if (nodeMapId === currentNodeMapId) {
            return;
        }

        navigate('/sandbox/' + nodeMapId);
    }

    // Component
    // ----------------------------------------------------------------------------------------------------

    return (
        <div className={`nodemaps-list-item ${isDeleted ? "deleted-item-animation" : ""} `}
            onAnimationEnd={() => {
                
                // if the deleted nodemap is the current one then navigate to sandbox after animation
                if(nodeMapId === nodeMap.id) {
                    navigate('/sandbox/');
                }

          }}>
            <div className={'nodemaps-list-item-info'}>
                <div className={'nodemaps-list-item-name'} title={'go to nodemap'} onClick={()=>{handleNavigateToNodeMap(nodeMap.id)}}>{nodeMapName}</div>
                <div className={'nodemaps-list-item-last-edited'} title={`last edited ${lastEdited}`} href={nodeMapUrl}>{lastEdited}</div>
            </div>

            <div className={'nodemaps-list-item-buttons'}>
                
                { demo ?
                    <img className={'nodemaps-list-item-delete-disabled'} alt="delete button icon" src={crossLight} nodemapid={nodeMap.id}></img>
                :
                    <img className={'nodemaps-list-item-delete'} title={'delete nodemap'} alt="delete button icon" src={crossLight} onClick={getNodeMapIdAndDelete} nodemapid={nodeMap.id}></img>
                }
                
                <img className={'nodemaps-list-item-share'} title={'share nodemap'} alt="share button icon" src={shareIconLight} onClick={shareNodeMap} nodemapid={nodeMap.id}></img>
            </div>
        </div>
    );

}