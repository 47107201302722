import { useEffect, useContext, useRef } from 'react';

export default function SandboxGrid({ nodeMap, viewportPosition, viewportZoom }) {

    const canvasRef = useRef(null);

    const updateCanvasSize = () => {

        if (canvasRef.current) {
            const canvas = canvasRef.current;
            canvas.width = canvas.clientWidth;
            canvas.height = canvas.clientHeight;
        }
    };

    useEffect(() => {

        updateCanvasSize();
        drawGrid();

        window.addEventListener('redrawGrid', updateCanvasSize);

        return () => {
            window.removeEventListener('redrawGrid', updateCanvasSize);
        };

    }, [viewportZoom]);

    useEffect(() => {

        updateCanvasSize();
        drawGrid();

    }, [viewportZoom]);

    updateCanvasSize();

    const drawGrid = () => {

        // Gaurd clause
        if (!canvasRef.current) { return; }

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Set grid properties
        const gridSize = 75;
        const gridColor = '#00000030';
        ctx.strokeStyle = gridColor;
        ctx.lineWidth = 1 / viewportZoom;

        // Calculate the starting points based on the viewportPosition
        const offsetX = -1*viewportPosition.x % gridSize;
        const offsetY = -1*viewportPosition.y % gridSize;

        // Draw vertical lines
        for (let x = offsetX; x < canvas.width; x += gridSize) {

            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(x, canvas.height);
            ctx.stroke();
        }

        // Draw horizontal lines
        for (let y = offsetY; y < canvas.height; y += gridSize) {

            ctx.beginPath();
            ctx.moveTo(0, y);
            ctx.lineTo(canvas.width, y);
            ctx.stroke();
        }
    };

    drawGrid();

    return <canvas className={'sandbox-grid'} ref={canvasRef}></canvas>;
}