// Modules
import config from '../../../../config.js';
import icon from './icon.png';
import { util } from '../../../../nodeMapProcessing/util.js';
import { EdisonAI } from '../../../../EdisonAI/EdisonAI.js';

const title = 'Blip';
const description = '';

// JSON template
//----------------------------------------------------------------------------------------------------

// Node JSON template
const template = {
      
    category: "image",
    name: "Blip: Image to Text",
    type: "blip",
    position: null,

    settings: {
      showIcon: true,
      infoLink: 'https://replicate.com/salesforce/blip',

      canEditInputs: false,

      modelType: 'image-description-model',
      modelProvider: 'Replicate',
      modelName: 'blip',

      defaultImage: null,
      scaleBeforeSend: '0.5',
    },

    stats: {
      processing: {
        meanTime: 5000
      }
    },

    gui: [
      {
        group: 'horizontal',
        elements: [
            { element: 'dropdown', label: 'image size to send', setting: 'scaleBeforeSend', options: ['256xAuto', '512xAuto', '1024xAuto']},
        ]
      },
      {
        group: 'horizontal',
        elements: [
          { element: 'imageDisplay', setting: 'defaultImage' },
        ]
      },
      // Advanced settings
      {
        group: 'horizontal',
        elements: [
          { element: 'toggleShowAdvanced', setting: 'showAdvanced' },
        ]
      },
      {
        group: 'horizontal',
        elements: [
          { element: 'checkbox', label: 'tolerate errors', setting: 'tolerateErrors', advanced: true },
          { element: 'numberbox', label: 'max retries', setting: 'maxRetries', min: 0, advanced: true, displayCondition: {setting: 'tolerateErrors', value: [true]} },
        ]
      },
    ],

    contents: "",

    inputs: {
      "image": {
        
        name: 'image',
        type: 'image',
        dataType: 'imageUrl',
        requireContent: true,
        contents: null,
      }
    },

    outputs: {
      "description": {
        
        name: 'description',
        type: 'text',
        dataType: 'string',
        contents: null,
        destinations: {},
        clearOnSend: true
      }
    }
  }

// Processing function
//----------------------------------------------------------------------------------------------------

class process {

	// Callbacks
	//--------------------------------------------------

	constructor() {

		this.onUpdateCallback =     () => { console.warn('no onUpdate callback')};
		this.onStreamCallback =     () => { console.warn('no onStream callback')};
		this.onCompleteCallback =   () => { console.warn('no onComplete callback')};
		this.onWarningCallback =    () => { console.warn('no onWarning callback')};
		this.onErrorCallback =      () => { console.warn('no onError callback')};
		this.onGiveUpCallback =     () => { console.warn('no onGiveUp callback')};

	}

	// Set callback functions
	onUpdate(callback) { this.onUpdateCallback = callback; }
	onStream(callback) { this.onStreamCallback = callback; }
	onComplete(callback) { this.onCompleteCallback = callback; }
	onWarning(callback) { this.onWarningCallback = callback; }
	onError(callback) { this.onErrorCallback = callback; }
	onGiveUp(callback) { this.onGiveUpCallback = callback; }

	// Fire functions easily
	update(node) { this.onUpdateCallback(node); }
	stream(node) { this.onStreamCallback(node); }
	finish(node) { this.onCompleteCallback(node); }
	warn(message) { this.onWarningCallback(message); }
	error(message) { this.onErrorCallback(message); }
	giveUp(message) { this.onGiveUpCallback(message); }


  	// Run node
  	//--------------------------------------------------

	// Node processing function
	async run (node, extras) {

		const apiToken = extras.apiToken;

		try {

			// Node must have valid 'image' input
			const imageInput = node.inputs['image'];

			if (!imageInput.contents) {
				console.log('image input empty!');
				return;
			}

			// Before request
			//----------------------------------------

			const blip = new EdisonAI();

			blip.onComplete((data) => {

				console.log('complete: ', data);

				const result = data.result;

				util.setContents(node, result);
				util.clearInputs(node);
				util.fillOutputs(node);
				this.finish(node);
				
				return;
			});

			blip.onError((data) => {
				console.warn('BLIP ERROR: ', data.error);
				this.error(data.error);
				return;
			});

			const model = 'blip';
			const input = imageInput.contents;
			const settings = {}

			await blip.run(model, input, settings, apiToken);

			return;
		}

		catch (e) {
			this.error(e.message);
			return;
		}
  	}
}

// Export
//----------------------------------------------------------------------------------------------------

export const blip = {
    template,
    process,
    icon,
    title,
    description
}