import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loggedIn: false,
  user: null,
  reAuthenticating: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {

    // doesn't actually mutate the state because it uses the Immer library,
    login: (state, action) => {
        state.loggedIn = true
        state.user = action.payload.user;
    },

    logout: (state) => {
        state.loggedIn = false
        state.user = null;
    },

    reauth: (state, action) => {
      state.reAuthenticating = action.payload.awaitingToken;
    },

  }

});


// Action creators are generated for each case reducer function
export const { login, logout, reauth } = userSlice.actions;
export default userSlice.reducer;