// modules
import { useEffect, useContext, useRef, useState } from 'react';
import { useSelector} from 'react-redux';

// context
import SandboxContext from '../SandboxContext';

const INACTIVE_COLOR = "#00000080";
const ACTIVE_COLOR = "#005EFFBF";

export default function SandboxConnections({nodeMap, viewportPosition, viewportZoom}) {

    // Get all connections
    //----------------------------------------------------------------------------------------------------

    const connections = listConnections(nodeMap);

    // Connection dragging
    //----------------------------------------------------------------------------------------------------

    const { dragConnectionState, setDragConnectionState } = useContext(SandboxContext);
    const canvasRef = useRef(null);
    
    useEffect(() => {

        if (canvasRef.current) {
            
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');

            context.clearRect(0, 0, canvas.width, canvas.height);
    
            // Draw all connections anyway!
            handleRedrawConnections();
    
            // Add "redrawConnections" event listener
            window.addEventListener('redrawConnections', handleRedrawConnections);
    
            // Create a ResizeObserver instance and observe the canvas
            const resizeObserver = new ResizeObserver(handleRedrawConnections);
            resizeObserver.observe(canvas);
    
            // Function to handle "redrawConnections" event
            function handleRedrawConnections () {

                // Update canvas dimensions to match its actual size in the browser
                canvas.width = canvas.clientWidth;
                canvas.height = canvas.clientHeight;
    
                drawAllConnections(context);
            };

            // Clean up the event listeners and observer on unmount
            return () => {
                window.removeEventListener('redrawConnections', handleRedrawConnections);
                resizeObserver.disconnect();
            };
        }
    }, [canvasRef, nodeMap, dragConnectionState, connections]);


    // Animating streaming lines
    //----------------------------------------------------------------------------------------------------

    const [dashOffset, setDashOffset] = useState(0);
    const [showStreamAnimation, setShowStreamAnimation] = useState(false);


    useEffect(() => {

        // If there's no canvas or we're dragging a connection, don't run the effect
        if (!canvasRef.current || dragConnectionState.draggingConnection || !showStreamAnimation) { return; }

        let animationId;
        let start;

        const updateDashOffset = (timestamp) => {
            // Initialize the start time
            if (start === undefined) {
                start = timestamp;
            }
            // Calculate elapsed time
            const elapsed = timestamp - start;
            // Update the dash offset based on elapsed time, but only if not dragging a connection
            if (!dragConnectionState.draggingConnection) {
                setDashOffset((elapsed / 10));  // Adjust the divisor as needed to change speed
            }
            // Request the next frame
            animationId = requestAnimationFrame(updateDashOffset);
        };

        // Start the animation loop
        animationId = requestAnimationFrame(updateDashOffset);

        // Clean up the animation loop on unmount
        return () => {
            if (animationId) {
                cancelAnimationFrame(animationId);
            }
        };
    }, [canvasRef, dragConnectionState.draggingConnection, showStreamAnimation]);


    // Animating Connection progress
    //----------------------------------------------------------------------------------------------------

    const [activationProgresses, setActivationProgresses] = useState({});
    const nodeDelay = 1000 - (1000 * nodeMap.vars.flowSpeed) + 1;
    const identicalStateCyclesThreshold = 1;  // Define your threshold here

    const identicalStateCyclesRef = useRef(0);
    const prevStateRef = useRef(activationProgresses);

    useEffect(() => {

        const timer = setInterval(() => {
            const now = Date.now();
            const newActivationProgresses = {...activationProgresses};
    
            for (let id in connections) {
                const connection = connections[id];
    
                const targetContents = nodeMap.nodes[connection.target.nodeId].inputs[connection.target.inputId].contents;
                const streaming = nodeMap.nodes[connection.origin.nodeId].outputs[connection.origin.outputId].state === 'streaming';
                const active = (targetContents !== null && targetContents !== '' && typeof targetContents !== 'undefined') || streaming;
    
                if (active) {
                    const activationStart = newActivationProgresses[id]?.start || now;
                    const elapsedTime = (now - activationStart) / (nodeDelay * 0.85);
                    const progress = Number.isFinite(elapsedTime) ? Math.min(1, elapsedTime) : 0;
                    newActivationProgresses[id] = { start: activationStart, progress };

                } else {
                    delete newActivationProgresses[id];
                }
            }
    
            // Compare with the previous state
            if (JSON.stringify(prevStateRef.current) === JSON.stringify(newActivationProgresses)) {
                identicalStateCyclesRef.current++;
            } else {
                identicalStateCyclesRef.current = 0;
                prevStateRef.current = newActivationProgresses;
            }
    
            // If the state has remained identical for the threshold number of cycles, stop running
            if (identicalStateCyclesRef.current >= identicalStateCyclesThreshold) {
                clearInterval(timer);
                return;
            }
    
            setActivationProgresses(newActivationProgresses);
    
            if (canvasRef.current) {
                const context = canvasRef.current.getContext('2d');
                drawAllConnections(context);
            }

            console.log('render')
    
        }, 1);
    
        return () => {
            clearInterval(timer);
        };
    
    }, [connections, nodeMap, activationProgresses]);
    


    // Drawing connections
    //----------------------------------------------------------------------------------------------------

    function drawAllConnections(context) {

        // Showing dragging connections
        //--------------------------------------------------

        if (dragConnectionState.draggingConnection) {

            const start = getOriginPosition(dragConnectionState.origin);
            const end = dragConnectionState.mousePos;

            if (!end.x || !end.y) {
                return;
            }

            drawConnection(context, start, end, {endOffset: 2, arrowLength: 1});
        }

        // Showing connected connections
        //--------------------------------------------------

        // This will tell us if any connection is streaming
        let anyConnectionStreaming = false;

        // Loop through all connections
        for (let connectionId in connections) {

            const connection = connections[connectionId];

            const start = getOriginPosition(connection.origin);
            const end = getTargetPosition(connection.target);

            // Give up if incomplete data
            if (!start || !end) { continue; }

            // Determine if connection is streaming
            let streaming = false;
            if (nodeMap.nodes[connection.origin.nodeId].outputs[connection.origin.outputId].state === 'streaming') {
                streaming = true;
                anyConnectionStreaming = true;
            }

            const originContents = nodeMap.nodes[connection.origin.nodeId].outputs[connection.origin.outputId].contents;
            const targetContents = nodeMap.nodes[connection.target.nodeId].inputs[connection.target.inputId].contents;

            //connection is 'active' if the origin has contents
            const active = (targetContents !== null && targetContents !== '' && typeof targetContents !== 'undefined') || streaming;

            // For animating progress
            const progress = activationProgresses[connectionId]?.progress || 0;

            drawConnection(context, start, end, {active, streaming, progress});
        }

        // If any connection is streaming, show animation
        if (setShowStreamAnimation(anyConnectionStreaming));
    }

    function drawConnection(context, start, end, params) {

        if (!params) { params =  {} };
        const streaming = params.streaming || false;

        // Give up if incomplete data
        if (!start || !end) { return; }

        const active = params.active || false;
        const progress = params.progress || 0;
        const arrowAngle = params.arrowAngle || 30;
        const arrowLength = params.arrowLength || 10;
        const arrowThickness = params.arrowThickness || 2;
        const startOffset = params.startOffset || 15;
        const endOffset = params.endOffset || 15;

        // Correct for input / output margins
        start.x = start.x + startOffset;
        end.x = end.x - endOffset;
      
        let horizontalDifference = Math.abs(end.x - start.x);
        let verticalDifference = Math.abs(end.y - start.y);

        if (horizontalDifference === 0) { horizontalDifference = 1; }
        if (horizontalDifference === 0) { horizontalDifference = 1; }
      
        let cpOffset = 5 + Math.pow(Math.abs(verticalDifference), 0.75);
      
        if (end.x < start.x) {
          cpOffset = cpOffset + Math.pow(Math.abs(horizontalDifference), 0.75);
        }
      
        let cp1 = { x: start.x + cpOffset, y: start.y };
        let cp2 = { x: end.x - cpOffset - 25, y: end.y };
      
        context.lineWidth = arrowThickness;
      
        // Active is blue, inactive is dark grey
        if (active) { context.strokeStyle = ACTIVE_COLOR; }
        if (!active) { context.strokeStyle = INACTIVE_COLOR; }

        // Set line style based on 'streaming' state
        if (streaming) {
            context.lineWidth = context.lineWidth + 1;
            context.setLineDash([15, 15]);  // Set dashed line style
            context.lineDashOffset = -dashOffset;  // Apply the dash offset
        } else {
            context.setLineDash([]); // Reset to solid line
            context.lineDashOffset = 0;

            if (active) {
                const gradient = context.createLinearGradient(start.x, start.y, end.x, end.y);
                gradient.addColorStop(0, ACTIVE_COLOR);
                gradient.addColorStop(progress, ACTIVE_COLOR);  // active up to progress
                gradient.addColorStop(progress, INACTIVE_COLOR);       // inactive starting from progress
                gradient.addColorStop(1, INACTIVE_COLOR);
                context.strokeStyle = gradient;
            }
        }

        
      
        context.beginPath();
        context.moveTo(start.x, start.y);
        context.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, end.x, end.y);
        context.stroke();
      
        // Draw arrow at the end
        drawArrow(context, end, arrowAngle, arrowLength, arrowThickness);
      }
      
      function drawArrow(context, end, arrowAngle, arrowLength, arrowThickness) {
        // Calculate the angle of the line
        const lineAngle = 0;
      
        // Calculate the coordinates of the arrow points
        const arrowEnd1 = {
          x: end.x - arrowLength * Math.cos(lineAngle + arrowAngle * (Math.PI / 180)),
          y: end.y - arrowLength * Math.sin(lineAngle + arrowAngle * (Math.PI / 180)),
        };
      
        const arrowEnd2 = {
          x: end.x - arrowLength * Math.cos(lineAngle - arrowAngle * (Math.PI / 180)),
          y: end.y - arrowLength * Math.sin(lineAngle - arrowAngle * (Math.PI / 180)),
        };

        // Arrow is always solid
        context.setLineDash([]);
      
        // Draw the arrow
        context.beginPath();
        context.moveTo(end.x, end.y);
        context.lineTo(arrowEnd1.x, arrowEnd1.y);
        context.moveTo(end.x, end.y);
        context.lineTo(arrowEnd2.x, arrowEnd2.y);
        context.stroke();
      }

    //gets the position of an origin input marker by querySelecting() for special attribute
    function getOriginPosition(origin) {

        if (!origin?.nodeId || !origin?.outputId) { return null; }

        const origin_id = origin.outputId + ' of ' + origin.nodeId;
        const element = document.querySelector(`[origin_id="${origin_id}"]`);
    
        if (element) {
            const rect = element.getBoundingClientRect();
            const canvasRect = canvasRef.current.getBoundingClientRect();
            
            return {
                x: (rect.left + rect.width / 2 - canvasRect.left) / viewportZoom,
                y: (rect.top + rect.height / 2 - canvasRect.top)  / viewportZoom,
            };
        } else {
            //console.log('Element not found:', origin_id);
            return {x: 0, y: 0};
        }
    }

    //gets the position of an origin input marker by querySelecting() for special attribute
    function getTargetPosition(target) {

        if (!target?.nodeId || !target?.inputId) { return null; }

        const target_id = target.inputId + ' of ' + target.nodeId;
        const element = document.querySelector(`[target_id="${target_id}"]`);
    
        if (element) {
            const rect = element.getBoundingClientRect();
            const canvasRect = canvasRef.current.getBoundingClientRect();
            
            return {
                x: (rect.left + rect.width / 2 - canvasRect.left) / viewportZoom,
                y: (rect.top + rect.height / 2 - canvasRect.top) / viewportZoom,
            };

        } else {
            //console.log('Element not found:', target_id);
            return {x: 0, y: 0};
        }
    }

    // misc functions
    //----------------------------------------------------------------------------------------------------
    
    function listConnections(nodeMapObject) {

        const destinationsArray = deepFindKey(nodeMapObject, 'destinations');
        
        let connectionsList = {};

        for (const obj of destinationsArray) {
            if (typeof obj === 'object' && obj !== null) {
                connectionsList = { ...connectionsList, ...obj };
            }
        }

        return connectionsList;
    };

    function deepFindKey(obj, key) {

        let results = [];
    
        for (const [k, v] of Object.entries(obj)) {
            if (k === key) {
                results.push(v);
            } else if (typeof v === 'object' && v !== null) {
                results = results.concat(deepFindKey(v, key));
            }
        }
    
        return results;
    }

    return (
        <canvas className={'sandbox-connections'} ref={canvasRef}></canvas>
    );
}
