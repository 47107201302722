import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';

import './contextMenu.css';

export function ContextMenuComponent() {

    // context menu items
    let menuItems = ['Delete node', 'Create new group'];
    // const [menuItems, setMenuItems] = useState(defaultMenuItems);

    const[position, setPosition] = useState({x: 0, y: 0});
    const[displayStatus, setDisplayStatus] = useState(false);
    const[clickPlacement, setClickPlacement] = useState(false);

    const contextMenuRef = useRef(null);


      useLayoutEffect(() => {

        if (displayStatus && contextMenuRef.current) {

            let viewWidth = window.visualViewport.width;
            let viewHeight = window.visualViewport.height;

            const { width, height } = contextMenuRef.current.getBoundingClientRect();

            // extra padding
            let padding = 20;

            // click coordinates
            let x = clickPlacement.x;
            let y = clickPlacement.y;


            // limits
            let xLimit = x + width + padding;
            let yLimit = y + height + padding;


            // new positions
            let newXPos = x - width;
            let newYPos = y - height;

            // handle edge case first
            if(xLimit > viewWidth && yLimit > viewHeight) {
                setPosition({ x: newXPos, y: newYPos });
                return;
            }


            if(xLimit > viewWidth) {
    
                setPosition({ x: newXPos, y: y });
                return;

            }


            if(yLimit > viewHeight) {
               
                setPosition({ x: x, y: newYPos });
                return;
            }


            setPosition({ x: x, y: y});
           
        }

      }, [displayStatus, clickPlacement]);

    


    useEffect(() => {

        const handleContextMenu = (e) => {

            /*

            if (!e.ctrlKey) {
                // stop browser from opening up menu
                e.preventDefault();

                // set open status
                setDisplayStatus(true);


                // set click placement
                let x = e.pageX;
                let y = e.pageY;
                setClickPlacement({x: x, y: y});

                // setPosition({ x: x, y: y});
            }
            */

        }


        const handleSelection = (e) => {

            // if no context menu do nothing on click
            if(!contextMenuRef.current) {
                return;
            }


            // check if click happened outside of the menu
            if(!contextMenuRef.current.contains(e.target)) {
                setDisplayStatus(false);
                return;
              }

            // check if click happened inside of the menu
            if(contextMenuRef.current.contains(e.target)) {
                
                // get selection
                let selection = e.target.innerText;
                //console.log(selection);

                // do something with selection

                // call close function when done
                handleClose(e);
            }


           return;
        }


        const handleClose = (e) => {
            // set open status
            setDisplayStatus(false);
        }


        window.addEventListener('contextmenu', handleContextMenu);
        window.addEventListener('click', handleSelection);

        return () => {

            window.removeEventListener('contextmenu', handleContextMenu);
            window.removeEventListener('click', handleSelection);
        };
        
    }, []);


    if (!displayStatus) {
        return null;
      }


    return (
        <>
         <div ref={contextMenuRef} className='context-menu' style={{ position: "absolute", top: position.y, left: position.x }}>
            <ul>
                {menuItems.map((item, index) => {
                    return <li key={item}>{item}</li>
                })}
            </ul>
        </div>
        </>
    );
}