import { useSelector, useDispatch } from 'react-redux';


const handleUpdate = () => {
    //restore: //console.log('handling profile update');
}

const Profile = () => {

    let userSelector = useSelector((state) => state.user.user);
    //restore: //console.log(userSelector);
    let email = userSelector.email;

    let timeStamp = userSelector.lastLogin;
    let date = new Date(timeStamp);
    let displayDate = date.toString();
    
    return(
        <div>
            <p>profile</p>
            <div><p>Email: {email}</p></div>
            <div>UserId: {userSelector.userId}</div>
            <div>Last Login: {displayDate}</div>
            <button type="submit" onClick={() => handleUpdate()}>Update Profile</button>
        </div>
    );
};

export default Profile;