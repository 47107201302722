// Modules
import config from '../../../../config.js';
import icon from './icon.png';
import { util } from '../../../../nodeMapProcessing/util.js';
import { EdisonAI } from '../../../../EdisonAI/EdisonAI.js';
import AV from 'av';

const title = 'Prime Voice';
const description = '';

// JSON template
//----------------------------------------------------------------------------------------------------

// Node JSON template
const template = {

	category: "audio",
	name: "Prime Voice",
	type: "primeVoice",
	position: null,

	settings: {
		showIcon: true,
		infoLink: 'https://docs.elevenlabs.io/welcome/introduction',

		canEditInputs: false,
		modelType: 'text-to-speech-model',
		modelProvider: 'Elevenlabs',

		mode: 'chat',

		model_id: 'eleven_monolingual_v1',
		voice_id: '21m00Tcm4TlvDq8ikWAM',
		similarity_boost: 0.5,
		stability: 0.5,
		optimize_streaming_latency: 1,
		maxWords: 1000,
	},

	stats: {
		processing: {
			meanTime: 10000
		}
	},

	gui: [
		{
			group: 'horizontal', elements: [
				{ element: 'dropdown', label: 'model', setting: 'model_id', options: ['eleven_monolingual_v1', 'eleven_multilingual_v1'] },
				{
					element: 'dropdown', label: 'voice', setting: 'voice_id',
					options: [
						{ label: 'Rachel', value: '21m00Tcm4TlvDq8ikWAM' },
						{ label: 'Domi', value: 'AZnzlk1XvdvUeBnXmlld' },
						{ label: 'Bella', value: 'EXAVITQu4vr4xnSDxMaL' },
						{ label: 'Antoni', value: 'ErXwobaYiN019PkySvjV' },
						{ label: 'Elli', value: 'MF3mGyEYCl7XYWbV9V6O' },
						{ label: 'Josh', value: 'TxGEqnHWrfWFTfGW9XjX' },
						{ label: 'Arnold', value: 'VR6AewLTigWG4xSOukaG' },
						{ label: 'Adam', value: 'pNInz6obpgDQGcFmaJgB' },
						{ label: 'Sam', value: 'yoZ06aMxZJJ28mfd3POQ' },
						{ label: 'Indian Male Old', value: 'Hc0pGJSsBwnIj8cO98vI' },
						{ label: 'Morgan Freeman', value: 'a0lBaxvsCg5TrvCHadWc' },
					]
				}
			]
		},
		{
			group: 'vertical', elements: [
				{ element: 'slider', label: 'similarity boost', setting: 'similarity_boost', min: 0, max: 1, step: 0.01 },
				{ element: 'slider', label: 'stability', setting: 'stability', min: 0, max: 1, step: 0.01 }
			],
		},
		// Advanced settings
		{
			group: 'horizontal',
			elements: [
				{ element: 'toggleShowAdvanced', setting: 'showAdvanced' },
			]
		},
		{
			group: 'horizontal',
			elements: [
				{ element: 'checkbox', label: 'Listen', setting: 'listen', advanced: true },
				{ element: 'numberbox', label: 'Stream Optimization', setting: 'optimize_streaming_latency', min: 0, max: 22, step: 1, advanced: true },
				{ element: 'checkbox', label: 'tolerate errors', setting: 'tolerateErrors', advanced: true },
				{ element: 'numberbox', label: 'max retries', setting: 'maxRetries', min: 0, advanced: true, displayCondition: { setting: 'tolerateErrors', value: [true] } },
			]
		},
	],

	contents: "",

	inputs: {
		"text": {
			name: 'text',
			dataType: 'string',
			requireContent: true,
			contents: null,
		}
	},

	outputs: {
		"audio": {

			name: 'audio',
			dataType: 'audioUrl',
			contents: null,
			destinations: {},
			clearOnSend: true
		}
	}
}

class process {

	// Callbacks
	//--------------------------------------------------

	constructor() {

		this.onUpdateCallback =     () => { console.warn('no onUpdate callback')};
		this.onStreamCallback =     () => { console.warn('no onStream callback')};
		this.onCompleteCallback =   () => { console.warn('no onComplete callback')};
		this.onWarningCallback =    () => { console.warn('no onWarning callback')};
		this.onErrorCallback =      () => { console.warn('no onError callback')};
		this.onGiveUpCallback =     () => { console.warn('no onGiveUp callback')};

	}

	// Set callback functions
	onUpdate(callback) { this.onUpdateCallback = callback; }
	onStream(callback) { this.onStreamCallback = callback; }
	onComplete(callback) { this.onCompleteCallback = callback; }
	onWarning(callback) { this.onWarningCallback = callback; }
	onError(callback) { this.onErrorCallback = callback; }
	onGiveUp(callback) { this.onGiveUpCallback = callback; }

	// Fire functions easily
	update(node) { this.onUpdateCallback(node); }
	stream(node) { this.onStreamCallback(node); }
	finish(node) { this.onCompleteCallback(node); }
	warn(message) { this.onWarningCallback(message); }
	error(message) { this.onErrorCallback(message); }
	giveUp(message) { this.onGiveUpCallback(message); }


  	// Run node
  	//--------------------------------------------------

	// Node processing function
	async run (node, extras) {

		const apiToken = extras.apiToken;

		try {

			const textInput = node.inputs['text'];
	
			if (!textInput.contents) {
				this.warn('text input is empty');
				this.giveUp();
				return;
			}
		
			// Listen to audio live
			//---------------------------------------------

			let audioChunks = [];
			let mediaSource = new MediaSource();
			let sourceBuffer;
			let audio = new Audio();
			let queue = [];
			let isPlaying = false;
		
			mediaSource.onsourceopen = function() {
				if(mediaSource.readyState === 'open' && !sourceBuffer) {
					sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
					
					sourceBuffer.onupdateend = function() {
						if (queue.length > 0 && !sourceBuffer.updating) {
							sourceBuffer.appendBuffer(queue.shift().buffer);
						}
		
						if (!isPlaying) {
							audio.play();
							isPlaying = true;
						}
					};
				}
			};
		
			audio.src = URL.createObjectURL(mediaSource);
	
		
			// Run model
			//---------------------------------------------

			const model = 'prime-voice';
			const input = util.convertToString(textInput.contents);
			const settings = {
				model_id: String(node.settings.model_id || 'eleven_monolingual_v1'),
				voice_id: String(node.settings.voice_id),
				similarity_boost: Number(node.settings.similarity_boost || 0.5),
				stability: Number(node.settings.stability || 0.5),
				optimize_streaming_latency: Number(node.settings.optimize_streaming_latency || 0)
			}

			const primeVoice = new EdisonAI();
		
			primeVoice.onUpdate((data) => {

				const chunk = new Uint8Array(Object.values(data.stream));
				audioChunks.push(chunk);
		
				if (node.settings.listen) {

					if (sourceBuffer && (sourceBuffer.updating || queue.length > 0)) {
						queue.push(chunk);
					} else if(sourceBuffer && !sourceBuffer.updating) {
						sourceBuffer.appendBuffer(chunk.buffer);
					}

					this.stream(node);
					return;
				}
		
				return;
			});
		
			primeVoice.onComplete(async (data) => {

				const completeChunks = concatenateArrays(...audioChunks);
				const audioBlob = new Blob([new Uint8Array(completeChunks)], { type: 'audio/mpeg' });
				const audioUrl = URL.createObjectURL(audioBlob);
			
				console.log(audioUrl);
			
				util.setContents(node, audioUrl);
				util.clearInputs(node);
				util.fillOutputs(node);
				this.finish(node);
			
				return;
			});
		
			primeVoice.onError((data) => {
				console.warn('PRIME VOICE ERROR: ', data.error);
				this.error(data.error);
				return;
			});
		
			await primeVoice.run(model, input, settings, apiToken);
		
			return;
		}

		catch (e) {
			this.error(e.message);
			return;
		}
  	}
}

// Functions special to this
//----------------------------------------------------------------------------------------------------

function concatenateArrays(...arrays) {

	let result = [];
	for (let arr of arrays) {
		Array.prototype.push.apply(result, arr);
	}

	return result;
}

// Export
//----------------------------------------------------------------------------------------------------

export const primeVoice = {
	template,
	process,
	icon,
	title,
	description
}