import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  duration: 4000,
  snackbarItems: [],
  refreshStatus: true,
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    
    showSnackbar: (state, action) => {
      const newItem = { ...action.payload, timestamp: Date.now() };
      state.snackbarItems.push(newItem);
      // state.snackbarItems.push(action.payload);
    },

    hideSnackbar: (state) => {
      state.snackbarItems.shift();
    },

    resetSnackbar: (state) => {
      // //console.log('resetting');
      state.snackbarItems = [];
    },

  }

});

export const { showSnackbar, hideSnackbar, resetSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;