// Modules
import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateNodeSetting } from '../../../../../../../redux/slices/nodeMapSlice.js';

// Styles
import './DropDown.css';

export default function DropDown({nodeId, element, value}) {

    // Define important variables

    const setting = element.setting;
    const label = element.label;
    const title = element.title;
    const options = element.options;

    // Define dispatch function
    const dispatch = useDispatch();
    
    // Updating node setting
    //----------------------------------------------------------------------------------------------------

    function handleChangeNodeSetting(e) {

        const newValue = e.currentTarget.value;
        dispatch(updateNodeSetting({nodeId, setting, newValue}));
    }

    // Component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={'node-setting-container'}>

            <label className={'node-setting-label'}>{label || ''}</label>

            <select className={'dropdown'}
            title={title || label || setting || ''}
            value={value || ''}
            onChange={handleChangeNodeSetting}>

                { options.map((option) => {

                    // Sometimes options are just strings
                    if (typeof option === 'string') {
                        option = {
                            label: option,
                            value: option
                        }
                    }

                    return (<option className={'dropdown-option'} value={option.value || ''} key={option.label + '_' + option.value}>{option.label || ''}</option>)
                    }) }

            </select>

        </div>
    );
}