//modules
import { useRef, useEffect } from 'react';
import { processNodeMap } from '../../../nodeMapProcessing/nodeMapProcessing.js'
import useSnackbar from '../../../SnackBar/useSnackBar.js';
import { useSmoothScroll } from '../../../utils/useSmoothScroll.js';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { updateNodeMap, updateNodes, updateNode, updateIoVariableContents, clearChatHistory, updateOutputVariable, updateChatHistory } from '../../../redux/slices/nodeMapSlice.js';

//components
import ChatMessage from './ChatMessage/ChatMessage';

//resources
import config from '../../../config.js';
import sendDark from './sendDark.svg';
import sendLight from './sendLight.svg';

//styles
import './ChatMenu.css';

//auto resizing function
const autoResize = (element) => {
    element.style.height = 'auto'; // Reset the height to auto
    element.style.height = `${element.scrollHeight}px`; // Set the height to the scroll height
};

export default function ChatMenu({submitFunction}) {

    const nodeMap = useSelector((state) => state.nodeMap?.nodeMap) || {};

    const chatMessage = useSelector((state) => state.nodeMap?.nodeMap?.io?.chat?.message) || null;
    const chatHistory = useSelector((state) => state.nodeMap?.nodeMap?.io?.chat?.history) || [];

    const chatMessageInputVariable = useSelector((state) => state.nodeMap?.nodeMap?.io?.inputVariables?.chatMessage) || null;
    const chatHistoryInputVariable = useSelector((state) => state.nodeMap?.nodeMap?.io?.inputVariables?.chatHistory) || null;

    const chatResponseOutputVariable = useSelector((state) => state.nodeMap?.nodeMap?.io?.outputVariables?.chatResponse) || null;
    const chatHistoryOutputVariable = useSelector((state) => state.nodeMap?.nodeMap?.io?.outputVariables?.chatHistory) || null;

    const showChatInput = chatMessageInputVariable?.showToUser;

    const showChatHistory = chatHistoryInputVariable?.showToUser ||
                            chatMessageInputVariable?.showToUser ||
                            chatHistoryOutputVariable?.showToUser ||
                            chatResponseOutputVariable?.showToUser;

    const dispatch = useDispatch();
    const snackbar = useSnackbar();

    // component logic
    //----------------------------------------------------------------------------------------------

    const chatMessageSubmitBox = useRef(null);

    // chat functinoality
    //----------------------------------------------------------------------------------------------

    const role = chatMessageInputVariable?.chatMessageRole;
    const name = chatMessageInputVariable?.chatMessageName;

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight);
        }
    }, [chatHistory]);

    useEffect(() => {
        if (chatMessageSubmitBox.current) {
          autoResize(chatMessageSubmitBox.current);
        }
      }, []);

    function handleKeyDown(e) {

        autoResize(e.currentTarget);

        // If it's a lone enter key
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();

            handleSubmit(e);

            return;
        }
    }

    function updateChatMessage(e) {

        // Otherwise...
        const ioType = 'chatMessage';
        const variableName = 'chatMessage';

        // Construct chatMessage
        const contents = {
            name: name || undefined,
            role: role || 'user',
            content: e.currentTarget.value,
            time: Date.now()
        }

        dispatch(updateIoVariableContents({ioType, variableName, contents}));

        autoResize(e.currentTarget);
    }

    function handleClearHistory(e) {
        dispatch(clearChatHistory());
    }

    // Submitting data to nodeMap for processing

    const userData = useSelector((state) => state.user.user);

    async function handleSubmit(e) {

        if (!userData) {
            snackbar('You need an account to process node maps', 'error');
            return;
        }

        const apiToken = userData.apiToken;
        const apiUrl = config.apiUrl;
        
        // Process nodeMap with current input variables
        processNodeMap(structuredClone(nodeMap), {apiToken, apiUrl, updateThisNodeMap, updateThisNode, updateThisOutputVariable, updateThisChatHistory});
    }

    async function updateThisNodeMap(nodeMap, noPos = true) {

        // Refresh user balance
        var event = new Event('refreshUserBalance');
        window.dispatchEvent(event);

        // Refresh usage history
        event = new Event('refreshUsageHistory');
        window.dispatchEvent(event);

        dispatch(updateNodeMap({nodeMap, noPos}));
    }

    async function updateThisNode(node, noPos = true) {
        dispatch(updateNode({node, noPos}));
    }

    async function updateThisOutputVariable(outputVariable) {
        dispatch(updateOutputVariable({outputVariable}));
    }

    async function updateThisChatHistory(chatHistory) {
        dispatch(updateChatHistory({chatHistory}));
    }
    
    const noChatHistory = (!chatHistory || chatHistory.length === 0)

    // Smooth Scrolling
    //----------------------------------------------------------------------------------------------

    const scrollRef = useSmoothScroll();

    // Component
    //----------------------------------------------------------------------------------------------

    return (
        <div className={'chat-menu'} style={ showChatHistory ? {flexGrow: 1} : null}>

            { showChatHistory ?
                <div className={'chat-menu-label-and-clear-container'}>
                    <div className={'chat-menu-label'}>Chat History</div>

                    <div className={'chat-menu-clear-history-button'} onClick={handleClearHistory}>Clear History</div>
                </div>
            : null }

            { showChatHistory ?  
                <div className={'chat-menu-messages-container'}>
                    <div className={'chat-menu-messages-scroll'} ref={scrollRef}>
                        <div className={'chat-menu-messages'}>

                            { noChatHistory ?
                                <div className={'chat-history-explainer-div'}>
                                    No chat history to display
                                </div>
                            : null}
                            
                            { chatHistory.map((message, i) => {
                                if (!message) { return null; }
                                return <ChatMessage message={message} key={i}/>
                            })}
                        </div>
                    </div>
                </div>
            : null }

            { showChatInput ? 
                <div className={'chat-menu-submit-container'}>
                    <textarea className={'chat-menu-submit-textbox'} ref={chatMessageSubmitBox} value={chatMessage?.content || ''} onKeyDown={handleKeyDown} onChange={updateChatMessage}></textarea>
                    <img className={'chat-menu-submit-button'} src={sendDark} alt={''} title={'Send Message'} onClick={handleSubmit}></img>
                </div>
            : null}
        </div>
    );
}