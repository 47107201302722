// Modules
import config from '../../../../config.js';
import icon from './icon.svg';
import { util } from '../../../../nodeMapProcessing/util.js';
import { EdisonAI } from '../../../../EdisonAI/EdisonAI.js';

const title = 'GPT-3';
const description = '';

// JSON Template
//----------------------------------------------------------------------------------------------------

// Node JSON template
const template = {
      
    category: "language",
    name: "OpenAI GPT-3",
    type: "gpt3",
    position: null,

    settings: {

      showIcon: true,
      infoLink: 'https://platform.openai.com/docs/models/gpt-3',

      canEditInputs: true,
      modelType: 'natural-language-model',
      modelProvider: 'OpenAI',

      model: 'davinci',
      max_tokens: 150,
      temperature: 0.5,
      frequency_penalty: 0.5,
      presence_penalty: 0.5,
      stop: '',
    },

    stats: {
      processing: {
        meanTime: 5000
      }
    },

    guiMaxWidth: 400,
    gui: [
      {
        group: 'horizontal', minWidth: '300px', elements: [
          {element: 'dropdown', label: 'model', setting: 'model', options: [ 'davinci', 'curie', 'babbage', 'ada' ]},
          {element: 'numberbox', label: 'max tokens', setting: 'max_tokens', min: 0, step: 25},
        ]
      },
      {
        group: 'vertical', elements: [
          {element: 'slider', label: 'temperature', setting: 'temperature', min: 0, max: 2, step: 0.01},
          {element: 'slider', label: 'frequency penalty', setting: 'frequency_penalty', min: -2, max: 2, step: 0.01},
          {element: 'slider', label: 'presence penalty', setting: 'presence_penalty', min: -2, max: 2, step: 0.01},
          {element: 'stopSequencesTextbox', label: 'stop sequences', title: "Up to 4 sequences where the API will\nstop generating further tokens.\n\nformat: ['sequence 1', 'sequence 2', 'etc...']",setting: 'stop'}
        ],
      },
      // Advanced settings
      {
        group: 'horizontal',
        elements: [
          { element: 'toggleShowAdvanced', setting: 'showAdvanced' },
        ]
      },
      {
        group: 'horizontal',
        elements: [
          { element: 'checkbox', label: 'stream data', setting: 'stream', advanced: true },
          { element: 'checkbox', label: 'tolerate errors', setting: 'tolerateErrors', advanced: true },
          { element: 'numberbox', label: 'max retries', setting: 'maxRetries', min: 0, advanced: true, displayCondition: {setting: 'tolerateErrors', value: [true]} },
        ]
      },
    ],

    contents: "",

    inputs: {
      "Input 1": {
        
        name: null,
        type: 'text',
        dataType: 'string',
        requireContent: true,
        contents: null,
      }
    },

    outputs: {
      "Output 1": {
        
        name: null,
        type: 'text',
        dataType: 'string',
        contents: null,
        destinations: {},
        clearOnSend: true
      }
    }
  }

// Processing function
//----------------------------------------------------------------------------------------------------

class process {

	// Callbacks
	//--------------------------------------------------

	constructor() {

		this.onUpdateCallback =     () => { console.warn('no onUpdate callback')};
		this.onStreamCallback =     () => { console.warn('no onStream callback')};
		this.onCompleteCallback =   () => { console.warn('no onComplete callback')};
		this.onWarningCallback =    () => { console.warn('no onWarning callback')};
		this.onErrorCallback =      () => { console.warn('no onError callback')};
		this.onGiveUpCallback =     () => { console.warn('no onGiveUp callback')};

	}

	// Set callback functions
	onUpdate(callback) { this.onUpdateCallback = callback; }
	onStream(callback) { this.onStreamCallback = callback; }
	onComplete(callback) { this.onCompleteCallback = callback; }
	onWarning(callback) { this.onWarningCallback = callback; }
	onError(callback) { this.onErrorCallback = callback; }
	onGiveUp(callback) { this.onGiveUpCallback = callback; }

	// Fire functions easily
	update(node) { this.onUpdateCallback(node); }
	stream(node) { this.onStreamCallback(node); }
	finish(node) { this.onCompleteCallback(node); }
	warn(message) { this.onWarningCallback(message); }
	error(message) { this.onErrorCallback(message); }
	giveUp(message) { this.onGiveUpCallback(message); }


  	// Run node
  	//--------------------------------------------------

	// Node processing function
	async run (node, extras) {

		const apiToken = extras.apiToken;

		try {

			// Clear contents first
			node.contents = null;

			// Do nothing if node has required inputs that are empty
			const hasEmptyRequired = util.hasEmptyRequiredInputs(node);

			if (hasEmptyRequired) {
				//console.log('has empty required!');
				//console.log(util.deepCopy(node))
				return false;
			}

			const prompt = util.concatenateInputContents(node);

			// Before request
			//----------------------------------------

			const model = 'gpt-3';
			const input = prompt;
			const settings = {
				model: node.settings.model,
				temperature: node.settings.temperature,
				max_tokens: node.settings.max_tokens,
				presence_penalty: node.settings.presence_penalty,
				frequency_penalty: node.settings.frequency_penalty,
				stop: JSON.parse('[' + node.settings.stop + ']'),
				stream: node.settings.stream
			}

			const gpt3 = new EdisonAI();

			// For streaming responses
			if (settings.stream) {

				let aggregate = '';

				gpt3.onUpdate((data) => {

					console.log('update: ', data);

					if (data.result) {
						aggregate += data.result;
					}

					util.setContents(node, aggregate + ' ❚');
					util.clearInputs(node);
					util.fillOutputs(node, 'streaming');

					this.stream(node);
					return;
				});
			}

			gpt3.onComplete((data) => {

				console.log('complete: ', data);

				util.setContents(node, data.result);
				util.clearInputs(node);
				util.fillOutputs(node);

				this.finish(node);
				return;
			});

			gpt3.onError((data) => {
				console.warn('GPT-3 ERROR: ', data.error);
				this.error(data.error);
				return;
			});

			await gpt3.run(model, input, settings, apiToken);

			return;
		}

		catch (e) {
			this.error(e.message);
			return;
		}
  	}
}

// Export
//----------------------------------------------------------------------------------------------------

export const gpt3 = {
    template,
    process,
    icon,
    title,
    description
}