import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// redux
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../redux/slices/loginSlice.js';
import { resetSnackbar } from "../../redux/slices/snackBarSlice";

// custom hooks
import useSnackbar from "../../SnackBar/useSnackBar.js";
// import useDebounce from '../../utils/useDebounce.js';

import { loadingSVG } from '../../loadingSVG.js';

import config from '../../config.js';
let api = config.apiUrl;

export const Login = () => {

    const [loading, setLoading] = useState(false);

    const [counter, setCounter] = useState(500);

    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const snackbar = useSnackbar();
    // const debounceLogin = useDebounce(handleSubmit, 5000);


    const [mouseCoordinates, setMouseCoordinates] = useState({ x: null, y: null });


    // useEffect(() => {
    //     dispatch(resetSnackbar());
    //   }, [dispatch]);
      

    async function handleSubmit(e) {
        //console.log('handling submit', e);
        //console.log('test client', e.clientX);
        e.preventDefault();

        setLoading(true);

        let payload = {
            email: email,
            password: pass
        };

        let url = api + 'users/login';

        //console.log(url);

        try {

        let request = await fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload), // body data type must match "Content-Type" header
          });

        let response = await request.json();
        setLoading(false);

        if(response.success) {

            const user = { user: response.userData };
            dispatch(login(user));
            
            localStorage.setItem('token', response.accessToken);
            navigate('/sandbox/');

        } else {

            // if(response.message === '')
            // //console.log('client x', e.clientX);
            // snackbar(response.message, 'error', { duration:5000, x:mouseCoordinates.x, y: mouseCoordinates.y } );
            snackbar(response.message, 'error');
            // snackbar('test message', 'success');
            // setCounter(counter + 500);
        }

    } catch(err) {
        setLoading(false);
        snackbar(err.message, 'error');
    }

}


    return (
        <div className="app-root">
            <div className="signIn-container">
            <div className="auth-form-container">
                <h2 className="auth-title">Login</h2>
                <form className="login-form" onSubmit={handleSubmit}>
                {/* <form className="login-form" onSubmit={(e) => { 
                    e.preventDefault();
                    debounceLogin(e);
                    } 
                }> */}
                    <div className="input-container">
                        <label htmlFor="email">email</label>
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            minLength="4"
                            placeholder="youremail@gmail.com"
                            id="email"
                            name="email"
                            requireContent // Add this attribute for validation
                        />
                    </div>
                    <div className="input-container">
                        <label htmlFor="password">password</label>
                        <input
                            value={pass}
                            onChange={(e) => setPass(e.target.value)}
                            type="password"
                            placeholder="********"
                            id="password"
                            name="password"
                            requireContent // Add this attribute for validation
                            minLength="3" // Add this attribute to set a minimum length for the password
                        />
                        <Link to={`/password`}>Forget your password?</Link>
                        <Link to={`/verifyEmail`}>Need a new confirmation email?</Link>
                    </div>
                    <button
                        className="auth-button"
                        type="submit"
                        onClick={(e) => setMouseCoordinates({ x: e.clientX, y: e.clientY })}
                        disabled={!email || !pass} // Add this attribute to disable the button if the email or password fields are empty
                    >
                       {loading ? loadingSVG : 'Login'}
                    </button>
                </form>
                <div className="link-container">
                    <Link to={`/register`}>Don't have an account? Register here</Link>
                </div>
            </div>
            </div>
        </div>
    );

}