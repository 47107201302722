import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { switchTheme } from '../../redux/slices/themeSlice.js';

import UserProfileComponent from './UserProfileComponent/UserProfileComponent';
import PublishToWebButton from './PublishToWebButton/PublishToWebButton';

import './TopBar.css';

import logo from '../../assets/Edison-logo.svg';
// import logo from 'C:/Users/alena/Desktop/fusion-frontend/src/assets/Edison-logo.svg';
// import logo from 'src/assets/Edison-logo.svg';

export default function TopBar() {

    // const [theme, setTheme] = useState('light');
    const currentTheme = useSelector((state) => state.theme.theme);
    const dispatch = useDispatch();

    const toggleTheme = () => {
        // setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));

        // toggle
        let theme = currentTheme === 'light' ? 'dark' : 'light';
        // let themeObj = { theme: theme };
        dispatch(switchTheme({ theme: theme }));
    };

    return (
        <div className={"top-bar"}>

            <div className={"logo-and-name"}>
                <img className={"logo"} src={logo}></img>
                <div className={"name"}>Edison AI Systems</div>
                <div className={'beta-tag'}>BETA</div>
            </div>

            <div className={"user-profile-container"}>
                <UserProfileComponent />
                
                {/*
                <label className="switch">
                    <input type="checkbox" onChange={toggleTheme} />
                    <span className="slider"></span>
                </label>
                */}
                {/* <PublishToWebButton /> */}
            </div>
        </div>
    );
}