import { useDispatch } from 'react-redux';
import { updateIoVariableContents } from '../../../../redux/slices/nodeMapSlice.js'

export default function IoNumberField({variable, ioType}) {

    // Update nodemap variable value
    //----------------------------------------------------------------------------------------------

    const dispatch = useDispatch();

    function handleUpdateValue(e) {
        const contents = e.currentTarget.value;
        const variableName = variable.name;

        dispatch(updateIoVariableContents({ioType, variableName, contents}))
    }

    // Component logic
    //----------------------------------------------------------------------------------------------


    // Component
    //----------------------------------------------------------------------------------------------

    return (
        <>
            { variable.guiElement === 'numberInput' ?
                <>
                    <div className={'io-variable-field-label'}>{variable.name || ''}</div>
                    <input className={'number-input-field'} type={'number'} min={variable.minValue} max={variable.maxValue} step={variable.valueIncrement || 0.01} variablename={variable.name} iotype={ioType} placeholder={variable.name} value={variable.contents || ''} onChange={handleUpdateValue} onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()}></input>
                </>
            : null }

            { variable.guiElement === 'slider' ?
                <>
                    <div className={'io-variable-field-label'}>{variable.name || ''}: {variable.contents}</div>
                    <input className={'number-input-field'} type={'range'} min={variable.minValue} max={variable.maxValue} step={variable.valueIncrement || 0.01} variablename={variable.name} iotype={ioType} placeholder={variable.name} value={variable.contents || ''} onChange={handleUpdateValue}></input>
                </>
            : null }

        </>
    );

}