//modules
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateNodeMapName, updateNodeMapSetting } from '../../../../redux/slices/nodeMapSlice.js';

//tooltip
import ToolTipComponent from '../../../../tooltip/ToolTipComponent.js';

import './NodeMapInfo.css';

export default function NodeMapInfo() {

    const nodeMap = useSelector(state => state.nodeMap.nodeMap);
    const dispatch = useDispatch();

    let nodeMapName = '';

    if(nodeMap) {
        nodeMapName = nodeMap.info.name === null ? nodeMap.id : nodeMap.info.name;
    }

    // Defined Functions
    // ----------------------------------------------------------------------------------------------------

    function handleChange(e) {
        let newName = e.target.value;
        dispatch(updateNodeMapName({newName}));
    }

    function handleUpdateNodeMapSetting(e) {

        const setting = e.currentTarget.getAttribute('setting');
        let value = e.currentTarget.value;

        //console.log('updating nodemap setting!');
        //console.log(setting, value, flowSpeedMax)
        dispatch(updateNodeMapSetting({setting, value}));
    }

    // Component Logic
    // ----------------------------------------------------------------------------------------------------

    let maxRecursion = nodeMap.vars.maxRecursion;
    let maxRecursionDisplay = maxRecursion;
    const maxRecursionMin = 1;
    const maxRecursionMax = 100;
    const maxRecursionStep = 1;

    let flowSpeed = nodeMap.vars.flowSpeed;
    let flowSpeedDisplay = flowSpeed;
    const flowSpeedMin = 0.01;
    const flowSpeedMax = 1;
    const flowSpeedStep = 0.01;

    if (flowSpeed == flowSpeedMax) {
        flowSpeedDisplay = 'instant';
    }

    // Component
    // ----------------------------------------------------------------------------------------------------

    return (
        <div className={"nodemap-info"}>

            <label className={'nodemap-info-label'}>Nodemap name:</label>

            <input className={'nodemap-name-textbox'} type="text" placeholder="Enter a name for your nodemap" value={nodeMapName} onChange={handleChange}></input>

            <label className={'nodemap-info-label'}>Max Recursion: {maxRecursionDisplay}<ToolTipComponent tooltip={'maxRecursion'}/></label>

            <input type="range"
            value={maxRecursion}
            min={maxRecursionMin}
            max={maxRecursionMax}
            step={maxRecursionStep}
            setting={'maxRecursion'}
            onChange={handleUpdateNodeMapSetting}></input>

            <label className={'nodemap-info-label'}>Flow Speed: {flowSpeedDisplay}<ToolTipComponent tooltip={'flowSpeed'}/></label>

            <input type="range"
            value={flowSpeed}
            min={flowSpeedMin}
            max={flowSpeedMax}
            step={flowSpeedStep}
            setting={'flowSpeed'}
            onChange={handleUpdateNodeMapSetting}></input>

        </div>
    );
}