//modules
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { updateNodeOutputName, updateNodeOutputRequired, deleteNodeOutput } from '../../../../../../redux/slices/nodeMapSlice';

//context
import { useContext } from 'react';
import SandboxContext from '../../../../SandboxContext';

//resources
import deleteIconDark from './deleteIconDark.svg';
import deleteIconLight from './deleteIconLight.svg';

export default function NodeOutput({nodeId, outputId, output, editable}) {

    ////console.log(outputId + ': ', output);

    // effects
    //----------------------------------------------------------------------------------------------------


    let dispatch = useDispatch();

    const outputNameRef = useRef(null);

    useEffect(() => {
        const output = outputNameRef.current;
        if (!output) { return; }
        output.style.width = output.value.length + 2 + 'ch';
    });

    // connection dragging functionality
    //----------------------------------------------------------------------------------------------------

    const { dragConnectionState, setDragConnectionState } = useContext(SandboxContext);

    //mousedown on a marker sets the origin
    function handleMarkerMouseDown(e) {

        // Left click = begin dragging new connection
        if (e.button === 0) {

            let newDragConnectionState = {...dragConnectionState};
            newDragConnectionState.draggingConnection = true;
            newDragConnectionState.origin = {nodeId, outputId};
            //console.log(newDragConnectionState);
            setDragConnectionState(newDragConnectionState);
        }
    }

    // component logic
    //----------------------------------------------------------------------------------------------------
    
    const [name, setName] = useState(output.name);
    const [showDeleteButton, setShowDeleteButton] = useState(false);

    const origin_id = make_origin_id(nodeId, outputId);

    // defined functions
    //----------------------------------------------------------------------------------------------------

    function handleUpdateName(e) {
        let newName = e.target.value;
        setName(newName);
        dispatch(updateNodeOutputName({nodeId, outputId, newName}));
    }

    function handleUpdateRequired(e) {
        const requireContent = e.currentTarget.checked;
        dispatch(updateNodeOutputRequired({ nodeId, outputId, requireContent }));
    }

    function handleDelete(e) {
        dispatch(deleteNodeOutput({nodeId, outputId}));
    }

    // example: "Output 1 of Input Node 2"
    function make_origin_id(nodeId, outputId) {
        return outputId + ' of ' + nodeId;
    }

    // component
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={'node-output'} onMouseEnter={() => setShowDeleteButton(true)} onMouseLeave={() => setShowDeleteButton(false)}>

            { editable ? <div className={'delete-node-output-button-container'}>
                { showDeleteButton ? <img className={'delete-node-output-button'} src={deleteIconDark} alt={''} title={'Delete Output'} onClick={handleDelete}></img> : null} 
            </div> : null }

            { output.name !== null ? <input className={'node-output-name'} ref={outputNameRef} value={name} type={'text'} title={'Text or Regular Expression'} onChange={handleUpdateName} disabled={!editable}></input> : null}

            { typeof output.requireContent !== 'undefined' ? <output className={'node-output-requireContent'} type={'checkbox'} title={'Output Required'} defaultChecked={output.requireContent} onChange={handleUpdateRequired} disabled={!editable}></output> : null }

            <div className={'node-output-marker'} title={outputId} origin_id={origin_id} onMouseDown={handleMarkerMouseDown}></div>

        </div>
    );
}