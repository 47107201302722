import { useState, useEffect, useRef } from 'react';

const useReCaptchaV2 = (elementId, sitekey, onExpired, onError) => {

  const [widgetId, setWidgetId] = useState(null);
  const [response, setResponse] = useState(null);
  const hasRendered = useRef(false);
  const scriptRef = useRef(null);
  const isMounted = useRef(true);

  // refs do not persist between mounts

const resetCaptcha = () => {
  if (widgetId !== null) {
    window.grecaptcha.reset(widgetId);
  }
};

  useEffect(() => {


    const loadReCaptcha = () => {

        window.onloadCallback = renderReCaptcha;

        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit`;
        script.async = true;
        script.defer = true;
        // script.onload = () => renderReCaptcha();
        document.body.appendChild(script);
        
        // set script ref
        scriptRef.current = script;
    };

    const renderReCaptcha = () => {
      
      // need this because useEffect will rerender it!
      if (isMounted.current && !hasRendered.current) {

        const widget = window.grecaptcha.render(elementId, {
          sitekey,
          callback: onResponse,
          'expired-callback': onExpired,
          'error-callback': onError,
          'theme': 'dark',
          'size': 'normal'
        });

        setWidgetId(widget);
        hasRendered.current = true;
     }


    };

    const onResponse = (response) => {

      if(isMounted.current) {
        resetCaptcha();
        //console.log('reCAPTCHA response:', response);
        setResponse(response);
      }


      // if (widgetId !== null) {
      //   window.grecaptcha.reset(widgetId);
      //   // window.grecaptcha.dispose(widgetId);
      // }

      // //console.log('reCAPTCHA response:', response);
      // setResponse(response);
    };

    // invoke initial function
    loadReCaptcha();


     // Cleanup function
     return () => {
        
        if (scriptRef.current) {
          document.body.removeChild(scriptRef.current);
          // window.grecaptcha.dispose(widgetId);
          // setWidgetId(null);
          // window.grecaptcha.reset(widgetId);
        }
      };




  }, []);

  return [response, resetCaptcha];
};



export default useReCaptchaV2;