import { useSelector, useDispatch } from 'react-redux';

import './UserProfileComponent.css';

import UserProfileNotLoggedIn from './UserProfileNotLoggedIn.js';
import UserProfileLoggedIn from './UserProfileLoggedIn.js';

export default function UserProfileComponent() {

    const userData = useSelector((state) => state.user.user);

    //console.log('userData: ', userData);

    return (
        userData ? <UserProfileLoggedIn user={userData} /> : <UserProfileNotLoggedIn /> 
    );

};