//modules
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteInputVariable, updateInputVariableRequired, updateInputVariableType, updateInputVariableName, updateInputVariableSetting } from '../../../../../../redux/slices/nodeMapSlice.js';

//resources
import deleteIconLight from './deleteIconLight.svg';
import deleteIconDark from './deleteIconDark.svg';
import settingsIcon from './settingsIcon.svg';

//styles
import './InputVariable.css';

//const variables
const reservedTypes = [
    "chatMessage",
    "chatHistory",
    "chatImage",
    "chatAudio"
  ];

export default function InputVariable(props) {

    const dispatch = useDispatch();

    const inputVariable = props.inputVariable;

    //chatMessage, chatAudio, etc...
    const isReservedType = reservedTypes.includes(inputVariable.type);

    // Show / hide settings
    //----------------------------------------------------------------------------------------------

    const [showAdvanced, setShowAdvanced] = useState(false);

    function handleToggleAdvanced(e) {
        //console.log('Toggling advanced settings menu');
        setShowAdvanced(!showAdvanced);
    }

    // Updating variable settings
    //----------------------------------------------------------------------------------------------

    function handleUpdateVariableSetting(e) {
        const name = inputVariable.name;
        const setting = e.currentTarget.getAttribute('setting');

        let value = e.currentTarget.value;
        
        if (e.currentTarget.getAttribute('type') === 'checkbox') {
            value = e.currentTarget.checked;
        }

        dispatch(updateInputVariableSetting({name, setting, value}));
    }

    // Defined functions
    //----------------------------------------------------------------------------------------------

    function handleDeleteInputVariable(e) {
        //restore: //console.log("Deleting input variable");
        let name = inputVariable.name;
        dispatch(deleteInputVariable({name}));
    }

    function handleUpdateInputVariableRequired(e) {
        //restore: //console.log("Updating input variable requireContent");
        let name = inputVariable.name;
        let requireContent = e.currentTarget.checked;
        dispatch(updateInputVariableRequired({name, requireContent}));
    }

    function handleUpdateInputVariableType(e) {
        //restore: //console.log("Updating input variable type");
        let name = inputVariable.name;
        let newType = e.currentTarget.value;
        dispatch(updateInputVariableType({name, newType}));
    }

    function handleUpdateInputVariableName(e) {
        //restore: //console.log("Updating input variable name");
        let name = inputVariable.name;
        let newName = e.currentTarget.value;
        dispatch(updateInputVariableName({name, newName}));
    }

    function hasSetting(name) {
        return (name in inputVariable);
    }

    // component structure
    //----------------------------------------------------------------------------------------------

    return (
        <div className={'input-variable'}>

            <div className={'input-variable-settings'}>

                <div className={'input-variable-basic-settings'}>

                    <img className={'input-variable-show-settings-icon'} src={settingsIcon} alt={''} title={'Show options panel'} onClick={handleToggleAdvanced}></img>

                    <select className={'input-variable-type-dropdown'} title="variable type" value={inputVariable.type || ''} onChange={handleUpdateInputVariableType}>
                        <option className={'input-variable-type-dropdown-option'} value={''}>select</option>
                        <option className={'input-variable-type-dropdown-option'} value="text">Text</option>
                        <option className={'input-variable-type-dropdown-option'} value="number">Number</option>
                        <option className={'input-variable-type-dropdown-option'} value="image">Image</option>
                        <option className={'input-variable-type-dropdown-option'} value="audio" >Audio</option>
                        <option className={'input-variable-type-dropdown-option'} value="chatMessage">Chat Message</option>
                        <option className={'input-variable-type-dropdown-option'} value="chatHistory">Chat History</option>
                    </select>

                    <input className={'input-variable-name'} type="text" title="variable name" placeholder={'Variable Name'} value={inputVariable.name || ''} disabled={isReservedType} onChange={handleUpdateInputVariableName}></input>

                    <img className={'input-variable-delete-icon'} src={deleteIconDark} alt={''} title="delete" onClick={handleDeleteInputVariable}></img>
                </div>
                
                { showAdvanced ? 
                    <div className={'input-variable-advanced-settings'}>

                        <div className={'require-show-input-variable'}>

                            {hasSetting('showToUser') ? 
                                <div className={'input-variable-setting'}>
                                    <input className={'input-variable-setting-checkbox'} type="checkbox" title="Show to user in IO panel" checked={inputVariable.showToUser} setting={'showToUser'} onChange={handleUpdateVariableSetting}></input>
                                    <label className={'input-variable-setting-label'}>Show to user</label>
                                </div>
                            : null }

                            {hasSetting('requireContent') ? 
                                <div className={'input-variable-setting'}>
                                    <input className={'input-variable-setting-checkbox'} type="checkbox" title="Require that variable has contents before proccessing" checked={inputVariable.requireContent} setting={'requireContent'} onChange={handleUpdateVariableSetting}></input>
                                    <label className={'input-variable-setting-label'}>require content</label>
                                </div>
                            : null }

                        </div>

                        <div className={'message-name-and-role'}>

                            {hasSetting('chatMessageRole') ? 
                                <div className={'input-variable-setting-vertical'}>
                                    <label className={'input-variable-setting-label'}>Role</label>
                                    <select className={'input-variable-setting-dropdown'} value={inputVariable.chatMessageRole || ''} setting={'chatMessageRole'} onChange={handleUpdateVariableSetting}>
                                        <option className={'input-variable-setting-dropdown-option'} value={""}></option>
                                        <option className={'input-variable-setting-dropdown-option'} value="user">User</option>
                                        <option className={'input-variable-setting-dropdown-option'} value="assistant">Assistant</option>
                                        <option className={'input-variable-setting-dropdown-option'} value="system">System</option>
                                    </select>
                                </div>
                            : null }

                            {hasSetting('chatMessageName') ? 
                                <div className={'input-variable-setting-vertical'}>
                                    <label className={'input-variable-setting-label'}>Name</label>
                                    <input className={'input-variable-setting-text-input'} type="text" value={inputVariable.chatMessageName || ''} setting={'chatMessageName'} onChange={handleUpdateVariableSetting}></input>
                                </div>
                            : null }

                        </div>

                        {hasSetting('showToUser') && inputVariable.showToUser && hasSetting('guiElement') ? 
                            <div className={'input-variable-display-type'}>
                                <div className={'input-variable-setting'}>
                                    <select className={'input-variable-setting-dropdown'} title="Show as textbox / slider" value={inputVariable.guiElement || ''} setting={'guiElement'} onChange={handleUpdateVariableSetting}>
                                        <option className={'input-variable-setting-dropdown-option'} value="numberInput">Number Input</option>
                                        <option className={'input-variable-setting-dropdown-option'} value="slider">Slider</option>
                                    </select>
                                    <label className={'input-variable-setting-label'}>GUI input type</label>
                                </div>
                            </div>
                        : null }

                        {hasSetting('minLength') || hasSetting('maxLength') ? 
                            <div className={'input-variable-min-max-words'}>
                                <div className={'input-variable-setting'}>
                                    <input className={'input-variable-setting-input'} type="number" min="0" title="Minimum allowed word count for input variable" value={inputVariable.minLength || ''} setting={'minLength'} onChange={handleUpdateVariableSetting} onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()}></input>
                                    <label className={'input-variable-setting-label'}>{'Min length'}</label>
                                </div>
                                <div className={'input-variable-setting'}>
                                    <input className={'input-variable-setting-input'} type="number" min="0" title="Maximum allowed word count for input variable" value={inputVariable.maxLength || ''} setting={'maxLength'}  onChange={handleUpdateVariableSetting} onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()}></input>
                                    <label className={'input-variable-setting-label'}>{'Max length'}</label>
                                </div>
                            </div>
                        : null }

                        {hasSetting('minValue') || hasSetting('maxValue') ? 
                            <div className={'input-variable-min-max-value'}>
                                <div className={'input-variable-setting'}>
                                    <input className={'input-variable-setting-input'} type="number" title="Minmum allowed value for input variable" value={inputVariable.minValue || ''} setting={'minValue'} onChange={handleUpdateVariableSetting} onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()}></input>
                                    <label className={'input-variable-setting-label'}>Min value</label>
                                </div>

                                <div className={'input-variable-setting'}>
                                    <input className={'input-variable-setting-input'} type="number" title="Minmum allowed value for input variable" value={inputVariable.maxValue || ''} setting={'maxValue'} onChange={handleUpdateVariableSetting} onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()}></input>
                                    <label className={'input-variable-setting-label'}>Max value</label>
                                </div>
                            </div>
                        : null }

                        {hasSetting('minValue') || hasSetting('maxValue') ? 
                            <div className={'input-variable-setting'}>
                                <input className={'input-variable-setting-input'} type="number" title="Step increment" value={inputVariable.valueIncrement || ''} setting={'valueIncrement'} onChange={handleUpdateVariableSetting} onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()}></input>
                                <label className={'input-variable-setting-label'}>Round to nearest</label>
                            </div>
                        : null }

                    </div>
                : null}

            </div>

        </div>
    );
}