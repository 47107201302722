const loggingMiddleware = (store) => (next) => (action) => {

    // //console.log(`dispatching ${action.type}`, action);

    const result = next(action);

    // //console.log(`next state`, store.getState());
    return result;
  };

  export default loggingMiddleware;