// Styles
import './LogElement.css';

export default function LogElement({log}) {

    return (

        <div className={'log-element'}>

            { log.dataType === 'string' ? 
                <div className={'log-string'}>{log.data}</div>
            : null}
            
        </div>

    );
}
