// get current api url
import config from '../config.js';
let api = config.apiUrl;


export const fetchWrapper = async (method, url, payload = null) => {

    try {
    
        // set endpoint
        url = api + url;

        // get JWT token
        let accessToken = localStorage.token;
        //restore: //console.log('access token', accessToken);
        
        // initialize headers object
        const headers = { 'Content-Type': 'application/json' };
        
        // set credentials
        const credentials = 'include';
        
        // add access token if it exists
        if(accessToken) {
            headers.Authorization = `Bearer ${accessToken}`;
        }

        //restore: //console.log(headers);

        // build options object
        const options = {
            method,
            credentials,
            headers,
        };

        // if there's a payload and it's not a get request add it to the options object
        if(payload && method !== 'GET') {
            options.body = JSON.stringify(payload);
        }

        // make fetch request
        let request = await fetch(url, options);
        //restore: //console.log(request);

        // resolve response object
        let response = await request.json();
        //restore: //console.log(response);

       
        return response;
        


    } catch(err) {
        // //console.log('in fetchWrapper', err.message);
    }

};