// Modules
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateNodeSetting } from '../../../../../../../redux/slices/nodeMapSlice.js';

// Components
import LogElement from './LogElement/LogElement.js';

// Styles
import './LogViewer.css';

export default function LogViewer() {

    const logs = [
        {type: 'error', data: 'thiIn this example, JsonView is used to display each log message as a collapsible JSON object. The shouldInitiallyExpand prop is a function that returns true, so all JSON nodes will be initially expanded. If you want to change this behavior, you can modify this function. s is a string', dataType: 'string'}
    ]

    return (

        <div className={'log-viewer'}>
            <div className={'log-viewer-content'}>

                {logs.map((log, i) => 
                    <LogElement log={log} key={i}/>
                )}

            </div>
        </div>

    );
}
