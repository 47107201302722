import { useSelector, useDispatch } from 'react-redux';
import { updateNodeSetting } from '../../../../../../redux/slices/nodeMapSlice.js';

export default function OutputVariableSelect({nodeId, setting, value}) {

    const dispatch = useDispatch();

    let outputVariables = useSelector((state) => state.nodeMap.nodeMap?.io?.outputVariables);

    if (!outputVariables) ( outputVariables = {} );

    // component logic
    //----------------------------------------------------------------------------------------------------

    if (!nodeId || !setting) {
        return null;
    }

    // defined functions
    //----------------------------------------------------------------------------------------------------

    function handleChangeNodeSetting(e) {
        const newValue = e.currentTarget.value;
        //console.log(`Changing '${nodeId}' setting '${setting}' to '${newValue}'`);
        dispatch(updateNodeSetting({nodeId, setting, newValue}));
    }

    // component
    //----------------------------------------------------------------------------------------------------

    return (
        <select className={'output-variable-select'} title='Select output variable' value={value || ''} onChange={handleChangeNodeSetting}>
            <option className={'output-variable-select-option'} value={''}>select</option>
            { Object.keys(outputVariables).map((key) => { return <option className={'output-variable-select-option'} value={key} key={key}>{key}</option> })}
        </select>
    );
}