// Utility functions
//----------------------------------------------------------------------------------------------------

// Check if any input is streaming
function isAnyInputStreaming(inputs) {

    for (let inputId in inputs) {
        const input = inputs[inputId];

        if (input.state === 'streaming') {
            return true;
        }
    }

    return false;
}

// Check if a node has any requireContent inputs that are empty
function hasEmptyRequiredInputs(node) {

    for (const inputId in node.inputs) {
        if (node.inputs.hasOwnProperty(inputId)) {
            
            const input = node.inputs[inputId];

            // General (if nonexistant)
            if (!input.contents && input.requireContent === true) {
                return true;
            }

            // For strings (if nonexistant or empty string)
            if (isString(input.contents) && input.contents === '' && input.requireContent === true) {
                return true;
            }
        }
    }

    return false;
}

// Check if a node has any requireContent inputs that are empty
function hasStreamingRequiredInputs(node) {

    for (const inputId in node.inputs) {
        if (node.inputs.hasOwnProperty(inputId)) {
            
            const input = node.inputs[inputId];

            // General (if nonexistant)
            if (input.requireContent === true && input.state === 'streaming') {
                return true;
            }
        }
    }

    return false;
}

// Concatenate the contents of each input and return the resulting string
function concatenateInputContents(node, mode = 'string') {

    let result = '';

    // String mode: look for string in each input
    if (mode === 'string') {

        for (const inputId in node.inputs) {
            const input = node.inputs[inputId];
            
            if (input.contents) {

                // If string, use string
                if (typeof input.contents === 'string') {
                    result += input.contents;
                    continue;
                }

                // If chatMessage, use content
                if (input.contents.content) {
                    result += input.contents.content;
                    continue;
                }

                // If chatMessage array, loop through messages for content
                if (Array.isArray(input.contents)) {
                    for (let entry of input.contents) {
                        if (entry.content) {
                            result += entry.content;
                        }
                    }
                    continue;
                }
            }
        }
    }

    return result;
}

// Check datatype for all inputs
async function checkDataTypes(inputs) {

    let success = true;

    for (let inputId in inputs) {
        const input = inputs[inputId];
        const match = await checkDataType(input.contents, input.dataType);

        if (!match) {
            success = false;
            input.state = 'error';
            input.message = `incorrect data type (requires ${input.dataType})`;
        }
    }

    return success;
}

// Check datatype of particular input
async function checkDataType (variable, dataType) {
    
    // null contents or datatype means true
    if (!variable) { return true; }
    if (!dataType) { return true; }

    // Check types
    if (dataType === 'string') { return isString(variable); }
    if (dataType === 'number') { return isNumber(variable); }
    if (dataType === 'object') { return isObject(variable); }
    if (dataType === 'array') { return isArray(variable); }
    if (dataType === 'chatMessage') { return await isChatMessage(variable); }
    if (dataType === 'imageUrl') { return await isImageUrl(variable); }
    if (dataType === 'audioUrl') { return await isAudioUrl(variable); }

    // Return true by default
    return true;
}

    function isNull(variable) { return ( variable === null ); }

    function isUndefined(variable) { return ( typeof variable === 'undefined' ); }

    function isString(variable) { return ( typeof variable === 'string' ); }

    function isNumber(variable) { return ( typeof variable === 'number' ); }

    function isArray(variable) { return ( Array.isArray(variable) ); }

    function isObject(variable) { return ( typeof variable === 'object' && variable !== null); }

    function isChatMessage(variable) {

        // Must exist or be object
        if (!variable) { return false; }
        if (!isObject(variable)) { return false; }

        // Must have role and contents
        if (!variable.content) { return false; }
        if (!variable.role) { return false; }

        // Role and contents must be strings        
        if (!isString(variable.content)) { return false; }
        if (!isString(variable.content)) { return false; }

        return true;
    }

    function isUrl(variable) {

        if (!variable) {
            return false;
        }
        
        try {
            new URL(variable);
            return true;
        } catch (_) {
            return false;  
        }
    }

    function isFile(variable) {

        if (!variable) {
            return false;
        }

        return variable instanceof File;
    }

    async function isImage(variable) {

        if (!variable) {
            return false;
        }

        if (isUrl(variable)) {
            try {
                const response = await fetch(variable, { method: 'HEAD' });
                const contentType = response.headers.get('Content-Type') || '';
                return contentType.startsWith('image/');
            } catch (e) {
                console.error(`Error while fetching file: ${e}`);
                return false;
            }
        } else if (isFile(variable)) {
            return variable.type.startsWith('image/');
        } else {
            return false;
        }
    }
    
    async function isAudio(variable) {

        if (!variable) {
            return false;
        }

        if (isUrl(variable)) {
            try {
                const response = await fetch(variable, { method: 'HEAD' });
                const contentType = response.headers.get('Content-Type') || '';
                return contentType.startsWith('audio/');
            } catch (e) {
                console.error(`Error while fetching file: ${e}`);
                return false;
            }
        } else if (isFile(variable)) {
            return variable.type.startsWith('audio/');
        } else {
            return false;
        }
    }

    async function isAudioBufferObj(variable) {

        if (!variable) {
            return false;
        }

        if (variable.audioBuffer) {
            return true;
        }
    }

    async function isImageUrl(variable) {

        if (!variable) {
            return false;
        }

        if (isUrl(variable)) {
            try {
                const response = await fetch(variable);
                const contentType = response.headers.get('Content-Type') || '';
                return contentType.startsWith('image/');
            } catch (e) {
                console.error(`Error while fetching file: ${e}`);
                return false;
            }
        }
    
        return false;
    }
    
    async function isAudioUrl(variable) {
        if (isUrl(variable)) {
          try {
            const response = await fetch(variable);
            const contentType = response.headers.get('Content-Type') || '';
            return contentType.startsWith('audio/');
          } catch (e) {
            console.error(`Error while fetching file: ${e}`);
            return false;
          }
        }
      
        return false;
      }
    
// Convert input contents to correct type if neccecary
async function convertDataTypes(inputs) {

    let success = true;

    for (let inputId in inputs) {
        const input = inputs[inputId];
        await convertDataType(input);

        if (input.state === 'error') {
            success = false;
        }
    }

    return success;
}

    // Convert one datatype to another (example: string to chatMessage)
    async function convertDataType(input) {

        const dataType = input.dataType;
        const contents = input.contents;

        // Attempt to convert if dataType is incorrect
        if (!checkDataType(input)) {

            try {
                if (dataType === 'string') { input.contents = convertToString(contents); }
                if (dataType === 'number') { input.contents = convertToNumber(contents); }
                if (dataType === 'chatMessage') { input.contents = convertToChatMessage(contents); }
                if (dataType === 'imageUrl') { input.contents = await convertToImageUrl(contents); }
                if (dataType === 'audioUrl') { input.contents = await convertToAudioUrl(contents); }
            }
            catch (e) {
                input.state = 'error';
                input.message = e.message;
            }
        }
    }

        function convertToString(variable) {

            if (isNull(variable)) { return null; }
            if (isUndefined(variable)) { return undefined; }
            if (isString(variable)) { return variable; }
            if (isNumber(variable)) { return Number.toString(variable); }
            if (isChatMessage(variable)) { return convertToString(variable.content); }

            throw new Error(`Could not convert ${typeof variable} to string`);
        }

        function convertToNumber(variable) {

            if (isNull(variable)) { return null; }
            if (isUndefined(variable)) { return undefined; }
            if (isString(variable)) { return Number(variable); }
            if (isNumber(variable)) { return variable; }
            if (isChatMessage(variable)) { return Number(variable.content); }

            throw new Error(`Could not convert ${typeof variable} to number`);
        }

        function convertToChatMessage(variable) {

            if (isString(variable)) {
                const message = {
                    time: Date.now(),
                    role: 'user',
                    name: null,
                    content: variable
                }

                return message;
            }

            if (isNumber(variable)) {
                const message = {
                    time: Date.now(),
                    role: 'user',
                    name: null,
                    content: Number.toString(variable)
                }

                return message;
            }
            
            if (isChatMessage(variable)) {
                return variable;
            }

            throw new Error(`Could not convert ${typeof variable} to chat message`);
        }

        async function convertToImageUrl (input) {

            // If the input is a URL string
            if (isUrl(input)) {
                if (await isImageUrl(input)) {
                    return input; // return it as is
                } else {
                    throw new Error(`Invalid image URL: ${input}`);
                }
            }
            // If the input is a File object
            else if (isFile(input)) {
                if (await isImage(input)) {
                    // Convert the File to a Blob URL
                    return URL.createObjectURL(input);
                } else {
                    throw new Error(`Invalid File object: ${input}`);
                }
            }
            // If the input is neither a URL string nor a File object
            else {
                throw new Error(`Invalid input: ${input}`);
            }
        }

        async function convertToAudioUrl (input) {

            if (isAudioBufferObj(input)) {
                return input;
            }

            // If the input is a URL string
            if (isUrl(input)) {
                if (await isAudioUrl(input)) {
                    return input; // return it as is
                } else {
                    throw new Error(`Invalid audio URL: ${input}`);
                }
            }
            // If the input is a File object
            else if (isFile(input)) {
                if (await isAudio(input)) {
                    // Convert the File to a Blob URL
                    return URL.createObjectURL(input);
                } else {
                    throw new Error(`Invalid File object: ${input}`);
                }
            }
            // If the input is neither a URL string nor a File object
            else {
                throw new Error(`Invalid input: ${input}`);
            }
        }

// Clear all inputs
function clearInputs(node) {

    for (const inputId in node.inputs) {
        if (node.inputs.hasOwnProperty(inputId)) {
            const input = node.inputs[inputId];

            if (input.state !== 'streaming') {
                input.contents = null;
            }
        }
    }
}

// Set node contents
function setContents(node, contents) {
    node.contents = contents;
    return;
}

// Returns list of nodes that are connected to the one in question
function getOutputTargets(output) {

    const targets = [];

    const destinations = output.destinations;
    for (const destinationId in destinations) {
        if (destinations.hasOwnProperty(destinationId)) {
            const target = destinations[destinationId].target;
            targets.push(target);
        }
    }

    return targets;
}

// Fills node outputs with node contents
function fillOutputs(node, state = 'filled') {

    const outputs = node.outputs;

    // Loop through outputs
    for (let outputId in outputs) {

        // Set contents and state
        const output = outputs[outputId];
        output.contents = node.contents;
        output.state = state;

        // Set stream Id of output if necessary
        if (state === 'streaming' && !output.streamId) {
            output.streamId = randomString();
        }
    }
}

// Generates random string for streamId
function randomString() {

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let randomString = '';
    
    for (let i = 0; i < 12; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters.charAt(randomIndex);
    }
    
    return randomString;
}

function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
}


const util = {

    isAnyInputStreaming,
    hasEmptyRequiredInputs,
    hasStreamingRequiredInputs,

    checkDataTypes,
    checkDataType,
    convertDataTypes,
    convertDataType,
    clearInputs,
    setContents,
    fillOutputs,
    getOutputTargets,
    randomString,
    deepCopy,

    isNull,
    isUndefined,
    isString,
    isNumber,
    isChatMessage,
    isImage,
    isAudio,
    isImageUrl,
    isAudioUrl,

    convertToString,
    convertToNumber,
    convertToChatMessage,
    convertToImageUrl,
    convertToAudioUrl,

    concatenateInputContents,
}

export { util };