// React modules
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

// Redux modules
import { switchTheme } from './redux/slices/themeSlice.js';
import { resetSnackbar } from "./redux/slices/snackBarSlice";
import { resetRequests } from "./redux/slices/requestSlice";
import { logout } from './redux/slices/loginSlice.js';

// HOC
import withContextMenu from './withContextMenu.js';
import useFetchManager from './utils/useFetchManager';
import FusionEditor from './FusionEditor/FusionEditor.js';

// Styles
import './darkModeColors.css';

function App() {

  // instantiate hook for managing all requests
  useFetchManager();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // redux selectors
  const logoutData = useSelector((state) => state.request.logoutData);
  const currentTheme = useSelector((state) => state.theme.theme);
  const userData = useSelector((state) => state.user.user);

  useEffect(() => {

    //console.log('handling logout in app.js');
    //console.log('logout data', logoutData);

    if(!logoutData) {
      return;
    }

    if(logoutData.success === true) {
    
    // change user state to loggedOut
    dispatch(logout());

    // erase all of the users request data from the session
    dispatch(resetRequests());
      
    // remove access token from local storage
    localStorage.removeItem('token');
    
    // send user back to login page
    navigate('/login');
    }

  }, [logoutData]);

  // Send user to /login if not logged in
  useEffect(() => {
    //console.log(userData);
    //if (!userData) { navigate('/login'); }
  }, [userData]);

  return (
    <>
    <div className={`app-root`} id={currentTheme}>
      <FusionEditor/>
    </div>
    </>
  );
  }

  export default withContextMenu(App);