//modules
import { useDispatch } from 'react-redux';
import { addNodeInput } from '../../../../../redux/slices/nodeMapSlice';

//components
import NodeInput from './NodeInput/NodeInput';

//resources
import plusIconDark from './plusIconDark.svg';
import plusIconLight from './plusIconLight.svg';

export default function NodeInputs({nodeId, inputs, editable}) {

    // effects
    //----------------------------------------------------------------------------------------------------

    const dispatch = useDispatch();

    // no inputs means do not render
    if (!inputs) { return null; }
    
    
    // defined functions
    //----------------------------------------------------------------------------------------------------

    function handleAddNodeInput(e) {
        //console.log(`Adding input to ${nodeId}`);
        dispatch(addNodeInput({nodeId}));
    }


    // component
    //----------------------------------------------------------------------------------------------------

    // DO NOT USE INDEX FOR KEY!!!
    return (
        <div className={'node-inputs'}>
            { Object.keys(inputs).map((inputId, index) => { return <NodeInput key={index} nodeId={nodeId} inputId={inputId} input={inputs[inputId]} editable={editable}/> })}
            <div className={'add-node-input-button-container'}>
                {editable ? <img className={'add-node-input-button'} src={plusIconDark} alt={''} title={'Add Input'} onClick={handleAddNodeInput}></img> : null}
            </div>
        </div>
    );
}