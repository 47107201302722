import { useState, useEffect, useCallback } from 'react';

// need this to logout user
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { logout, reauth } from '../redux/slices/loginSlice.js';

// import authObj from './authObj.js';

// get current api url
import config from '../config.js';
let api = config.apiUrl;


export const useFetchWrapper = () => {

    // delete this
    // const reAuth = useSelector((state) => state.user.reAuthenticating);
    // //console.log('auth in wrapper', reAuthenticating);
    const user = useSelector((state) => state.user.user);

    // const [authenticating, setAuthenticating] = useState(false);
    const [loading, setLoading] = useState(false);
    

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const fetchData = async (method, url, payload = null) => {
        // //console.log('fetch data render', 'loading:', loading);
        setLoading(true);
        // //console.log('loading', loading);
        //console.log('url', url);

        try {
        
            // set endpoint
            url = api + url;

            // get JWT token
            let accessToken = localStorage.token;
            //restore: //console.log('access token', accessToken);
            
            // initialize headers object
            const headers = { 'Content-Type': 'application/json' };
            
            // set credentials
            const credentials = 'include';
            
            // add access token if it exists
            if(accessToken) {
                headers.Authorization = `Bearer ${accessToken}`;
            }

            //restore: //console.log(headers);

            // build options object
            const options = {
                method,
                credentials,
                headers,
            };

            // //console.log('user in wrapper fetch', user);
            let userName = user ? user.userName : null;
            // //console.log('userName', userName);
             

            // if there's a payload and it's not a get request add it to the options object
            if(payload && method !== 'GET') {

                // passed payload should never include username 
                payload.userName = userName;
                
                // add payload to body
                options.body = JSON.stringify(payload);
                // //console.log('options if payload', options);
            }

            
                // making POST and no user or payload

                // if no payload then create one
                if(!payload && method !== 'GET') {

                    payload = { userName: userName };
                    options.body = JSON.stringify(payload);
                    // //console.log('options if no payload', options);
                }

            

            //console.log('options after checks', options);

            // make fetch request
            let request = await fetch(url, options);
            //console.log('request', request);

            // resolve response object
            let response = await request.json();
            //console.log('response object', response);

            // if access token is expired then send request for a new one
            if(response.message === 'access token not valid') {

                //check state before dispatching
                // debugger;
                // let status = checkState();
                // //console.log('status', status);

                  // dispatch(reauth({awaitingToken: true}));
                    // //console.log('right after dispatch', reAuth);

                // //console.log('obj', authObj.isAuth);

                // if(authObj.isAuth === false) {
                //     authObj.isAuth = true;
                //     //console.log('right after switch', authObj);
    
                //     } else {
                //         //console.log('in else');

                //         while(authObj.isAuth) {
                //             //console.log('in while loop');

                //         }

                //         // return;
                //     //console.log('before new refetch', authObj.isAuth);
                //     let data = await reFetch(url, options);
                //     setLoading(false);
                //     return data;
                //     }



                // if(status === false) {
                // dispatch(reauth({awaitingToken: true}));
                // //console.log('right after dispatch', );

                // } else {
                //     //console.log('in else');
                //     return;
                // }



                // go to another function and check reauth state
                // let status = await checkState();
                // //console.log('status', status);
               
                
                let auth = await reAuthenticate();

                // if reauthentication passes refetch data from original endpoint
                if(auth) {
                    let data = await reFetch(url, options);
                    setLoading(false);
                    return data;
                }

            } else {

                // return original request data
                setLoading(false);
                return response;
            }


        } catch(err) {
            //console.log('in fetchWrapper', err.message);
        }

};



    // function recur() {

    //     if(authObj.isAuth === false) {
    //         authObj.isAuth = true;
    //         //console.log('right after switch', authObj);

    //         } else {
    //             //console.log('in else');
    //             //console.log('before new refetch', authObj.isAuth);
    //             // let data = await reFetch(url, options);
    //             // setLoading(false);
    //             // return data;

    //         }
    // }




    async function reAuthenticate() {
        //console.log('in reAuthenticate', );

        // change state so other instances know reauthetication process is taking place
        // dispatch(reauth({awaitingToken: true}));
        //console.log('after dispatch', );

        // make request to reauthenticate user using refresh token
        let request = await fetch(api + 'users/reauthenticate', {
            method: "POST",
            credentials: "include",
            headers: {
            "Content-Type": "application/json",
            },
        });

        let response = await request.json();

        // if refresh token is invalid logout user
        if(response.success === true) {
            localStorage.setItem('token', response.accessToken);
            //console.log('dispatching false...');
            // authObj.isAuth = false;
            dispatch(reauth({awaitingToken: false}));
            return true;

        } else {
             // change user state to loggedOut
            dispatch(logout());
    
            // remove access token from local storage
            localStorage.removeItem('token');
        
            // send user back to login page
            navigate('/login');

            // dispatch(reauth({awaitingToken: false}));
            // authObj.isAuth = false;
            return;
        }

    };



    async function reFetch(url, options) {

        // get new access token
        let accessToken = localStorage.token;
        //console.log('new access token in refetch', accessToken);

        // attach it to header
        options.headers.Authorization = `Bearer ${accessToken}`;
        //console.log('options in refetch', options);

        // make request and return response
        let request = await fetch(url, options);
        let response = await request.json();
        return response;

    };


    return [loading, fetchData];

};