// Modules
import { createSlice } from '@reduxjs/toolkit';

// Node templates
import { nodes } from '../../nodes/nodes.js';

const initialState = {
  upToDate: true,
  nodeMap: {
    id: "",
    info: {
        public: true,
        author: null,
        name: null,
        dateCreated: null,
        dateModified: null
    },
    nodes: {},
    io: {
        inputVariables: {
            chatMessage: {
                name: "chatMessage",
                type: "chatMessage",
                dataType: "chatMessage",
                requireContent: true,
                showToUser: true,
                minLength: null,
                maxLength: null,
                chatMessageRole: 'user',
                chatMessageName: 'User',
                contents: ""
            }
        },
        outputVariables: {
            chatResponse: {
                name: "chatResponse",
                type: "chatResponse",
                dataType: "chatMessage",
                requireContent: true,
                showToUser: true,
                minLength: null,
                maxLength: null,
                chatMessageRole: 'assistant',
                chatMessageName: 'Assistant',
                contents: ""
            }
        }
    },
    vars: {
      viewportZoom: 1.00,
      viewportPosition: {x: 0, y: 0},
      maxRecursion: 5,
      flowSpeed: 0.75
    }
  },

};

const reservedTypes = [
  "chatMessage",
  "chatResponse",
  "chatHistory",
  "chatImage",
  "chatAudio"
];

const inputVariableTemplate = {
  name: null,
  type: 'text',
  dataType: 'string',
  requireContent: true,
  showToUser: true,
  minLength: null,
  maxLength: null,
  contents: null
};

const outputVariableTemplate = {
  name: null,
  type: 'text',
  dataType: 'string',
  requireContent: true,
  showToUser: true,
  minLength: null,
  maxLength: null,
  contents: null
};

const nodeMapSlice = createSlice({
  name: 'nodeMap',
  initialState,
  reducers: {

    // reset
    //----------------------------------------------------------------------------------------------------

    // basic info
    //----------------------------------------------------------------------------------------------------

    setUpToDate: (state, action) => {

      //restore: //console.log("Setting nodeMap up to date");
      state.upToDate = action.payload.upToDate;
    },

    updateNodeMap: (state, action) => {

      //console.log("Updating nodeMap");
      //console.log()

      // If we don't want to update position
      if (action.payload.noPos) {
        action.payload.nodeMap.vars.viewportPosition = deepCopy(state.nodeMap.vars.viewportPosition);
        action.payload.nodeMap.vars.viewportZoom = deepCopy(state.nodeMap.vars.viewportZoom);
      }
      
      state.nodeMap = pruneMissingConnections(deepCopy(action.payload.nodeMap));
    },

    updateNodeMapName: (state, action) => {

      //restore: //console.log("Updating nodeMap name");
      state.nodeMap.info.name = action.payload.newName;

      state.upToDate = false;
    },

    updateNodeMapSetting: (state, action) => {

      const setting = action.payload.setting;
      const value = action.payload.value;

      if (isNullOrUndefined([setting, value])) {
        //console.log('Certain payload variables missing!');
      }

      //console.log(`Updating nodeMap setting '${setting}' to `, value);

      state.nodeMap.vars[setting] = value;
      state.upToDate = false;
    },

    updateViewportPosition: (state, action) => {
      
      const position = action.payload.newPosition;

      if (!position) {
        return state;
      }

      state.nodeMap.vars.viewportPosition = position;
      // DON'T set upToDate = false - we don't want dragging to trigger save button!
    },

    updateViewportZoom: (state, action) => {
      
      const zoom = action.payload.newZoom;
      const viewportPosition = action.payload.newViewportPosition;

      if (!zoom) {
        //console.log('Certain payload variables missing!');
      }

      state.nodeMap.vars.viewportZoom = zoom;

      if (viewportPosition) {
        state.nodeMap.vars.viewportPosition = viewportPosition;
      }
    },

    updateNodes: (state, action) => {

      const nodes = action.payload.nodes;
      const noPos = action.payload.noPos;

      for (let nodeId in nodes) {

        const newNode = nodes[nodeId];
        const oldPos = state.nodeMap.nodes[nodeId].position;

        if (!state.nodeMap.nodes[nodeId]) { return state; }
        state.nodeMap.nodes[nodeId] = newNode;
        if (noPos) { state.nodeMap.nodes[nodeId].position = oldPos; }
        
      }
    },


    // io variables high-level concerns
    //----------------------------------------------------------------------------------------------------

    updateIoVariableContents: (state, action) => {

      const ioType = action.payload.ioType;
      const variableName = action.payload.variableName;
      const contents = action.payload.contents;

      //console.log(`Updating ${ioType}Variable ${variableName}: `, contents)

      if (isNullOrUndefined([ioType, variableName])) {
        console.log('Certain payload variables missing!');
        return state;
      }

      if (ioType === 'input') {
        state.nodeMap.io.inputVariables[variableName].contents = contents;
      }

      if (ioType === 'output') {
        state.nodeMap.io.outputVariables[variableName].contents = contents;
      }

      if (ioType === 'chatMessage') {

        if (!state.nodeMap.io.chat) {
          state.nodeMap.io.chat = {};
        }

        state.nodeMap.io.chat.message = contents;
      }

      //don't set state to out of date

      return state;
    },

    // Input variables
    //----------------------------------------------------------------------------------------------------

    addInputVariable: (state, action) => {

      //restore: //console.log("Adding input variable");

      let inputVariable = deepCopy(inputVariableTemplate);

      //generate unique name for input like "Input 2"
      let name = 'New Input'
      let nameBase = name;
      let counter = 2;
      while (hasKey(state.nodeMap?.io?.inputVariables, name)) {
        //restore: //console.log(name);
        name = nameBase + ' ' + counter.toString();
        counter = counter + 1;
      }

      inputVariable.name = name;

      if (!state.nodeMap.io) { state.nodeMap.io = {}; }
      if (!state.nodeMap.io.inputVariables) { state.nodeMap.io.inputVariables = {}; }

      state.nodeMap.io.inputVariables[inputVariable.name] = inputVariable;
      state.upToDate = false;
    },

    updateInputVariable: (state, action) => {

      const inputVariable = action.payload.inputVariable;

      state.nodeMap.io.inputVariables[inputVariable.name] = structuredClone(inputVariable);
    },

    deleteInputVariable: (state, action) => {

      //restore: //console.log("Deleting input variable");

      let name = action.payload.name;

      delete state.nodeMap?.io?.inputVariables[name];
      state.upToDate = false;
    },

    updateInputVariableRequired: (state, action) => {

      //restore: //console.log("Updating input variable requireContent");

      let name = action.payload.name;
      let requireContent = action.payload.requireContent;

      state.nodeMap.io.inputVariables[name].requireContent = requireContent;
      state.upToDate = false;
    },

    updateInputVariableType: (state, action) => {

      //restore: //console.log("Updating input variable type");

      let name = action.payload.name;
      let newType = action.payload.newType;

      if (isNullOrUndefined([name, newType])) {
        return state;
      }

      let newName = titleCase(newType);
      if (newName.length < 10) { newName = newName + ' Input'; }

      // Reserved types must have names match
      if (reservedTypes.includes(newType)) {
        newName = newType;
        if (hasKey(state.nodeMap.io.inputVariables, newName)) {
          //restore: //console.log("That variable already exists!");
          return state;
        }
      }

      // Make sure new name is unique
      let counter = 2;
      let nameBase = newName;
      while (hasKey(state.nodeMap.io.inputVariables, newName)) {
        //restore: //console.log(newName);
        newName = nameBase + ' ' + counter.toString();
        counter = counter + 1;
      }

      // Alter settings accordingly
      delete state.nodeMap.io.inputVariables[name].minLength;
      delete state.nodeMap.io.inputVariables[name].maxLength;
      delete state.nodeMap.io.inputVariables[name].minValue;
      delete state.nodeMap.io.inputVariables[name].maxValue;
      delete state.nodeMap.io.inputVariables[name].showToUser;
      delete state.nodeMap.io.inputVariables[name].guiElement;
      delete state.nodeMap.io.inputVariables[name].chatMessageRole;
      delete state.nodeMap.io.inputVariables[name].chatMessageName;

      state.nodeMap.io.inputVariables[name].showToUser = true;

      // Add correct dataType
      if (newType === 'text') { state.nodeMap.io.inputVariables[name].dataType = 'string'; }
      if (newType === 'number') { state.nodeMap.io.inputVariables[name].dataType = 'number'; }
      if (newType === 'image') { state.nodeMap.io.inputVariables[name].dataType = 'imageUrl'; }
      if (newType === 'audio') { state.nodeMap.io.inputVariables[name].dataType = 'audioUrl'; }
      if (newType === 'chatMessage') { state.nodeMap.io.inputVariables[name].dataType = 'chatMessage'; }
      if (newType === 'chatHistory') { state.nodeMap.io.inputVariables[name].dataType = 'chatMessageArray'; }
      if (newType === 'chatImage') { state.nodeMap.io.inputVariables[name].dataType = 'chatMessageImage'; }
      if (newType === 'chatAudio') { state.nodeMap.io.inputVariables[name].dataType = 'chatMessageAudio'; }

      // Init min / max length for text based inputs
      if (['text', 'chatMessage'].includes(newType)) {
        state.nodeMap.io.inputVariables[name].minLength = null;
        state.nodeMap.io.inputVariables[name].maxLength = null;
      }

      // Init min / max length for text based inputs
      if (['number'].includes(newType)) {
        state.nodeMap.io.inputVariables[name].minValue = null;
        state.nodeMap.io.inputVariables[name].maxValue = null;
        state.nodeMap.io.inputVariables[name].guiElement = 'numberInput';
      }

      // Init min / max length for text based inputs
      if (['chatMessage', 'chatResponse'].includes(newType)) {
        state.nodeMap.io.inputVariables[name].chatMessageRole = 'user';
        state.nodeMap.io.inputVariables[name].chatMessageName = 'user';
      }

      //edit nodeMapVariable entry
      state.nodeMap.io.inputVariables[name].name = newName;
      state.nodeMap.io.inputVariables[name].type = newType;
      state.nodeMap.io.inputVariables[name].contents = null;
      state.nodeMap.io.inputVariables = renameKey(state.nodeMap.io.inputVariables, name, newName);
      state.upToDate = false;
    },

    updateInputVariableName: (state, action) => {

      //console.log("Updating input variable name!");

      let name = action.payload.name;
      let newName = action.payload.newName;

      if (isNullOrUndefined([name, newName])) { return state; }

      // Reserved types must have names match
      if (reservedTypes.includes(newName)) { return state; }

      // Ensure new name does not match that of another variable
      if (hasKey(state.nodeMap.io.inputVariables, newName)) { alert(`${newName} is not a unique name!`);  return state; }

      // Edit nodeMapVariable entry
      state.nodeMap.io.inputVariables[name].name = newName;
      state.nodeMap.io.inputVariables = renameKey(state.nodeMap.io.inputVariables, name, newName);
      state.upToDate = false;
    },

    updateInputVariableSetting: (state, action) => {

      const name = action.payload.name;
      const setting = action.payload.setting;
      const value = action.payload.value;

      //console.log(`Updating ${name} ${setting} to ${value}`);

      if (isNullOrUndefined([name, setting, value])) { return state; }

      state.nodeMap.io.inputVariables[name][setting] = value;
      state.upToDate = false;
    },

    // Output variables
    //----------------------------------------------------------------------------------------------------

    addOutputVariable: (state, action) => {

      //console.log("Adding output variable to nodeMap!");

      let outputVariable = deepCopy(outputVariableTemplate);

      // Generate unique name for output like "Output 2"
      let name = titleCase(outputVariable.type);
      if (name.length < 10) { name = name + ' Output'; }

      let nameBase = name;
      let counter = 2;
      while (hasKey(state.nodeMap?.io?.outputVariables, name)) {
        //restore: //console.log(name);
        name = nameBase + ' ' + counter.toString();
        counter = counter + 1;
      }

      outputVariable.name = name;

      if (!state.nodeMap.io) { state.nodeMap.io = {}; }
      if (!state.nodeMap.io.outputVariables) { state.nodeMap.io.outputVariables = {}; }

      state.nodeMap.io.outputVariables[outputVariable.name] = outputVariable;
      state.upToDate = false;
    },

    updateOutputVariable: (state, action) => {

      console.log('Updating output variable!');

      const outputVariable = action.payload.outputVariable;

      state.nodeMap.io.outputVariables[outputVariable.name] = structuredClone(outputVariable);
    },

    deleteOutputVariable: (state, action) => {

      //console.log("Deleting output variable from nodeMap!");

      let name = action.payload.name;

      delete state.nodeMap?.io?.outputVariables[name];
      state.upToDate = false;
    },

    updateOutputVariableRequired: (state, action) => {

      const name = action.payload.name;
      const requireContent = action.payload.requireContent;

      //console.log(`Setting output variable ${name} requireContent to ${requireContent}`);

      if (isNullOrUndefined([name, requireContent])) {  return state; }

      state.nodeMap.io.outputVariables[name].requireContent = requireContent;
      state.upToDate = false;
    },

    updateOutputVariableType: (state, action) => {

      //console.log("Updating output variable type");

      let name = action.payload.name;
      let newType = action.payload.newType;

      if (isNullOrUndefined([name, newType])) {  return state; }

      let newName = titleCase(newType);
      if (newName.length < 10) { newName = newName + ' Output'; }

      //reserved types must have names match
      if (reservedTypes.includes(newType)) {
        newName = newType;
        if (hasKey(state.nodeMap.io.outputVariables, newName)) {
          //restore: //console.log("That variable already exists!");
          return state;
        }
      }

      //make sure new name is unique
      let counter = 2;
      let nameBase = newName;
      while (hasKey(state.nodeMap.io.outputVariables, newName)) {
        //restore: //console.log(newName);
        newName = nameBase + ' ' + counter.toString();
        counter = counter + 1;
      }

      // Alter settings accordingly
      delete state.nodeMap.io.outputVariables[name].minLength;
      delete state.nodeMap.io.outputVariables[name].maxLength;
      delete state.nodeMap.io.outputVariables[name].minValue;
      delete state.nodeMap.io.outputVariables[name].maxValue;
      delete state.nodeMap.io.outputVariables[name].showToUser;
      delete state.nodeMap.io.outputVariables[name].guiElement;
      delete state.nodeMap.io.outputVariables[name].chatMessageRole;
      delete state.nodeMap.io.outputVariables[name].chatMessageName;

      state.nodeMap.io.outputVariables[name].showToUser = true;

      // Add correct dataType
      if (newType === 'text') { state.nodeMap.io.outputVariables[name].dataType = 'string'; }
      if (newType === 'number') { state.nodeMap.io.outputVariables[name].dataType = 'number'; }
      if (newType === 'image') { state.nodeMap.io.outputVariables[name].dataType = 'imageUrl'; }
      if (newType === 'audio') { state.nodeMap.io.outputVariables[name].dataType = 'audioUrl'; }
      if (newType === 'chatMessage') { state.nodeMap.io.outputVariables[name].dataType = 'chatMessage'; }
      if (newType === 'chatResponse') { state.nodeMap.io.outputVariables[name].dataType = 'chatMessage'; }
      if (newType === 'chatImage') { state.nodeMap.io.outputVariables[name].dataType = 'chatMessageImage'; }
      if (newType === 'chatAudio') { state.nodeMap.io.outputVariables[name].dataType = 'chatMessageAudio'; }

      // Init min / max length for text based inputs
      if (['text', 'chatMessage', 'chatResponse'].includes(newType)) {
        state.nodeMap.io.outputVariables[name].minLength = null;
        state.nodeMap.io.outputVariables[name].maxLength = null;
      }

      // Init min / max length for text based inputs
      if (['number'].includes(newType)) {
        state.nodeMap.io.outputVariables[name].minValue = null;
        state.nodeMap.io.outputVariables[name].maxValue = null;
        state.nodeMap.io.outputVariables[name].guiElement = 'numberInput';
      }

      // Init min / max length for text based inputs
      if (['chatMessage', 'chatResponse'].includes(newType)) {
        state.nodeMap.io.outputVariables[name].chatMessageRole = 'assistant';
        state.nodeMap.io.outputVariables[name].chatMessageName = 'Assistant';
      }

      //edit nodeMapVariable entry
      state.nodeMap.io.outputVariables[name].name = newName;
      state.nodeMap.io.outputVariables[name].type = newType;
      state.nodeMap.io.outputVariables[name].contents = null;
      state.nodeMap.io.outputVariables = renameKey(state.nodeMap.io.outputVariables, name, newName);
      state.upToDate = false;
    },

    updateOutputVariableName: (state, action) => {

      //restore: //console.log("Updating output variable name");

      let name = action.payload.name;
      let newName = action.payload.newName;

      if (isNullOrUndefined([name, newName])) {
        return state;
      }

      //reserved types must have names match
      if (reservedTypes.includes(newName)) {
        //restore: //console.log("That name is reserved!");
        return state;
      }

      if (hasKey(state.nodeMap.io.outputVariables, newName)) {
        alert(`${newName} is not a unique name!`);
        //restore: //console.log("That name is already taken!");
        return state;
      }

      //edit nodeMapVariable entry
      state.nodeMap.io.outputVariables[name].name = newName;
      state.nodeMap.io.outputVariables = renameKey(state.nodeMap.io.outputVariables, name, newName);
      state.upToDate = false;
    },

    updateOutputVariableSetting: (state, action) => {
      const name = action.payload.name;
      const setting = action.payload.setting;
      const value = action.payload.value;

      //console.log(`Updating ${name} ${setting} to ${value}`);

      state.nodeMap.io.outputVariables[name][setting] = value;
      state.upToDate = false;
    },

    // chat messages
    //----------------------------------------------------------------------------------------------------

    addChatMessage: (state, action) => {

      //restore: //console.log("Updating output variable name");

      let time = action.payload.time;
      let role = action.payload.role;
      let content = action.payload.content;

      if (isNullOrUndefined([time, role, content])) {
        //console.log('Certain payload variables missing!');
        return state;
      }

      //handle missing chat messages array
      if (!state.nodeMap.io?.chatHistory) {
        state.nodeMap.io.chatHistory = [];
      }

      const message = {time, role, content};

      state.nodeMap.io.chatHistory.push(message);
    },

    updateChatHistory: (state, action) => {

      console.log('Upating chat history!');

      const chatHistory = action.payload.chatHistory;
      state.nodeMap.io.chat.history = structuredClone(chatHistory);
    },

    clearChatHistory: (state, action) => {

      //handle missing chat messages array
      if (!state.nodeMap.io.chat) {
        state.nodeMap.io.chat = {};
      }

      state.nodeMap.io.chat.message = '';
      state.nodeMap.io.chat.history = [];
    },


    // nodes
    //----------------------------------------------------------------------------------------------------

    addNode: (state, action) => {
      
      let template = action.payload.template;
      let position = action.payload.position || deepCopy(state.nodeMap?.vars?.viewportPosition);
      
      //ensure valid data
      if (!template) {
        //console.log('Slice: no template!');
        return state;
      }

      if (isNullOrUndefined([nodes[template].template])) {
        //console.log(`template '${template}' not recognized!`);
        return state;
      }

      if (!position) {
        //restore: //console.log('Slice: no position!');
        return state;
      }

      //nodes entry sometimes gets deleted if empty
      if (isNullOrUndefined([state.nodeMap.nodes])) {
        state.nodeMap.nodes = {};
      }

      //TO DO: FIX THE NEED FOR THIS BAND-AID!!!
      position.x = position.x + 100;
      position.y = position.y + 100;

      //console.log(`Slice: Adding ${template} node!`, position);

      //copy node from template
      let node = deepCopy(nodes[template].template);

      //set node position
      node.position = position;

      //generate unique random node id
      let count = 1;
      let baseNodeId = titleCase(`${template} node`);
      let nodeId = baseNodeId + ' ' + count.toString();

      while (state.nodeMap.nodes[nodeId]) {
        count = count + 1;
        nodeId = baseNodeId + ' ' + count.toString();
      }

      node.id = nodeId;

      //console.log(node);

      state.nodeMap.nodes[nodeId] = node;
      state.upToDate = false;
    },

    updateNode: (state, action) => {

      const node = action.payload.node;
      const noPos = action.payload.noPos;

      if (!state.nodeMap.nodes[node.id]) {
        return state;
      }

      if (noPos) { node.position = deepCopy(state.nodeMap.nodes[node.id].position); }
      state.nodeMap.nodes[node.id] = structuredClone(node);

      return state;

    },

    deleteNode: (state, action) => {
      
      const nodeId = action.payload?.nodeId;

      //nodes entry sometimes gets deleted if empty
      if (!nodeId) {
        //console.log('Delete Node: No nodeId!');
        return state;
      }

      //console.log(`Slice: Deleting ${nodeId}`);

      delete state.nodeMap.nodes[nodeId];

      state.nodeMap = pruneMissingConnections(deepCopy(state.nodeMap));
      state.upToDate = false;
    },

    updateNodePosition: (state, action) => {
      
      const nodeId = action.payload?.nodeId;
      const newPosition = action.payload?.position;

      //nodes entry sometimes gets deleted if empty
      if (!nodeId || !newPosition) {
        //console.log('Certain payload variables missing!');
        return state;
      }

      ////console.log(`Updating position of ${nodeId} to `, newPosition);

      state.nodeMap.nodes[nodeId].position = newPosition;
      state.upToDate = false;
    },
    
    updateNodeSetting: (state, action) => {
      
      const nodeId = action.payload?.nodeId;
      const setting = action.payload?.setting;
      const newValue = action.payload?.newValue;

      // Nodes entry sometimes gets deleted if empty
      if (isNullOrUndefined([nodeId, setting, newValue])) {
        //console.log('Certain payload variables missing!');
        return state;
      }

      // New value has to be different than the current value
      if (state.nodeMap.nodes[nodeId].settings[setting] === newValue) {
        return state;
      }

      // Ensure custom code complies with requirements
      if (setting === 'customCode') {

        // Ensure custom code function includes 'function processNode' somewhere
        if (!newValue.includes('function run(node)')) {
          state.nodeMap.nodes[nodeId].state = 'warning';
          state.nodeMap.nodes[nodeId].message = 'Warning: custom code must contain "function run(node)" in order to work';
        } else {
          if (state.nodeMap.nodes[nodeId].state === 'warning') {
            state.nodeMap.nodes[nodeId].state = '';
          }
        }
      }

      //console.log(`Changing '${nodeId}' setting '${setting}' to '${newValue}'`);

      state.nodeMap.nodes[nodeId].settings[setting] = newValue;
      state.upToDate = false;
    },

    // node inputs
    //----------------------------------------------------------------------------------------------------

    addNodeInput: (state, action) => {

      const nodeId = action.payload.nodeId;

      if (!nodeId) {
        //console.log("no nodeId");
        return state;
      }

      const type = state.nodeMap.nodes[nodeId].type;

      let input = null;

      for (let key in nodes[type].template.inputs) {
        input = deepCopy(nodes[type].template.inputs[key]);
        break;
      }

      //generate unique name for input like "Input 2"
      let counter = 1;
      let idBase = 'input';
      let id = titleCase(idBase + ' ' + counter.toString());

      while (hasKey(state.nodeMap.nodes[nodeId].inputs, titleCase(idBase + ' ' + counter.toString()))) {
        counter = counter + 1;
        id = titleCase(idBase + ' ' + counter.toString());
      }

      if (input.name != null) { input.name = ''; }
      input.contents = null;
      input.requireContent = true;

      state.nodeMap.nodes[nodeId].inputs[id] = input;
      state.upToDate = false;
    },

    deleteNodeInput: (state, action) => {

      const nodeId = action.payload.nodeId;
      const inputId = action.payload.inputId;

      if (isNullOrUndefined([nodeId, inputId])) {
        //console.log('certain payload variables missing!');
        return state;
      }

      if (Object.keys(state.nodeMap.nodes[nodeId].inputs).length < 2) {
        //console.log('Cannot delete only remaining input!');
        return state;
      }

      //console.log(`Deleting ${inputId} of ${nodeId}`);

      delete state.nodeMap.nodes[nodeId].inputs[inputId];

      state.nodeMap = pruneMissingConnections(deepCopy(state.nodeMap));
      state.upToDate = false;
    },

    updateNodeInputName: (state, action) => {

      const nodeId = action.payload.nodeId;
      const inputId = action.payload.inputId;
      const newName = action.payload.newName;

      //console.log(nodeId, inputId, newName);

      if(isNullOrUndefined([nodeId, inputId, newName])) {
        //console.log("certain payload variables missing!");
        return state;
      }

      //console.log('long id', state.nodeMap.nodes[nodeId]);

      state.nodeMap.nodes[nodeId].inputs[inputId].name = newName;
      state.upToDate = false;
    },

    updateNodeInputRequired: (state, action) => {

      const nodeId = action.payload.nodeId;
      const inputId = action.payload.inputId;
      const requireContent = action.payload.requireContent;
      
      if(isNullOrUndefined([nodeId, inputId, requireContent])) {
        //console.log('certain payload variables missing!');
        return state;
      }

      state.nodeMap.nodes[nodeId].inputs[inputId].requireContent = requireContent;
      state.upToDate = false;
    },

    // node outputs
    //----------------------------------------------------------------------------------------------------

    addNodeOutput: (state, action) => {

      const nodeId = action.payload.nodeId;

      if (!nodeId) {
        //console.log("no nodeId");
        return state;
      }

      const type = state.nodeMap.nodes[nodeId].type;

      let output = null;

      for (let key in nodes[type].template.outputs) {
        output = deepCopy(nodes[type].template.outputs[key]);
        break;
      }

      //generate unique name for output like "Output 2"
      let counter = 1;
      let idBase = 'output';
      let id = titleCase(idBase + ' ' + counter.toString());

      while (hasKey(state.nodeMap.nodes[nodeId].outputs, titleCase(idBase + ' ' + counter.toString()))) {
        counter = counter + 1;
        id = titleCase(idBase + ' ' + counter.toString());
      }

      if (output.name != null) { output.name = ''; }
      output.contents = null;
      if (output.requireContent != null) { output.requireContent = true; }

      state.nodeMap.nodes[nodeId].outputs[id] = output;
      state.upToDate = false;
    },

    deleteNodeOutput: (state, action) => {

      const nodeId = action.payload.nodeId;
      const outputId = action.payload.outputId;

      if (isNullOrUndefined([nodeId, outputId])) {
        //console.log('certain payload variables missing!');
        return state;
      }

      if (Object.keys(state.nodeMap.nodes[nodeId].outputs).length < 2) {
        //console.log('Cannot delete only remaining output!');
        return state;
      }

      //console.log(`Deleting ${outputId} of ${nodeId}`);

      delete state.nodeMap.nodes[nodeId].outputs[outputId];

      state.nodeMap = pruneMissingConnections(deepCopy(state.nodeMap));
      state.upToDate = false;
    },

    updateNodeOutputName: (state, action) => {

      const nodeId = action.payload.nodeId;
      const outputId = action.payload.outputId;
      const newName = action.payload.newName;

      //console.log(nodeId, outputId, newName);

      if(isNullOrUndefined([nodeId, outputId, newName])) {
        //console.log("certain payload variables missing!");
        return state;
      }

      //console.log('long id', state.nodeMap.nodes[nodeId]);

      state.nodeMap.nodes[nodeId].outputs[outputId].name = newName;
      state.upToDate = false;
    },

    updateNodeOutputRequired: (state, action) => {

      const nodeId = action.payload.nodeId;
      const outputId = action.payload.outputId;
      const requireContent = action.payload.requireContent;
      
      if(isNullOrUndefined([nodeId, outputId, requireContent])) {
        //console.log('certain payload variables missing!');
        return state;
      }

      state.nodeMap.nodes[nodeId].outputs[outputId].requireContent = requireContent;
      state.upToDate = false;
    },

    // node connections
    //----------------------------------------------------------------------------------------------------

    addNodeConnection: (state, action) => {

      const origin = action.payload.origin;
      const target = action.payload.target;
      
      if(isNullOrUndefined([origin, target])) {
        //console.log('certain payload variables missing!');
        return state;
      }

      //console.log(`Setting connection: '${origin.outputId}' of '${origin.nodeId}' => '${target.inputId}' of '${target.nodeId}'`);

      //destinations may be undefined at first
      if (!state.nodeMap.nodes[origin.nodeId].outputs[origin.outputId].destinations) {
        state.nodeMap.nodes[origin.nodeId].outputs[origin.outputId].destinations = {};
      }

      let connectionId = makeConnectionId(target, origin);

      state.nodeMap.nodes[origin.nodeId].outputs[origin.outputId].destinations[connectionId] = {origin, target}
      state.upToDate = false;

      return state;

      //makes a large connection ID that uniquely reperesents each connection
      function makeConnectionId(t, o) {
        return o.outputId + ' of ' + o.nodeId + ' to ' + t.inputId + ' of ' + t.nodeId;
      }
    },

    //TO DO: VERIFY THIS WORKS (written by gpt4)
    deleteNodeConnection: (state, action) => {

      const origin = action.payload.origin;
      const target = action.payload.target;
      
      //if we are supplied only the target
      if(target && !origin) {

        // For each node
        for (let nodeId in state.nodeMap.nodes) {
          const node = state.nodeMap.nodes[nodeId];

          // For each output
          for (let outputId in node.outputs) {
            const output = node.outputs[outputId];
            
            // For each destination
            for (let destinationId in output.destinations) {
              const destination = output.destinations[destinationId];

              //console.log(deepCopy(destination.target));
              //console.log(deepCopy(target));

              if (JSON.stringify(destination.target) === JSON.stringify(target)) {
                delete state.nodeMap.nodes[nodeId].outputs[outputId].destinations[destinationId];
              }
            }
          }
        }
      }

      //if we are supplied only the origin
      if(origin && !target) {
        state.nodeMap.nodes[origin.nodeId].outputs[origin.outputId].destinations = {};
      }

      if (origin && target) {
        // For each destination
        for (let destinationId in state.nodeMap.nodes[origin.nodeId].outputs[origin.outputId].destinations) {
          const destination = state.nodeMap.nodes[origin.nodeId].outputs[origin.outputId].destinations[destinationId];

          if (JSON.stringify(destination.origin) === JSON.stringify(origin) &&
              JSON.stringify(destination.target) === JSON.stringify(target) ) {
            delete state.nodeMap.nodes[origin.nodeId].outputs[origin.outputId].destinations[destinationId];
          }
        }
      }

      state.upToDate = false;
  
      return state;
  },
  

  resetSlice: (state, actions) => {
    return initialState;
  }

  }

});

//searches for and removes connections with missing nodes, inputs, or outputs.
function pruneMissingConnections(nodeMap) {

  if (!nodeMap.nodes) {
    nodeMap.nodes = {};
  }

  for (const [nodeId, node] of Object.entries(nodeMap.nodes)) {

    //not all nodes have inputs or outputs
    if (!node.outputs) {
      continue;
    }

    for (const [outputId, output] of Object.entries(node.outputs)) {
      for (const [connectionId, connection] of Object.entries(output.destinations || {})) {

          const origin = connection.origin;
          const target = connection.target;

          const originNode = nodeMap.nodes?.[origin.nodeId];
          const originOutput = nodeMap.nodes?.[origin.nodeId]?.outputs?.[origin.outputId];

          const targetNode = nodeMap.nodes?.[target.nodeId];
          const targetInput = nodeMap.nodes?.[target.nodeId]?.inputs?.[target.inputId];

          if (!originNode || !originOutput || !targetNode || !targetInput) {
            delete output.destinations[connectionId];
          }
        
        }
      }
    }

  //console.log(nodeMap);
  return nodeMap;
}

function randomLetters(length = 6) {

  const letters = [
      "A","B","C","D","E","F","G",
      "H","I","J","K","L","M","N",
      "O","P","Q","R","S","T","U",
      "V","W","X","Y","Z" ];

  let id = "";    //init blank id

  for (let i = 0; i < length; i++) {
      id = id + letters[Math.floor(Math.random() * letters.length)];
  }

  return id;
}

function titleCase(str) {

  return str.replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
}

//returns if an object has a key anywhere in it
function hasKey(obj, targetKey) {

  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  for (let key in obj) {
    if (key === targetKey) {
      return true;
    }

    if (typeof obj[key] === 'object') {
      if (hasKey(obj[key], targetKey)) {
        return true;
      }
    }
  }

  return false;
}

function renameKey(obj, oldKey, newKey) {

  if (oldKey === newKey) {
    return obj;
  }

  const newObj = {};

  for (const key in obj) {
    if (key === oldKey) {
      newObj[newKey] = obj[key];
    } else {
      newObj[key] = obj[key];
    }
  }

  return newObj;
}

function isNullOrUndefined(arr) {

  if (!Array.isArray(arr)) {
    throw new Error('Input must be an array');
  }

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === null || arr[i] === undefined) {
      return true;
    }
  }

  return false;
}

//deeply copy an object
function deepCopy(obj) {

  // Check if the input is an object
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // Create an empty object or array to store the deep copied object
  let copy = Array.isArray(obj) ? [] : {};

  // Iterate through the object's properties
  for (let key in obj) {
    // Check if the property is an object or an array, and call the deepCopy function recursively
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      copy[key] = deepCopy(obj[key]);
    } else {
      // If the property is a primitive value, just copy it
      copy[key] = obj[key];
    }
  }

  return copy;
}

export const {
  
  setUpToDate,
  updateNodeMap,
  updateNodeMapName,
  updateNodeMapSetting,
  updateViewportPosition,
  updateViewportZoom,
  updateNodes,

  updateIoVariableContents,

  addInputVariable,
  updateInputVariable,
  deleteInputVariable,
  updateInputVariableRequired,
  updateInputVariableType,
  updateInputVariableName,
  updateInputVariableSetting,

  addOutputVariable,
  updateOutputVariable,
  deleteOutputVariable,
  updateOutputVariableRequired,
  updateOutputVariableType,
  updateOutputVariableName,
  updateOutputVariableSetting,

  addChatMessage,
  updateChatHistory,
  clearChatHistory,

  addNode,
  updateNode,
  deleteNode,
  updateNodePosition,
  updateNodeSetting,

  addNodeInput,
  deleteNodeInput,
  updateNodeInputName,
  updateNodeInputRequired,

  addNodeOutput,
  deleteNodeOutput,
  updateNodeOutputName,
  updateNodeOutputRequired,

  addNodeConnection,
  deleteNodeConnection,

  resetSlice

} = nodeMapSlice.actions;

export default nodeMapSlice.reducer;