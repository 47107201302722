import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useSelector } from 'react-redux';
// import { login } from '../../redux/slices/loginSlice.js';

import useSnackbar from "../../SnackBar/useSnackBar.js";
// import { resetSnackbar } from "../../redux/slices/snackBarSlice";

import useDebounce from '../../utils/useDebounce.js';
import useReCaptchaV2 from '../../utils/useReCaptchaV2';

import { loadingSVG } from '../../loadingSVG.js';

import config from '../../config.js';
let api = config.apiUrl;


export const Register = () => {


    const timerRef = useRef(null);
    // const isMounted = useRef(false);

    const inputRef = useRef();
    let cursorPosition = useRef(0);

    // state to update the cursor every input change
    const [inputChange, setInputChange] = useState(0);

    const [registeredStatus, setRegisteredStatus] = useState(false);
    const [captchaStatus, setCaptchaStatus] = useState(false);

    const [loading, setLoading] = useState(false);
    // const [captchaLoading, setCaptchaLoading] = useState(false);
    
    // input states
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [accessCode, setAccessCode] = useState('');
    const [userName, setUserName] = useState('');

    
    // debouncing states
    const [queryUserName, setQueryUserName] = useState('');
    const [queryEmail, setQueryEmail] = useState('');
    const [queryPass, setQueryPass] = useState(''); 

    // handle errors
    const [userNameError, setUserNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passError, setPassError] = useState('');

    // handle success
    const [userNameSuccess, setUserNameSuccess] = useState('');
    const [emailSuccess, setEmailSuccess] = useState('');
    const [passSuccess, setPassSuccess] = useState('');


    // user logged in selector
    const loggedInStatus = useSelector((state) => state.user.loggedIn);

    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const snackbar = useSnackbar();

    const debounceUserName = useDebounce(queryUserName, 200);
    const debounceEmail = useDebounce(queryEmail, 200);
    const debouncePass = useDebounce(queryPass, 200);


    // temp snackbar reset
    // useEffect(() => {
    //     dispatch(resetSnackbar());
    //   }, [dispatch]);


      // subscribe to user changes
      useEffect(() => {
        
        if(loggedInStatus) {
            navigate('/sandbox/');
        }

      }, [loggedInStatus]);



      // --------- captcha stuff
      const elementId = 'recaptcha-container';
      const sitekey = process.env.REACT_APP_CAPTCHA;

      const onExpired = () => {
        //console.log('reCAPTCHA expired');
        setCaptchaStatus(false);
        snackbar('Captcha expired please reverify', 'error');
        };

      const onError = () => {
        //console.log('reCAPTCHA error');
        setCaptchaStatus(false);
        snackbar('Network error has occurred please reverify', 'error');
        };

      let [captchaResponse, resetCaptcha] = useReCaptchaV2(elementId, sitekey, onExpired, onError);


      // subscribe to captcha response
      useEffect(() => {

        // //console.log('in check response use effect', captchaResponse);
        // if(isMounted.current) {

        if(captchaResponse && !captchaStatus) {

            // //console.log('in captcha if', captchaResponse);

            const verifyCaptcha = async () => {

            let payload = {
                captchaResponse: captchaResponse
            };
    
    
            let url = api + 'users/captcha';
    
    
            try {
    
                let request = await fetch(url, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                    "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload),
                });
    
                let response = await request.json();
    
                if(response.success) {
                    setCaptchaStatus(true);
                    snackbar('Captcha successfully verified', 'success');
    
                } else {
                    // resetCaptcha();
                    snackbar('error verifying captcha', 'error');
                }
    
            } catch(err) {
                // resetCaptcha();
                snackbar(err.message, 'error');
            }

        };

        verifyCaptcha();

    }

    // } else {
    //     isMounted.current = true;
    // }

     // Cleanup function
    //  return () => {
    //     const recaptchaContainer = document.getElementById(elementId);
    //     if (recaptchaContainer) {
    //       recaptchaContainer.remove();
    //     }
    //   };


      }, [captchaResponse]);

// ------------ end captcha stuff



      // make api call when debounced data changes
      useEffect(() => {
       
        if (debounceUserName) {
            checkUserName();
        } 


      }, [debounceUserName]);


      // subscribe to email debouncing
      useEffect(() => {

        if(debounceEmail.length >= 6) {

            if(!checkData(email, 'email')) {
                setEmailSuccess('');
                setEmailError('Please enter a valid email format');
            
            } else {
                setEmailError('');
                checkEmail();
            }
           
        // if length is less than 6 display no messages
        } else {
            setEmailSuccess('');
            setEmailError('');
        }


      }, [debounceEmail]);




       // subscribe to password debouncing
       useEffect(() => {
        
        if(debouncePass) {

            if (debouncePass.length < 8) {
                setPassSuccess('');
                setPassError('Password is not long enough');
                return;
            } 

            if (debouncePass.length >= 8) {
                setPassError('');
                setPassSuccess('Password meets requirements');
                return;
            }

        } else {
            //console.log('in debounce length zero');
            setPassError('');
            setPassSuccess('');
            return;
        }


      }, [debouncePass]);




      useEffect(() => {

        if (inputRef.current) {
           inputRef.current.setSelectionRange(cursorPosition.current - 1, cursorPosition.current - 1);  // Restore cursor position
        }

     }, [inputChange]);

    

    function checkData(data, type) {

        let regex;

        if(type === 'text') {
            // Check for non-alphanumeric characters
            regex = /[^a-zA-Z0-9]+/g;
            return !regex.test(data);

        } else {
            // Check for valid email format
            regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            return regex.test(data);
        }
      
        // If the regex test returns true, it means an unauthorized character exists
      };




    const handlePassChange = (e) => {

        let input = e.target.value;

        // set password input for debouncing
        setQueryPass(input);
        
        // set password input
        setPass(input);

    };




    
    const handleEmailChange = (e) => {
        let input = e.target.value;

        // set the input for the debounce function
        setQueryEmail(input);

        // set the input
        setEmail(input);
    };




    const handleUserNameChange = async (e) => {

        let input = e.target.value;

        // update cursor position
        cursorPosition.current = e.target.selectionStart;


        // check input length and erase any success messages
        if (input.length < 6) {
            setUserNameSuccess('');
        }

        // check instantly without debouncing
        if(!checkData(input, 'text')) {

            // Clear the previous timeout
            clearTimeout(timerRef.current);

            // reset success message if there is one
            setUserNameSuccess('');

            // set error message
            setUserNameError('You can only use letters and numbers');

            // set cursor position
            setInputChange(inputChange + 1);
            // setCPos(cursorPosition.current);

            // Clear the error message after 2 seconds
            timerRef.current = setTimeout(() => {

                // reset error message
                setUserNameError('');

                // display last availability message
                if(input.length >= 6) {

                    // used to display status of username after error message is reset
                    checkUserName();

                }

            }, 2000);

            return;
        }

        // no display message if character typed is valid
        setUserNameError('');

        // set the input for the debounce function
        setQueryUserName(input);

        // set the input
        setUserName(input);

    };




    const checkUserName = async () => {

        // clear timeout if one exists
        clearTimeout(timerRef.current);


        // if username is less than 6 characters return
        if(userName.length < 6) {
            //console.log('username less than 6');
            return;
        }


        let url = api + 'users/checkUserName/' + userName;
        //console.log('check username', url);


        try {

            let request = await fetch(url, {
                method: "GET",
                credentials: "include",
                headers: {
                "Content-Type": "application/json",
                },
            });

            let response = await request.json();

            if(response.success) {
                //console.log('response', response);
                // reset error message if displayed
                setUserNameError('');
                setUserNameSuccess('Username is available');

            } else {

                // reset success message if displayed
                setUserNameSuccess('');
                setUserNameError('Username is unavailable');
            }

        } catch(err) {
            snackbar(err.message, 'error');
        }

    };




    const checkEmail = async () => {

        // encode email
        
        let url = api + 'users/checkEmail/' + email;
        //console.log('check username', url);


        try {

            let request = await fetch(url, {
                method: "GET",
                credentials: "include",
                headers: {
                "Content-Type": "application/json",
                },
            });

            let response = await request.json();

            if(response.success) {
                //console.log('response', response);
                // reset error message if displayed
                setEmailError('');
                setEmailSuccess('Email is available');

            } else {

                // reset success message if displayed
                setEmailSuccess('');
                setEmailError('Email is already in use');
            }

        } catch(err) {
            snackbar(err.message, 'error');
        }

    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if(userNameError) {
            snackbar('Please fix username error before registering', 'error');
            return;
        }

        if(emailError) {
            snackbar('Please fix email error before registering', 'error');
            return;
        }


        if(passError) {
            snackbar('Please fix password error before registering', 'error');
            return;
        }

        setLoading(true);


        let payload = {
            userName: userName,
            email: email,
            password: pass,
            accessCode: accessCode
        };


        let url = api + 'users/register';


        try {

            let request = await fetch(url, {
                method: "POST",
                credentials: "include",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            let response = await request.json();
            setLoading(false);

            if(response.success) {

                setRegisteredStatus(true);


            } else {
                snackbar(response.message, 'error');
            }

        } catch(err) {
            setLoading(false);
            snackbar(err.message, 'error');
        }

    };

    if(registeredStatus) {
        return (
            <div className="app-root">
                <div className="register-container">
                    <p>Registration complete. Please verify your address via the link in your email to proceed.</p>
                </div>
            </div>
        );
    }


    return (
        <div className="app-root">
        <div className="register-container">
            <div className="auth-form-container">
                <h2 className="auth-title">Register</h2>
                <form className="register-form" onSubmit={handleSubmit}>

                    <div className="input-container">
                        <label htmlFor="name">Username</label>
                        <input
                            ref={inputRef}
                            value={userName}
                            name="name"
                            onChange={(e) => handleUserNameChange(e)}
                            type="text"
                            id="name"
                            placeholder="At least 6 characters long letters and numbers only"
                            // requireContent // Add this attribute for validation
                            // minLength="6"
                        />
                        {userNameError && <p className="error-msg">{userNameError}</p>}
                        {userNameSuccess && <p className="success-msg">{userNameSuccess}</p>}
                    </div>

                    <div className="input-container">
                        <label htmlFor="email">Email</label>
                        <input
                            value={email}
                            onChange={(e) => handleEmailChange(e)}
                            type="email"
                            placeholder="youremail@gmail.com"
                            id="email"
                            name="email"
                            // requireContent // Add this attribute for validation
                        />
                        {emailError && <p className="error-msg">{emailError}</p>}
                        {emailSuccess && <p className="success-msg">{emailSuccess}</p>}
                    </div>

                    <div className="input-container">
                        <label htmlFor="password">Password</label>
                        <input
                            value={pass}
                            onChange={(e) => handlePassChange(e)}
                            type="password"
                            placeholder="At least 8 characters"
                            id="password"
                            name="password"
                            // requireContent // Add this attribute for validation
                            // minLength="8" // Add this attribute to set a minimum length for the password
                        />
                         {passError && <p className="error-msg">{passError}</p>}
                         {passSuccess && <p className="success-msg">{passSuccess}</p>}
                    </div>

                    <div className="input-container">
                        <label htmlFor="accessCode">Registration Code</label>
                        <input
                            value={accessCode}
                            onChange={(e) => setAccessCode(e.target.value)}
                            type="text"
                            placeholder="Special registration code"
                            id="accessCode"
                            name="accessCode"
                            // requireContent // Add this attribute for validation
                            // minLength="8" // Add this attribute to set a minimum length for the password
                        />
                    </div>

                    <div id={elementId}></div>
                    
                    <button
                        className="auth-button register"
                        type="submit"
                        disabled={!userNameSuccess || !emailSuccess || !passSuccess || !accessCode || !captchaStatus} // Add this attribute to disable the button if any field is empty
                    >
                        {loading ? loadingSVG : 'Register'}
                    </button>

                </form>
                <div className="link-container">
                    <Link to={`/login`}>Already have an account? Login here</Link>
                </div>
            </div>
        </div>
    </div>
    );
}