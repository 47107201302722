import { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateNodeSetting } from '../../../../../../redux/slices/nodeMapSlice.js';

export default function StopSequencesTextbox({ nodeId, setting, value }) {

    const dispatch = useDispatch();

    // component logic
    //----------------------------------------------------------------------------------------------------

    const inputNameRef = useRef(null);

    useEffect(() => {
        const input = inputNameRef.current;
        if (!input) { return; }
        input.style.width = input.value.length + 2 + 'ch';
    });


    // defined functions
    //----------------------------------------------------------------------------------------------------

    function handleChangeNodeSetting(e) {
        const newValue = e.currentTarget.value;
        dispatch(updateNodeSetting({ nodeId, setting, newValue }));
    };
    
    // component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={'model-param-slider-container'}>

            <label className={'node-setting-label'}>Stop Sequences</label>
            <input
                type={'text'}

                className={'stop-sequences-textbox'}
                title="Stop sequences"
                placeholder={'"Sequence 1", "Sequence 2", ...'}
                value={value}

                onChange={handleChangeNodeSetting}

                style={{minWidth: `${value?.length + 3}ch`}}
            ></input>
        </div>
    );
}