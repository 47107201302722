//modules
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteOutputVariable, updateOutputVariableRequired, updateOutputVariableType, updateOutputVariableName, updateOutputVariableSetting } from '../../../../../../redux/slices/nodeMapSlice.js';

//resources
import deleteIconLight from './deleteIconLight.svg';
import deleteIconDark from './deleteIconDark.svg';
import settingsIcon from './settingsIcon.svg';

//const variables
const reservedTypes = [
    "chatResponse",
    "chatImage",
    "chatAudio"
  ];

export default function OutputVariable(props) {

    const dispatch = useDispatch();

    const outputVariable = props.outputVariable;

    //chatResponse, chatAudio, etc...
    const isReservedType = reservedTypes.includes(outputVariable.type);

    // Show / hide settings
    //----------------------------------------------------------------------------------------------

    const [showAdvanced, setShowAdvanced] = useState(false);

    function handleToggleAdvanced(e) {
        //console.log('Toggling advanced settings menu');
        setShowAdvanced(!showAdvanced);
    }

    // Updating variable settings
    //----------------------------------------------------------------------------------------------

    function handleUpdateVariableSetting(e) {
        const name = outputVariable.name;
        const setting = e.currentTarget.getAttribute('setting');

        let value = e.currentTarget.value;
        
        if (e.currentTarget.getAttribute('type') === 'checkbox') {
            value = e.currentTarget.checked;
        }

        dispatch(updateOutputVariableSetting({name, setting, value}));
    }

    // Defined functions
    //----------------------------------------------------------------------------------------------

    function handleDeleteOutputVariable(e) {
        //restore: //console.log("Deleting output variable");
        let name = outputVariable.name;
        dispatch(deleteOutputVariable({name}));
    }

    function handleUpdateOutputVariableRequired(e) {
        //restore: //console.log("Updating output variable requireContent");
        let name = outputVariable.name;
        let requireContent = e.currentTarget.checked;
        dispatch(updateOutputVariableRequired({name, requireContent}));
    }

    function handleUpdateOutputVariableType(e) {
        //restore: //console.log("Updating output variable type");
        let name = outputVariable.name;
        let newType = e.currentTarget.value;
        dispatch(updateOutputVariableType({name, newType}));
    }

    function handleUpdateOutputVariableName(e) {
        //restore: //console.log("Updating output variable name");
        let name = outputVariable.name;
        let newName = e.currentTarget.value;
        dispatch(updateOutputVariableName({name, newName}));
    }

    function hasSetting(name) {
        return (name in outputVariable);
    }

    // component structure
    //----------------------------------------------------------------------------------------------

    return (
        <div className={'output-variable'}>

            <div className={'output-variable-settings'}>

                <div className={'output-variable-basic-settings'}>

                    <img className={'output-variable-show-settings-icon'} src={settingsIcon} alt={''}  title={'Show options panel'} onClick={handleToggleAdvanced}></img>

                    <select className={'output-variable-type-dropdown'} title="variable type" value={outputVariable.type || ''} onChange={handleUpdateOutputVariableType}>
                        <option className={'output-variable-type-dropdown-option'} value={''}>select</option>
                        <option className={'output-variable-type-dropdown-option'} value="text">Text</option>
                        <option className={'output-variable-type-dropdown-option'} value="number">Number</option>
                        <option className={'output-variable-type-dropdown-option'} value="image">Image</option>
                        <option className={'output-variable-type-dropdown-option'} value="audio" >Audio</option>
                        <option className={'output-variable-type-dropdown-option'} value="chatResponse">Chat Response</option>
                        <option className={'output-variable-type-dropdown-option'} value="chatHistory">Chat History</option>
                    </select>

                    <input className={'output-variable-name'} type="text" title="variable name" placeholder={'Variable Name'} value={outputVariable.name || ''} disabled={isReservedType} onChange={handleUpdateOutputVariableName}></input>

                    <img className={'output-variable-delete-icon'} src={deleteIconDark} alt={''} title="delete" onClick={handleDeleteOutputVariable}></img>
                </div>
                
                { showAdvanced ? 
                    <div className={'output-variable-advanced-settings'}>

                        <div className={'require-show-output-variable'}>

                            {hasSetting('showToUser') ? 
                                <div className={'output-variable-setting'}>
                                    <input className={'output-variable-setting-checkbox'} type="checkbox" title="Show to user in IO panel" checked={outputVariable.showToUser} setting={'showToUser'} onChange={handleUpdateVariableSetting}></input>
                                    <label className={'output-variable-setting-label'}>Show to user</label>
                                </div>
                            : null }

                            {hasSetting('requireContent') ? 
                                <div className={'output-variable-setting'}>
                                    <input className={'output-variable-setting-checkbox'} type="checkbox" title="Require content to end proccessing" checked={outputVariable.requireContent} setting={'requireContent'} onChange={handleUpdateVariableSetting}></input>
                                    <label className={'output-variable-setting-label'}>require content</label>
                                </div>
                            : null }

                        </div>

                        <div className={'message-name-and-role'}>

                            {hasSetting('chatMessageRole') ? 
                                <div className={'output-variable-setting-vertical'}>
                                    <label className={'output-variable-setting-label'}>Role</label>
                                    <select className={'output-variable-setting-dropdown'} value={outputVariable.chatMessageRole || ""} setting={'chatMessageRole'} onChange={handleUpdateVariableSetting}>
                                        <option className={'output-variable-setting-dropdown-option'} value={""}></option>
                                        <option className={'output-variable-setting-dropdown-option'} value="user">User</option>
                                        <option className={'output-variable-setting-dropdown-option'} value="assistant">Assistant</option>
                                        <option className={'output-variable-setting-dropdown-option'} value="system">System</option>
                                    </select>
                                </div>
                            : null }

                            {hasSetting('chatMessageName') ? 
                                <div className={'output-variable-setting-vertical'}>
                                    <label className={'output-variable-setting-label'}>Name</label>
                                    <input className={'output-variable-setting-text-input'} type="text" value={outputVariable.chatMessageName || ""} setting={'chatMessageName'} onChange={handleUpdateVariableSetting}></input>
                                </div>
                            : null }

                        </div>

                        {hasSetting('showToUser') && outputVariable.showToUser && hasSetting('guiElement') ? 
                            <div className={'output-variable-display-type'}>
                                <div className={'output-variable-setting'}>
                                    <select className={'output-variable-setting-dropdown'} title="Show as textbox / slider" value={outputVariable.guiElement || ""} setting={'guiElement'} onChange={handleUpdateVariableSetting}>
                                        <option className={'output-variable-setting-dropdown-option'} value="numberOutput">Number Output</option>
                                        <option className={'output-variable-setting-dropdown-option'} value="slider">Slider</option>
                                    </select>
                                    <label className={'output-variable-setting-label'}>GUI input type</label>
                                </div>
                            </div>
                        : null }

                        {hasSetting('minLength') || hasSetting('maxLength') ? 
                            <div className={'output-variable-min-max-words'}>
                                <div className={'output-variable-setting'}>
                                    <input className={'output-variable-setting-input'} type="number" min="0" title="Minimum allowed word count for output variable" value={outputVariable.minLength || ""} setting={'minLength'} onChange={handleUpdateVariableSetting}></input>
                                    <label className={'output-variable-setting-label'}>{'Min length'}</label>
                                </div>
                                <div className={'output-variable-setting'}>
                                    <input className={'output-variable-setting-input'} type="number" min="0" title="Maximum allowed word count for output variable" value={outputVariable.maxLength || ""} setting={'maxLength'}  onChange={handleUpdateVariableSetting}></input>
                                    <label className={'output-variable-setting-label'}>{'Max length'}</label>
                                </div>
                            </div>
                        : null }

                        {hasSetting('minValue') || hasSetting('maxValue') ? 
                            <div className={'output-variable-min-max-value'}>
                                <div className={'output-variable-setting'}>
                                    <input className={'output-variable-setting-input'} type="number" title="Minmum allowed value for output variable" value={outputVariable.minValue || ""} setting={'minValue'} onChange={handleUpdateVariableSetting}></input>
                                    <label className={'output-variable-setting-label'}>Min value</label>
                                </div>

                                <div className={'output-variable-setting'}>
                                    <input className={'output-variable-setting-input'} type="number" title="Minmum allowed value for output variable" value={outputVariable.maxValue || ""} setting={'maxValue'} onChange={handleUpdateVariableSetting}></input>
                                    <label className={'output-variable-setting-label'}>Max value</label>
                                </div>
                            </div>
                        : null }

                    </div>
                : null}

            </div>

        </div>
    );
}