// Icon
import icon from './icon.svg';

// Util
import { util } from '../../../nodeMapProcessing/util.js';

const title = 'Output';
const description = '';

// JSON template
//----------------------------------------------------------------------------------------------------

// Node JSON template
const template = {
      
    category: "control",
    name: "output",
    type: "output",
    position: null,
    
    settings: {
        outputVariable: null,
        canEditInputs: true
    },

    gui: [
        {
            group: 'horizontal',
            elements: [
                {setting: 'outputVariable'}
            ]
        }
    ],
    
    contents: null,

    inputs: {
        "Input 1": {
            
            name: null,
            type: null,
            requireContent: true,
            allowStreaming: true,
            contents: null,
        }
    }
}

// Processing function
//----------------------------------------------------------------------------------------------------

class process {

    // Callbacks
    //--------------------------------------------------

    constructor() {

        this.onUpdateCallback =     () => { console.warn('no onUpdate callback')};
        this.onStreamCallback =     () => { console.warn('no onStream callback')};
        this.onCompleteCallback =   () => { console.warn('no onComplete callback')};
        this.onWarningCallback =    () => { console.warn('no onWarning callback')};
        this.onErrorCallback =      () => { console.warn('no onError callback')};
        this.onGiveUpCallback =     () => { console.warn('no onGiveUp callback')};

    }

    // Set callback functions
    onUpdate(callback) { this.onUpdateCallback = callback; }
    onStream(callback) { this.onStreamCallback = callback; }
    onComplete(callback) { this.onCompleteCallback = callback; }
    onWarning(callback) { this.onWarningCallback = callback; }
    onError(callback) { this.onErrorCallback = callback; }
    onGiveUp(callback) { this.onGiveUpCallback = callback; }

    // Fire functions easily
    update(node) { this.onUpdateCallback(node); }
    stream(node) { this.onStreamCallback(node); }
    finish(node) { this.onCompleteCallback(node); }
    warn(message) { this.onWarningCallback(message); }
    error(message) { this.onErrorCallback(message); }
    giveUp(message) { this.onGiveUpCallback(message); }


    // Run node
    //--------------------------------------------------

    // Node processing function
    run (node, extras) {

        const nodeMap = extras.nodeMap;

        try {

            const outputVariableId = node.settings?.outputVariable;
            const outputVariable = nodeMap.io?.outputVariables?.[outputVariableId];

            // If there's no inputVariableId
            if (!outputVariableId) {
                this.warn('warning: no output variable selected');
                this.giveUp();
                return;
            }

            // If there's no inputVariable
            if (!outputVariable) {
                this.warn(`could not find output variable '${outputVariableId}' in node map`);
                this.giveUp();
                return;
            }

            let contents = null;

            if (outputVariable.dataType === 'string') {
                contents = util.concatenateInputContents(node);
            } else {
                contents = node.inputs[Object.keys(node.inputs)[0]].contents;
            }

            util.setContents(node, contents);

            if (util.isAnyInputStreaming(node.inputs)) {
                this.stream(node);
                return;
            }

            this.finish(node);

            return;
        }

        catch (e) {
            this.error(e.message);
            this.giveUp();
            return;
        }
    }
}

// Export
//----------------------------------------------------------------------------------------------------

export const output = {
    template,
    process,
    icon,
    title,
    description
}