import { nodes } from '../nodes/nodes.js';
import { util } from './util';
import { nodeMapUtils } from './nodeMapUtils.js';

async function step(node, extras) {

    // We cannot process nodes that are currently processing
    if (node.state === 'processing') {
        //return;
    }

    const oldNode = structuredClone(node);

    console.group(node.id);

    const nodeMap = extras.nodeMap;
    extras.callNext = callNext;
    console.log(structuredClone(node));

    console.log(`${node.id}: Checking`);

    if (util.hasStreamingRequiredInputs(node)) {
        console.log(`${node.id}: Required inputs still streaming`);
        console.groupEnd();
        return;
    }

    // Obey max recursion
    //--------------------------------------------------

    // Check if any of the node's inputs have recursion entries that exceed the limit
    const maxRecursion = nodeMap.vars.maxRecursion || 5;

    // Important drilling loop to obey max recursion
    for (const inputId in node.inputs) {
        const input = node.inputs[inputId];
        if (input.recursion) {
            for (const originName in input.recursion) {
                if (input.recursion[originName] > maxRecursion) {
                    console.log(`MAX RECURSION EXCEEDED! (${maxRecursion})`);
                    node.state = 'error';
                    node.message = 'max recursion exceeded';
                    extras.updateNode(node);
                    return;
                }
            }
        }
    }

    // Record start time
    //--------------------------------------------------

    if (!node.stats) { node.stats = {}; }
    if (!node.stats.processing) { node.stats.processing = {}; }

    node.stats.processing.startTime = Date.now();


    // Run processing function
    //--------------------------------------------------

    console.log(`${node.id}: Processing`);

    // Set node state and update nodeMap
    node.state = 'processing';
    let errorCount = 0;

    if (util.isAnyInputStreaming(node.inputs)) { node.state = 'streaming'; }
    extras.updateThisNode(node);

    // Obtain processing function
    const process = nodes[node.type]?.process;
    const nodeFunc = new process();

    if (!nodeFunc) {
        node.state = 'error';
        node.message = `could not find node processing function "${node.type}"`;
        return;
    }

    // When the node issues a warning
    nodeFunc.onWarning((message) => {

        console.warn('warning: ', message);

        node.state = 'warning';
        node.message = message;
        extras.updateThisNode(node);

        return;
    });

    // When the node has an error
    nodeFunc.onError((message) => {

        console.warn('error: ', message);

        node.state = 'error';
        node.message = message;
        errorCount += 1;

        if (node.settings.tolerateErrors) {
            node.message = node.message + ` (${errorCount})`;
        }

        if (errorCount >= (node.settings.maxRetries || 5)) {
            node.message = node.message + ' (max retries exceeded)';
        }

        extras.updateThisNode(node);

        // Run again if we're under the max retry limit
        if (errorCount < (node.settings.maxRetries || 5) && node.settings.tolerateErrors) {
            const nodeDelay = 1000 - (1000 * nodeMap.vars.flowSpeed) + 1;
            window.setTimeout( () => { nodeFunc.run(node, extras) }, nodeDelay);
        }

        return;
    });

    // When node gives up
    nodeFunc.onGiveUp((message) => {

        console.warn('giveUp: ', message);

        node = oldNode;
        extras.updateThisNode(node);

        return;
    });

    // When the node updates
    nodeFunc.onUpdate((node) => {

        console.log('update: ', structuredClone(node));
        extras.updateThisNode(node);

        return;
    });

    nodeFunc.onStream((node) => {

        console.log('stream: ', structuredClone(node));

        node.state = 'streaming';
        if (!node.streamId) { node.streamId = util.randomString(); }

        extras.updateThisNode(node);
        callNext(node, extras);

        return;
    });

    nodeFunc.onComplete((node) => {

        console.log('complete: ', structuredClone(node));

        node.state = 'processed';
        extras.updateThisNode(node);
        callNext(node, extras);

        return;
    });

    // Run processing function
    nodeFunc.run(node, extras);
}

// This function is included in extras
async function callNext(node, extras) {

    const nodeMap = extras.nodeMap;

    // Deposit contents to output variable
    //--------------------------------------------------

    if (node.settings?.outputVariable) {

        const outputVariableId = node.settings.outputVariable;
        const outputVariable = nodeMap.io.outputVariables[outputVariableId];
        const contents = structuredClone(node.contents);
        const streamId = structuredClone(node.streamId);

        if (!outputVariable) { return; }

        console.log(`${node.id}: Sending to output variable '${outputVariableId}'`);
        console.log(streamId);

        if (outputVariableId === 'chatResponse') {

            const role = outputVariable.chatMessageRole;
            const name = outputVariable.chatMessageName;

            // Init chatHistory if necessary
            if (!nodeMap.io.chat) { nodeMap.io.chat = {}; }
            if (!nodeMap.io.chat.history) { nodeMap.io.chat.history = []; }
            const chatHistory = nodeMap.io.chat.history;

            // Make chat message
            let message = contents;
            if (!util.isChatMessage(contents)) {
                try { message = util.convertToChatMessage(contents); }
                catch { return; }
            }

            message.role = role || 'assistant';
            message.name = name || undefined;

            outputVariable.contents = structuredClone(message);

            // Normal behavior is to just push message to list
            if (!streamId) {
                chatHistory.push(structuredClone(message));
            }

            // When there is a stream id we must replace the matching message
            if (streamId) {

                message.streamId = node.streamId;
                let foundMessage = false;

                // Search for chatMessage in history
                for (let key in chatHistory) {
                    if (chatHistory[key].streamId === message.streamId) {
                        chatHistory[key] = structuredClone(message);
                        foundMessage = true;
                    }
                }

                // If nothing found, push message as usual
                if (!foundMessage) {
                    chatHistory.push(message);
                }
            }

            extras.updateThisChatHistory(chatHistory);
        }

        // In the case of all other output variables
        if (outputVariableId !== 'chatResponse') {

            try {

                // Convert to string if neccecary
                if (outputVariable.dataType === 'number') { outputVariable.contents = util.convertToNumber(contents); }
                if (outputVariable.dataType === 'string') { outputVariable.contents = util.convertToString(contents); }
                if (outputVariable.dataType === 'imageUrl') { outputVariable.contents = await util.convertToImageUrl(contents); }
                if (outputVariable.dataType === 'audioUrl') { outputVariable.contents = await util.convertToAudioUrl(contents); }

            }

            catch (e) {
                node.state = 'error';
                node.message = e.message;
                extras.updateThisNode(node);
                return;
            }

            extras.updateThisOutputVariable(outputVariable);
        }
    }

    // Send outputs to inputs of connected nodes
    //--------------------------------------------------

    const outputs = node.outputs;
    for (let outputId in outputs) {

        const output = outputs[outputId];
        const destinations = output.destinations;

        for (let destinationId in destinations) {

            const destination = destinations[destinationId];
            const targetInput = nodeMap.nodes[destination.target.nodeId].inputs[destination.target.inputId];

            targetInput.state = structuredClone(output.state);
            targetInput.contents = structuredClone(output.contents);
            targetInput.streamId = structuredClone(output.streamId);
            targetInput.streamCounter = structuredClone(node.streamCounter);

            // Resursion management
            if (node.state === 'processed') {

                // Example: 'Output 1 of Gpt-3 Node 2'
                const originName = outputId + ' of ' + node.id;

                if (!targetInput.recursion) {
                    targetInput.recursion = {};
                }

                if (!targetInput.recursion[originName]) {
                    targetInput.recursion[originName] = 0;
                }

                // Increment recursion counter
                targetInput.recursion[originName]++

            }
        }
    }

    // Assemble list of connected nodes
    //--------------------------------------------------

    let nextNodes = [];

    for (let outputId in outputs) {
        const output = outputs[outputId];
        const destinations = output.destinations;

        for (let destinationId in destinations) {
            const destination = destinations[destinationId];
            const targetNode = nodeMap.nodes[destination.target.nodeId];

            nextNodes.push(targetNode);
        }
    }

    // Once a node has FINISHED processing
    //--------------------------------------------------

    if (node.state !== 'streaming' && node.streamId !== null) {
        delete node.streamId;
        delete node.streamCounter;
    }

    if (node.state === 'processed') {
        const processing = node.stats.processing;

        processing.endTime = Date.now();
        processing.totalTime = processing.endTime - processing.startTime;
        processing.meanTime = processing.totalTime;
    }

    console.groupEnd();

    extras.updateThisNodeMap(nodeMap);

    // Call self on connected nodes
    //--------------------------------------------------

    const nodeDelay = 1000 - (1000 * nodeMap.vars.flowSpeed);

    if (node.state !== 'streaming' && nodeDelay > 0) {
        const nodeDelay = 1000 - (1000 * nodeMap.vars.flowSpeed);
        window.setTimeout( stepNext, nodeDelay);
    } else {
        stepNext();
    }

    // Concurrently process nodes in list of entry points
    async function stepNext() {
        const promises = nextNodes.map(node => step(node, extras));
        await Promise.all(promises);
    }

    return;

    // Check if nodeMap is done processing
    //--------------------------------------------------

    if (nodeMap.state === 'error') {
        extras.updateThisNodeMap(nodeMap);
        return;
    }

    const done = nodeMapUtils.isNodeMapDone(nodeMap);

    if (done && nodeMap.state !== 'processed') {
        nodeMap.state = 'processed';

        nodeMapUtils.resetNodes(nodeMap.nodes);
        extras.updateThisNodeMap(nodeMap);
    }

}

export { step };