//modules
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//components
import InputVariables from './InputVariables/InputVariables';
import OutputVariables from './OutputVariables/OutputVariables';

//tooltip
import ToolTipComponent from '../../../../tooltip/ToolTipComponent';

//resources
import chevronLight from './chevronLight.svg';
import chevronDark from './chevronDark.svg';

//styles
import './NodeMapVariables.css';

export default function NodeMapVariables() {

    // effects
    //----------------------------------------------------------------------------------------------

    //get nodeMap from redux state
    const nodeMap = useSelector(state => state.nodeMap.nodeMap);


    // component logic
    //----------------------------------------------------------------------------------------------

    const [expanded, setExpanded] = useState(true);
    useEffect( () => {}, [expanded]);


    // component structure
    //----------------------------------------------------------------------------------------------

    return (
        <div className={'edit-menu-section-container'}>

            <div className={'edit-menu-section-label'} onClick={() => setExpanded(!expanded)}>
                <div className={'edit-menu-section-label-line'}></div>
                <img className={`edit-menu-section-label-icon ${!expanded ? 'edit-menu-section-label-icon-collapsed' : ''}`} src={chevronDark} alt={''}></img>
                <div className={'edit-menu-section-label-text'}>Nodemap Variables</div>
                <ToolTipComponent tooltip={'nodeMapVariables'}/>
                <div className={'edit-menu-section-label-line'}></div>
            </div>

            <div className={`edit-menu-section ${!expanded ? 'edit-menu-section-collapsed' : ''}`}>
                <InputVariables />
                <OutputVariables />
            </div>
        </div>
    );
}