// Config
import config from '../../../../config.js';
import icon from './icon.svg';
import { util } from '../../../../nodeMapProcessing/util.js';
import { EdisonAI } from '../../../../EdisonAI/EdisonAI.js';

const title = 'Dall-E 2';
const description = '';

// JSON Template
//----------------------------------------------------------------------------------------------------

// Node JSON template
const template = {
      
    category: "image",
    name: "OpenAI DALL-E 2",
    type: "dalle2",
    position: null,

    settings: {

      showIcon: true,
      infoLink: 'https://platform.openai.com/docs/models/dall-e',

      canEditInputs: false,
      canEditOutputs: false,

      modelType: 'image-generation-model',
      modelProvider: 'OpenAI',
      modelName: 'dall-e-2',
      defaultImage: null,
      n: 1,
      size: '256x256',
      response_format: 'url',

    },

    stats: {
      processing: {
        meanTime: 5000
      }
    },

    gui: [
      {
        group: 'horizontal',
        elements: [
            { element: 'numberbox', label: 'number', setting: 'n', min: 0, max: 5, step: 1},
            { element: 'dropdown', label: 'size', setting: 'size', options: ['256x256', '512x512', '1024x1024']},
            { element: 'dropdown', label: 'response Format', setting: 'response_format', options: ['url','b64_json']},
        ]
      },
      {
        group: 'horizontal',
        elements: [
          { element: 'imageDisplay', setting: 'defaultImage' },
        ]
      },
      // Advanced settings
      {
        group: 'horizontal',
        elements: [
          { element: 'toggleShowAdvanced', setting: 'showAdvanced' },
        ]
      },
      {
        group: 'horizontal',
        elements: [
          { element: 'checkbox', label: 'tolerate errors', setting: 'tolerateErrors', advanced: true },
          { element: 'numberbox', label: 'max retries', setting: 'maxRetries', min: 0, advanced: true, displayCondition: {setting: 'tolerateErrors', value: [true]} },
        ]
      },
    ],

    contents: "",

    inputs: {

      "prompt": {
        
        name: 'prompt',
        type: 'text',
        dataType: 'string',
        requireContent: true,
        contents: null,
      }
    },

    outputs: {

      "image": {
        
        name: 'image',
        type: 'image',
        dataType: 'imageUrl',
        contents: null,
        destinations: {},
        clearOnSend: true
      }
    }
  }

// Processing function
//----------------------------------------------------------------------------------------------------

class process {

	// Callbacks
	//--------------------------------------------------

	constructor() {

		this.onUpdateCallback =     () => { console.warn('no onUpdate callback')};
		this.onStreamCallback =     () => { console.warn('no onStream callback')};
		this.onCompleteCallback =   () => { console.warn('no onComplete callback')};
		this.onWarningCallback =    () => { console.warn('no onWarning callback')};
		this.onErrorCallback =      () => { console.warn('no onError callback')};
		this.onGiveUpCallback =     () => { console.warn('no onGiveUp callback')};

	}

	// Set callback functions
	onUpdate(callback) { this.onUpdateCallback = callback; }
	onStream(callback) { this.onStreamCallback = callback; }
	onComplete(callback) { this.onCompleteCallback = callback; }
	onWarning(callback) { this.onWarningCallback = callback; }
	onError(callback) { this.onErrorCallback = callback; }
	onGiveUp(callback) { this.onGiveUpCallback = callback; }

	// Fire functions easily
	update(node) { this.onUpdateCallback(node); }
	stream(node) { this.onStreamCallback(node); }
	finish(node) { this.onCompleteCallback(node); }
	warn(message) { this.onWarningCallback(message); }
	error(message) { this.onErrorCallback(message); }
	giveUp(message) { this.onGiveUpCallback(message); }


  	// Run node
  	//--------------------------------------------------

	// Node processing function
	async run (node, extras) {

		const apiToken = extras.apiToken;

		try {

			// Ensure neccecary inputs are full
			if (util.hasEmptyRequiredInputs(node)) {
				this.warn('prompt input is empty');
				this.giveUp();
				return;
			}

			// Ensure the input contains a string
			const prompt = util.convertToString(node.inputs['prompt'].contents);

			// Set input and construct settings
			const input = prompt;
			const settings = {
				n: 					Number(node.settings.n || 1),
				size: 				String(node.settings.size || '512x512'),
				response_format: 	String(node.settings.response_format || 'url')
			}

			const dalle2 = new EdisonAI();

			dalle2.onComplete((data) => {

				console.log('complete: ', data);

				console.log(data);

				const url = data.result.url;
        node.settings.defaultImage = url;
        
				util.setContents(node, url);
				util.clearInputs(node);
				util.fillOutputs(node);
				this.finish(node);
				
				return;
			});

			dalle2.onError((data) => {
				console.warn('DALL-E 2 ERROR: ', data.error);
				this.error(data.error);
				return;
			});

			dalle2.run('dall-e-2', input, settings, apiToken);
		}

		catch (e) {
			this.error(e.message);
			return;
		}
  	}
}

export const dalle2 = {
    template,
    process,
    icon,
    title,
    description
}