//modules
import { useEffect, useRef, useState, createContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateViewportPosition, updateViewportZoom } from '../../redux/slices/nodeMapSlice.js';

//context
import SandboxContext from './SandboxContext';

//components
import SandboxGrid from './SandboxGrid/SandboxGrid';
import SandboxConnections from './SandboxConnections/SandboxConnections';
import SandboxNodes from './SandboxNodes/SandboxNodes';

import { loadingSVG } from '../../loadingSVG.js';

//styles
import './Sandbox.css';

export default function Sandbox() {

  //  Always re-render whenever the window is resized
  //----------------------------------------------------------------------------------------------------

  // Window resize handling
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add the event listener
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component is unmounted
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty dependency array ensures this runs once on mount and cleanup on unmount
  
  //  Drag viewport functionality
  //----------------------------------------------------------------------------------------------------

  const sandboxRef = useRef(null);

  const nodeMap = useSelector((state) => state.nodeMap?.nodeMap);
  const nodeMapData = useSelector((state) => state.request.nodeMapData);

  useEffect(() => {
    // This code will run whenever `nodeMap` changes
    setViewportPosition(nodeMap?.vars?.viewportPosition || {x: 0, y: 0});
    setViewportZoom(nodeMap?.vars?.viewportZoom || 1.00);
  }, [nodeMap]); // `nodeMap` is a dependency

  const dispatch = useDispatch();

  const zoomTimeoutId = useRef(null);
  const positionTimeoutId = useRef(null);

  const [viewportPosition, setViewportPosition] = useState(nodeMap?.vars?.viewportPosition || {x: 0, y: 0});
  const [viewportZoom, setViewportZoom] = useState(nodeMap.vars.viewportZoom || 1.00);

  const dragging = useRef(false);
  const dragStartPos = useRef({ x: 0, y: 0 });
  const viewportStartPos = useRef(viewportPosition);
  const mousePos = useRef({x: 0, y: 0});
  const dragPos = useRef({ x: 0, y: 0 });
  const dragOffset = useRef({ x: 0, y: 0 });
  const sandboxNodesRef = useRef(null);

  //dragging functionality
  useEffect(() => {

    const handleMouseDown = (e) => {

      //console.log('mousePos: ', getMousePos(e));

      //console.log(e.target)

      if (e.target === document.getElementById("sandboxNodesDiv")) {

        if ((e.button === 0 && !e.ctrlKey) || e.button === 1) {
          dragging.current = true;
          dragStartPos.current = getMousePos(e);
          viewportStartPos.current = viewportPosition;
        }
      }
    };
    
    const handleMouseMove = (e) => {

      mousePos.current = getMousePos(e);

      if (dragging.current) {

        dragPos.current = getMousePos(e);

        dragOffset.current = {
          x: dragPos.current.x - dragStartPos.current.x,
          y: dragPos.current.y - dragStartPos.current.y,
        };

        let newPosition = {
          x: viewportStartPos.current.x - dragOffset.current.x,
          y: viewportStartPos.current.y - dragOffset.current.y,
        };

        ////console.log('Middle Mouse Drag: ', newPosition);

        setViewportPosition(newPosition);
      }
    };

    const handleMouseUp = (e) => {

      if (dragging.current) {

        let newPosition = {
          x: viewportStartPos.current.x - dragOffset.current.x,
          y: viewportStartPos.current.y - dragOffset.current.y,
        };

        dragging.current = false;
        dragStartPos.current = {x: 0, y: 0};
        dragPos.current = {x: 0, y: 0};
        dragOffset.current = {x: 0, y: 0};
        viewportStartPos.current = newPosition;

        dispatch(updateViewportPosition({newPosition}));
        setViewportPosition(newPosition);
      }
    };

    const handleWheel = (e) => {

      e.preventDefault();

      // Get key data
      const alt = e.altKey;
      const ctrl = e.ctrlKey;
      const shift = e.shiftKey;
  
      // Get scroll delta
      const deltaX = shift ? e.deltaY : e.deltaX;
      const deltaY = shift ? e.deltaX : e.deltaY;
  
      const sandboxRect = sandboxRef.current.getBoundingClientRect();
      const mousePosPercent = getMousePosPercent(e);
  
      let newViewportPosition = { x: null, y: null };
  
      newViewportPosition.x = viewportPosition.x + (sandboxRect.width*mousePosPercent.x);
      newViewportPosition.y = viewportPosition.y + (sandboxRect.width*mousePosPercent.y);
  
      //console.log('newViewportPosition: ', newViewportPosition);
      //console.log(viewportPosition.x + sandboxRect.width*mousePosPercent.x);
  
      //console.log(mousePosPercent)
  
      //zooming
      if ((ctrl || alt) && deltaY) {
  
        let multiplier = 1.00;
  
        if (deltaY < 1) {
          multiplier = 1.15;
        }
  
        if (deltaY > 1) {
          multiplier = 0.85;
        }
  
        let newZoom = viewportZoom * multiplier;
  
        if (newZoom < 0.25) {
          newZoom = 0.25;
        }
  
        if (newZoom > 1.25) {
          newZoom = 1.25;
        }
  
        setViewportZoom(newZoom);

        // Clear any pending timeout
        if(zoomTimeoutId.current !== null) {
          clearTimeout(zoomTimeoutId.current);
        }

        dispatch(updateViewportZoom({ newZoom }));
        
        return;
      }
  
      let scrollMultiplier = 0.5 / viewportZoom;
  
      let newPosition = {
        x: viewportPosition.x + (scrollMultiplier *deltaX),
        y: viewportPosition.y + (scrollMultiplier * deltaY)
      };
  
      dragging.current = false;
      dragStartPos.current = {x: 0, y: 0};
      dragPos.current = {x: 0, y: 0};
      dragOffset.current = {x: 0, y: 0};
  
      // Update viewport position
      setViewportPosition(newPosition);

      // Clear any pending timeout
      if(positionTimeoutId.current !== null) {
        clearTimeout(positionTimeoutId.current);
      }

      // Set a new timeout to update the position with dispatch
      positionTimeoutId.current = setTimeout(() => { dispatch(updateViewportPosition({newPosition})); }, 100);
      return;
    };

    if (sandboxRef.current) {
      sandboxRef.current.addEventListener('mousedown', handleMouseDown);
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      sandboxRef.current.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (sandboxRef.current) {
        sandboxRef.current.removeEventListener('mousedown', handleMouseDown);
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
        sandboxRef.current.removeEventListener('wheel', handleWheel);
      }
    };
    
  }, [viewportPosition, viewportZoom]);

  function getMousePos(e) {

    if (!sandboxRef.current) {
      return {x: 0, y: 0}
    }

    const sandboxRect = sandboxRef.current.getBoundingClientRect();

    return {
      x: (e.clientX - sandboxRect.left) / viewportZoom,
      y: (e.clientY - sandboxRect.top) / viewportZoom
    }
  }

  function getMousePosPercent(e) {

    const sandboxRect = sandboxRef.current.getBoundingClientRect();

    return {
      x: (e.clientX - sandboxRect.left) / sandboxRect.width,
      y: (e.clientY - sandboxRect.top) / sandboxRect.height
    }
  }


  //  dragging node connections functionality
  //----------------------------------------------------------------------------------------------------

  const defaultDragConnectionState = {
    draggingConnection: false,
    origin: {
      nodeId: null,
      outputId: null
    },
    target: {
      nodeId: null,
      inputId: null
    },
    mousePos: {
      x: null,
      y: null
    }
  }

  const [dragConnectionState, setDragConnectionState] = useState(defaultDragConnectionState);

  function handleSandboxMouseDown(e) {

  }

  // Constantly record mouse position
  function handleSandboxMouseMove(e) {

      //get sandbox rect and mousePos within it
      const mousePos = getMousePos(e);

      let newDragConnectionState = {...dragConnectionState};
      newDragConnectionState.mousePos = mousePos;
      setDragConnectionState(newDragConnectionState);

  }

  //mouseUp anywhere means reset the dragging connection state, if dragging
  function handleSandboxMouseUp(e) {
      ////console.log(defaultDragConnectionState);
      const mousePos = getMousePos(e);
      let newDragConnectionState = {...dragConnectionState};
      newDragConnectionState.draggingConnection = false;
      newDragConnectionState.mousePos = mousePos;
      newDragConnectionState.origin = {nodeId: null, outputId: null};
      newDragConnectionState.target = {nodeId: null, inputId: null};
      setDragConnectionState(newDragConnectionState);
  }

  // if (!nodeMap) {
  //   return null;
  // }

  // Component Logic
  //----------------------------------------------------------------------------------------------------

  let viewportShifted = {};
  viewportShifted = Object.assign(viewportShifted, viewportPosition);;

    if (sandboxRef.current) {

        const sandboxRect = sandboxRef.current.getBoundingClientRect();    

        const viewportWidth = sandboxRect.width / viewportZoom;
        const viewportHeight = sandboxRect.height / viewportZoom;

        viewportShifted.x = viewportShifted.x - viewportWidth / 2;
        viewportShifted.y = viewportShifted.y - viewportHeight / 2;
    }

  //  Component
  //----------------------------------------------------------------------------------------------------

  return (

    <SandboxContext.Provider value={{ dragConnectionState, setDragConnectionState }}>

    <div className={'sandbox'}>

    { nodeMapData === 'loading' ? <div className="loading-sandbox">{loadingSVG}</div> : 
        <>
        <div className={'sandbox-nodemap-name'}>{nodeMap.info.name}</div>

        <div className={'sandbox-zoom-container-absolute'}>

          <div className={'sandbox-zoom-container'}

            ref={sandboxRef}
            onMouseDown={handleSandboxMouseDown}
            onMouseMove={handleSandboxMouseMove}
            onMouseUp={handleSandboxMouseUp}

            style={{
              left: `${-1*((50/viewportZoom) - 50)}%`,
              top: `${-1*((50/viewportZoom) - 50)}%`,
              width: `${100/viewportZoom}%`,
              height: `${100/viewportZoom}%`,
              transform: `scale(${viewportZoom})`}}>
 
            <div className={'sandbox-elements-wrapper'}>
              <SandboxGrid nodeMap={nodeMap} viewportPosition={viewportShifted} viewportZoom={viewportZoom}/>
              <SandboxConnections nodeMap={nodeMap} viewportPosition={viewportShifted} viewportZoom={viewportZoom}/>
              <SandboxNodes nodeMap={nodeMap} viewportPosition={viewportShifted} viewportZoom={viewportZoom}/>
            </div>

          </div>
        </div>
        </>}

      </div>

    </SandboxContext.Provider>

  );
}