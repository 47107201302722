// Modules
import { useSelector, useDispatch } from 'react-redux';
import { updateViewportPosition } from '../../../redux/slices/nodeMapSlice.js';

// Syles
import './PositionIndicator.css';

export default function PositionIndicator() {


    // Display Zoom
    //----------------------------------------------------------------------------------------------

    const viewportPosition = useSelector((state) => state.nodeMap.nodeMap?.vars?.viewportPosition);

    const viewportX = viewportPosition.x.toFixed(0);
    const viewportY = viewportPosition.y.toFixed(0);

    // Reset position
    //----------------------------------------------------------------------------------------------

    const dispatch = useDispatch();

    function handleResetPosition (e) {
        console.log('resetting viewport position');
        const newPosition = {x: 0, y: 0};
        dispatch(updateViewportPosition({newPosition}));
    }
   

    // Component
    //----------------------------------------------------------------------------------------------

    return (
        <div className={'position-indicator'}>
            <div className={'position-indicator-level-display'} title={'Reset Position'} onClick={handleResetPosition}>pos: {viewportX}, {-1*viewportY}</div>
        </div>
    );

}