import { useState, useEffect } from 'react';

export default function ChatMessage({message}) {

    // Determine if chatMessage is a long imageUrl
    //----------------------------------------------------------------------------------------------

    const [fileType, setFileType] = useState(null);

    useEffect(() => {

        const fetchData = async () => {
            try {
                if (isValidUrl(message.content)) {
                    const response = await fetch(message.content);
                
                    if (response.ok) { // if HTTP status is 200-299
                        // get the content type from headers
                        const contentType = response.headers.get("content-type");
                
                        if (contentType && contentType.startsWith("image/")) {
                            // it's an image
                            setFileType("image");
                        } else if (contentType && contentType.startsWith("audio/")) {
                            // it's an audio file
                            setFileType("audio");
                        } else {
                            // it's something else
                            setFileType("other");
                        }
                    } else {
                        //console.log("HTTP-Error: " + response.status);
                        setFileType("error");
                    }
                } else {
                    setFileType(null);
                }
            } catch {
                //console.log('Could not fetch data!');
        
                // If fetch failed, test if the URL is an image by loading it into an Image object
                const img = new Image();
                img.src = message.content;
        
                img.onload = function() {
                    setFileType("image");
                };
        
                img.onerror = function() {
                    setFileType("other");
                };
            }
        };

        fetchData();
    }, [message.content]);

    // Determine if a string is a valid url
    function isValidUrl(string) {
        //console.log("checking if valid url");
        try {
            new URL(string);
        } catch (_) {
            return false;  
        }
    
        return true;
    }

    // component logic
    //----------------------------------------------------------------------------------------------

    // No message means no content
    if (!message) {
        //console.log('no message provided to chatMessage!')
        return null;
    }

    let className = 'memory-chat-message-container';

    if (message.role === 'assistant') {
        className = className + ' memory-chat-message-container-ai'
    }

    function formatTime(timestamp) {
        const date = new Date(timestamp);
        return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
    }

    // Component
    //----------------------------------------------------------------------------------------------

    return (
        <div className={className}>
            <div className={'memory-chat-message-role-time-container'}>
                <div className={'memory-chat-message-role'}>{message.role || ''}</div>
                <div className={'memory-chat-message-time'}>{message.time ? formatTime(message.time) : ''}</div>
            </div>
            <div className={'memory-chat-message-content'}>
                {
                fileType === 'image' ? <img className={'memory-chat-message-image'} src={message.content} alt={''}></img> :
                fileType === 'audio' ? <audio className={'memory-chat-message-audio'} src={message.content} controls></audio> :
                message.content || ''
                }
            </div>
        </div>
    );
}