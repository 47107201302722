// Icon
import icon from './icon.svg';

// Util
import { util } from '../../../nodeMapProcessing/util.js';

const title = 'Replace';
const description = '';

// JSON template
//----------------------------------------------------------------------------------------------------

// Node JSON template
const template = {
      
    category: "control",
    name: "replace",
    type: "replace",
    position: null,

    settings: {
      defaultTextContent: 'This is a replace node. You can replace ${input 1} variables in this text.',
      textBoxDimensions: {width: 300, height: 200},
      canEditInputs: true
    },

    gui: [ { group: 'horizontal', elements: [{element: 'nodeTextArea', setting: 'defaultTextContent'}] } ],

    contents: "",

    inputs: {
      "Input 1": {
        
        name: 'input 1',
        type: 'text',
        dataType: 'string',
        requireContent: true,
        contents: null,
      }
    },

    outputs: {
      "Output 1": {
        
        name: null,
        type: 'text',
        dataType: 'string',
        contents: null,
        destinations: {},
        clearOnSend: true
      }
    }
  }

// Processing function
//----------------------------------------------------------------------------------------------------

class process {

	// Callbacks
	//--------------------------------------------------

	constructor() {

		this.onUpdateCallback =     () => { console.warn('no onUpdate callback')};
		this.onStreamCallback =     () => { console.warn('no onStream callback')};
		this.onCompleteCallback =   () => { console.warn('no onComplete callback')};
		this.onWarningCallback =    () => { console.warn('no onWarning callback')};
		this.onErrorCallback =      () => { console.warn('no onError callback')};
		this.onGiveUpCallback =     () => { console.warn('no onGiveUp callback')};

	}

	// Set callback functions
	onUpdate(callback) { this.onUpdateCallback = callback; }
	onStream(callback) { this.onStreamCallback = callback; }
	onComplete(callback) { this.onCompleteCallback = callback; }
	onWarning(callback) { this.onWarningCallback = callback; }
	onError(callback) { this.onErrorCallback = callback; }
	onGiveUp(callback) { this.onGiveUpCallback = callback; }

	// Fire functions easily
	update(node) { this.onUpdateCallback(node); }
	stream(node) { this.onStreamCallback(node); }
	finish(node) { this.onCompleteCallback(node); }
	warn(message) { this.onWarningCallback(message); }
	error(message) { this.onErrorCallback(message); }
	giveUp(message) { this.onGiveUpCallback(message); }


	// Run node
	//--------------------------------------------------

	// Node processing function
	run (node, extras) {

		try {

			// Get the defaultTextContent from the node settings
			const defaultTextContent = node.settings?.defaultTextContent || '';
	  
			// Create a mutable copy of the defaultTextContent
			let currentTextContent = defaultTextContent;
	  
			// Check if any requireContent inputs are empty
			if (util.hasEmptyRequiredInputs(node)) {
				console.log('has empty!')
				this.giveUp();
				return;
			}
	  
			// Iterate through the inputs and replace the input names with their contents in the currentTextContent
			for (const inputId in node.inputs) {
				if (node.inputs.hasOwnProperty(inputId)) {
					const input = node.inputs[inputId];
					const inputName = input.name;
					const inputContents = util.convertToString(input.contents || '');
	  
					// Replace the input name with its contents using a regular expression
					const inputNamePattern = '\\${' + inputName + '}';
					const regex = new RegExp(inputNamePattern, 'g');
					currentTextContent = currentTextContent.replace(regex, inputContents);
				}
			}
	  
			// Set the node's contents, fill outputs, finish
			util.setContents(node, currentTextContent);
			util.clearInputs(node);
			util.fillOutputs(node);
			this.finish(node);

			return;
		}

		catch (e) {
			this.error(e.message);
			this.giveUp();
			return;
		}
  	}
}

// Export
//----------------------------------------------------------------------------------------------------

export const replace = {
    template,
    process,
    icon,
    title,
    description
}