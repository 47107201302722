//modules
import { useEffect, useState, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useFetch } from '../../../utils/useFetch.js';
import { useSmoothScroll } from '../../../utils/useSmoothScroll.js';

//components
import NodeMapsListItem from './NodeMapsListItem/NodeMapsListItem';

// slices
import useSnackbar from "../../../SnackBar/useSnackBar.js";
import { resetData } from '../../../redux/slices/requestSlice.js';

//Modal
import useModal from '../../../Modal/useModal.js';
import Modal from '../../../Modal/Modal.js';

//resources
import config from '../../../config.js';
import addIconLight from './addIconLight.svg';
import addIconDark from './addIconDark.svg';

// loading animation
import { loadingSVG } from '../../../loadingSVG.js';

//syles
import './NodeMaps.css';

export default function NodeMaps() {

    // Component effects
    //----------------------------------------------------------------------------------------------

    // Init isMounted
    const isMounted = useRef(false);
    const isMountedDelete = useRef(false);

    // Init userData and userName
    let userData = useSelector((state) => state.user.user);
    let userName = userData ? userData.userName : null;

    // Init nodeMaps and demoNodeMaps
    const [nodeMaps, setNodeMaps] = useState(null);
    const [demoNodeMaps, setDemoNodeMaps] = useState(null);

    // const [expanded, setExpanded] = useState(true);
    const [nodeId, setNodeId] = useState(null);

    const navigate = useNavigate();
    const snackbar = useSnackbar();

    // Modal state
    const [isOpen, toggle] = useModal();

    // Use these state hooks instead of making useless fetches
    const [nodeMapCreated, setNodeMapCreated] = useState(false);
    const [nodeMapDeleted, setNodeMapDeleted] = useState(false);

    // Define fetch instances
    let fetchNodeMaps = useFetch();
    let createNodeMap = useFetch();
    let deleteNodeMap = useFetch();

    // Subscribe to fetched data
    let nodeMapsData = useSelector((state) => state.request.nodeMapsData);
    let demoNodeMapsData = useSelector((state) => state.request.demoNodeMapsData);
    let createNodeMapData = useSelector((state) => state.request.createNodeMapData);
    let deleteNodeMapData = useSelector((state) => state.request.deleteNodeMapData);

    const [itemId, setItemId] = useState({});

    // Initial fetches here
    useEffect(() => {

        // If no user nothing to fetch
        if (!userName) { return; }

        fetchNodeMaps('GET', 'nodeMaps/byAuthor/' + userName, { key: 'nodeMapsData' });
        fetchNodeMaps('GET', 'nodeMaps/byCategory/demo', { key: 'demoNodeMapsData' });

    }, [nodeMapCreated, nodeMapDeleted]);

    // Subscribe to nodemaps data updates
    useEffect(() => {

        if (!nodeMapsData) return;
        if (nodeMapsData === 'loading') return;

        // Can't mutate redux data
        let nodeMaps = nodeMapsData.nodeMaps;

        // If no data or empty array
        if (!nodeMaps || nodeMaps.length < 1) { return; }

        // Sort nodeMaps according to last edited
        let sortedNodeMaps = [...nodeMaps].sort((a, b) => {
            if (a.info.dateModified > b.info.dateModified) {
                return -1;
            } else if (a.info.dateModified < b.info.dateModified) {
                return 1;
            } else {
                return 0;
            }
        });

        setNodeMaps(sortedNodeMaps);

    }, [nodeMapsData]);

    // subscribe to nodemaps data updates
    useEffect(() => {

        if (!demoNodeMapsData) { return; }
        let demoNodeMaps = demoNodeMapsData.nodeMaps;

        // If no data or empty array
        if (!demoNodeMaps || demoNodeMaps.length < 1) { return; }
        setDemoNodeMaps(demoNodeMaps);

    }, [demoNodeMapsData]);

    // subscribe to createNodeMap data
    useEffect(() => {

        if (isMounted.current !== false) {

            if (!createNodeMapData) return;

            if (createNodeMapData === 'loading') return;

            // add success to payload on server
            if (createNodeMapData.message === 'Created nodeMap') {

                // //console.log('created nodemappppppppppppppppppppppppppppppp', createNodeMapData);

                // navigate user to newly created nodemap
                navigate('/sandbox/' + createNodeMapData.data);

                // display message
                snackbar('Nodemap created', 'success');
                setItemId({ id: createNodeMapData.data, type: 'created' });

                // delay for animations
                // setTimeout(() => {
                setNodeMapCreated((prevState) => !prevState);
                // }, 500);

            } else {
                snackbar(`Couldn't create a new nodemap`, 'error');
            }


        } else {
            //console.log('createNodeMap setting is mounted to true');
            isMounted.current = true;
        }

        // return () => {
        //     // Dispatch the reset action when the component unmounts
        //     dispatch(resetData({ key: 'createNodeMapData', value: null}));
        //   };

    }, [createNodeMapData]);




    // subscribe to deleteNodeMap data
    useEffect(() => {

        // //console.log('deleteNodeMapData', deleteNodeMapData);

        if (isMountedDelete.current !== false) {

            if (!deleteNodeMapData) return;

            if (deleteNodeMapData === 'loading') return;

            // add success to payload on server
            // logic for if user deletes current nodemap they're on *(url)?
            if (deleteNodeMapData.success === 'nodeMap deleted') {

                // add actual name. need to get it from server
                snackbar(`Nodemap ${nodeId} deleted`, 'success');

                // animation logic
                setItemId({ id: nodeId, type: 'deleted' });

                // delay for animation
                setTimeout(() => {
                    setNodeMapDeleted((prevState) => !prevState);
                }, 500);



            } else {
                snackbar(`Couldn't delete nodemap`, 'error');
            }

        } else {
            // //console.log('setting is mounted to true');
            isMountedDelete.current = true;
        }

    }, [deleteNodeMapData]);




    // component logic
    //----------------------------------------------------------------------------------------------

    const scrollRef = useSmoothScroll();

    // defined functions
    //----------------------------------------------------------------------------------------------

    // function toggleNodeMapsMenu() {

    //     //for some reason using a negation didn't work!

    //     if (expanded) { setExpanded(false); return; }
    //     if (!expanded) { setExpanded(true); return; }
    // }



    async function createNodeMapHandler() {

        if (!userName) {
            return snackbar('You need an account to create a nodemap', 'error');
        }

        let url = 'nodeMaps/createNodeMap';

        // for(let i = 0; i < 20; i++) {
        //     createNodeMap('POST', url, {key: 'createNodeMapData', id: i});
        // }
        // return;
        return createNodeMap('POST', url, { key: 'createNodeMapData' });
    };



    async function deleteNodeMapHandler() {

        // toggle off modal
        toggle();

        if (!userName) {
            return snackbar('You need an account to delete nodemaps', 'error');
        }

        let url = 'nodeMaps/deleteNodeMap';
        let payload = { id: nodeId };

        return deleteNodeMap('POST', url, { key: 'deleteNodeMapData' }, payload);

    };

    function updateNodeId(id) {

        // toggle modal
        toggle();

        // update node id for deletion
        setNodeId(id);
    }

    // component structure
    //----------------------------------------------------------------------------------------------
    // //console.log(nodeMaps);
    return (
        <>
            <div className={`nodemaps-container`}>  {/* collapsible from 0px to 400px*/}
                <div className={'nodemaps'}>    {/* always 400px */}
                    <button className={'create-nodemap-button'} title={'create new blank nodemap'} onClick={createNodeMapHandler}>
                        <img className={'create-nodemap-button-icon'} alt="create nodemap icon" src={addIconLight}></img>
                        <div className={'create-nodemap-button-text'}>New Blank Nodemap</div>
                    </button>

                    <div className={'nodemaps-list'} ref={scrollRef}>

                        {nodeMaps ?
                            <div className={'user-maps'}>
                                <div className={'nodemaps-section-label'}>Your Nodemaps</div>
                                {nodeMaps.map((nodeMap) => {
                                    return (
                                        <NodeMapsListItem
                                            // fadeIn={fadeIn}
                                            nodeMap={nodeMap}
                                            key={nodeMap.id}
                                            id={itemId}
                                            deleteNodeMap={updateNodeId}
                                        />
                                    )
                                })}
                            </div>
                            : null}

                        {demoNodeMaps ?
                            <div className={'demo-maps'}>
                                <div className={'nodemaps-section-label'}>Demo Nodemaps</div>
                                {demoNodeMaps.map((nodeMap) => {
                                    return (
                                        <NodeMapsListItem
                                            // fadeIn={fadeIn}
                                            nodeMap={nodeMap}
                                            key={nodeMap.id}
                                            id={itemId}
                                            deleteNodeMap={updateNodeId}
                                            demo={true}
                                        />
                                    )
                                })}
                            </div>
                            : null}

                    </div>

                </div>
            </div>

            {isOpen && <Modal isOpen={isOpen} toggle={toggle}>
                <div className="delete-node-confirmation">
                    <h2>Warning: This cannot be undone</h2>
                    <div className="button-container">
                        <button onClick={deleteNodeMapHandler} className="delete">Delete this nodeMap</button>
                        <button onClick={toggle} className="cancel">Cancel</button>
                    </div>
                </div>
            </Modal>}
        </>
    );
};