// Modules
import config from '../../../../config.js';
import { EdisonAI } from '../../../../EdisonAI/EdisonAI.js';
import icon from './icon.svg';
import { util } from '../../../../nodeMapProcessing/util.js';

const title = 'GPT-4';
const description = '';

// JSON template
//----------------------------------------------------------------------------------------------------

// Node JSON template
const template = {

	category: "language",
	name: "OpenAI GPT-4",
	type: "gpt4",
	position: null,

	settings: {
		showIcon: true,
		infoLink: 'https://platform.openai.com/docs/models/gpt-4',
		canEditInputs: true,
		modelType: 'natural-language-model',
		modelProvider: 'OpenAI',
		mode: 'chat',

		model: 'gpt-4',
		max_tokens: 150,
		temperature: 0.5,
		frequency_penalty: 0.5,
		presence_penalty: 0.5,
		stop: '',
		stream: true
	},

	stats: {
		processing: {
			meanTime: 5000
		}
	},

	gui: [
		{
			group: 'horizontal', elements: [
				{ element: 'dropdown', label: 'model', title: 'ID of the model to use', setting: 'model', options: ['gpt-4', 'gpt-4-0314', 'gpt-4-32k', 'gpt-4-32k-0314'] },
				{ element: 'dropdown', label: 'mode', title: 'Chat mode will respond with a chat message object.\nCompletion mode will respond with a string of text', setting: 'mode', options: ['chat', 'completion'] },
				{ element: 'numberbox', label: 'max tokens', title: "The maximum number of tokens to\ngenerate in the chat completion.\nThe total length of input\ntokens and generated tokens is\nlimited by the model's context length.", setting: 'max_tokens', min: 0, step: 25 }
			]
		},
		{
			group: 'vertical', elements: [
				{ element: 'slider', label: 'temperature', title: "What sampling temperature to use,\n\nbetween 0 and 2. Higher values\nlike 0.8 will make the output\nmore random, while lower values\nlike 0.2 will make it more focused\nand deterministic.\n\nWe generally recommend altering this\nor top_p, but not both.", setting: 'temperature', min: 0, max: 2, step: 0.01 },
				{ element: 'slider', label: 'frequency penalty', title: "Number between -2.0 and 2.0.\n\nPositive values penalize new tokens\njbased on their existing frequency\nin the text so far, decreasing\nthe model's likelihood to repeat\nthe same line verbatim.", setting: 'frequency_penalty', min: -2, max: 2, step: 0.01 },
				{ element: 'slider', label: 'presence penalty', title: "Number between -2.0 and 2.0.\n\nPositive values penalize new tokens\nbased on whether they appear\nin the text so far, increasing the\nmodel's likelihood to talk about\nnew topics.", setting: 'presence_penalty', min: -2, max: 2, step: 0.01 },
				{ element: 'stopSequencesTextbox', label: 'stop sequences', title: "Up to 4 sequences where the API will\nstop generating further tokens.\n\nformat: ['sequence 1', 'sequence 2', 'etc...']", setting: 'stop' }
			],
		},
		// Advanced settings
		{
			group: 'horizontal',
			elements: [
				{ element: 'toggleShowAdvanced', setting: 'showAdvanced' },
			]
		},
		{
			group: 'horizontal',
			elements: [
				{ element: 'checkbox', label: 'stream data', setting: 'stream', advanced: true },
				{ element: 'checkbox', label: 'tolerate errors', setting: 'tolerateErrors', advanced: true },
				{ element: 'numberbox', label: 'max retries', setting: 'maxRetries', min: 0, advanced: true, displayCondition: { setting: 'tolerateErrors', value: [true] } },
			]
		},
	],

	contents: "",

	inputs: {
		"Input 1": {
			name: '',
			namePlaceholder: 'role',
			dataType: ['string', 'chatMessage', 'chatMessageArray'],
			requireContent: true,
			contents: null,
		}
	},

	outputs: {
		"Output 1": {

			name: null,
			type: 'chatMessage',
			dataType: 'chatMessage',
			contents: null,
			destinations: {},
			clearOnSend: true
		}
	}
}

// Processing function
//----------------------------------------------------------------------------------------------------

class process {

	// Callbacks
	//--------------------------------------------------

	constructor() {

		this.onUpdateCallback = () => { console.warn('no onUpdate callback') };
		this.onStreamCallback = () => { console.warn('no onStream callback') };
		this.onCompleteCallback = () => { console.warn('no onComplete callback') };
		this.onWarningCallback = () => { console.warn('no onWarning callback') };
		this.onErrorCallback = () => { console.warn('no onError callback') };
		this.onGiveUpCallback = () => { console.warn('no onGiveUp callback') };

	}

	// Set callback functions
	onUpdate(callback) { this.onUpdateCallback = callback; }
	onStream(callback) { this.onStreamCallback = callback; }
	onComplete(callback) { this.onCompleteCallback = callback; }
	onWarning(callback) { this.onWarningCallback = callback; }
	onError(callback) { this.onErrorCallback = callback; }
	onGiveUp(callback) { this.onGiveUpCallback = callback; }

	// Fire functions easily
	update(node) { this.onUpdateCallback(node); }
	stream(node) { this.onStreamCallback(node); }
	finish(node) { this.onCompleteCallback(node); }
	warn(message) { this.onWarningCallback(message); }
	error(message) { this.onErrorCallback(message); }
	giveUp(message) { this.onGiveUpCallback(message); }


	// Run node
	//--------------------------------------------------

	// Node processing function
	async run(node, extras) {

		const apiToken = extras.apiToken;

		try {

			const mode = node.settings.mode;

			// Clear contents first
			node.contents = null;
			let messages = [];

			// Do nothing if node has required inputs that are empty
			const hasEmptyRequired = util.hasEmptyRequiredInputs(node);

			// Give up if required inputs not full
			if (hasEmptyRequired) {
				this.giveUp();
				return false;
			}

			// Add all inputs to messages array
			for (let inputId in node.inputs) {

				const input = node.inputs[inputId];
				const contents = input.contents;
				const name = input.name;

				// Normal chatMessages get pushed automatically
				if (contents.content) {

					let message = contents;

					if (name) { message.name = name || undefined; }
					if (name === 'user') { message.role = 'user'; }
					if (name === 'assistant') { message.role = 'assistant'; }
					if (name === 'system') { message.role = 'system'; }

					messages.push(message);
					continue;
				}

				// Strings get converted to chatMessages first
				if (typeof contents === 'string') {
					let message = { role: 'assistant', time: Date.now(), content: contents };

					if (name) { message.name = name || undefined; }
					if (name === 'user') { message.role = 'user'; }
					if (name === 'assistant') { message.role = 'assistant'; }
					if (name === 'system') { message.role = 'system'; }

					messages.push(message);
					continue;
				}

				// Arrays get special treatment
				if (Array.isArray(contents)) {
					for (let message of contents) {
						if (message.content) {

							if (name) { message.name = name; }
							if (name === 'user') { message.role = 'user'; }
							if (name === 'assistant') { message.role = 'assistant'; }
							if (name === 'system') { message.role = 'system'; }

							messages.push(message);
						}
					}

					continue;
				}
			}

			if (mode === 'chat') {

			}

			// Append system message for completion
			if (mode === 'completion') {
				messages.push({ role: 'system', content: 'IMPORTANT! please reply to any user messages by continuing the string of text chatacter-by-character' });
			}

			// Before request
			//----------------------------------------

			const model = 'gpt-4';
			const input = messages;
			const settings = {
				model: node.settings.model,
				temperature: node.settings.temperature,
				max_tokens: node.settings.max_tokens,
				presence_penalty: node.settings.presence_penalty,
				frequency_penalty: node.settings.frequency_penalty,
				stop: JSON.parse('[' + node.settings.stop + ']'),
				stream: node.settings.stream
			}

			const gpt4 = new EdisonAI();


			if (settings.stream) {

				let aggregate = '';

				gpt4.onUpdate((data) => {

					console.log('update: ', data);

					if (data.result.content) {
						aggregate += data.result.content;
					}

					util.setContents(node, aggregate + ' ❚');
					util.clearInputs(node);
					util.fillOutputs(node, 'streaming');

					this.stream(node);
					return;
				});
			}

			gpt4.onComplete((data) => {

				console.log('complete: ', data);

				util.setContents(node, data.result);
				util.clearInputs(node);
				util.fillOutputs(node);

				this.finish(node);
				return;
			});

			gpt4.onError((data) => {
				console.warn('GPT-4 ERROR: ', data.error);
				this.error(data.error);
				return;
			});

			await gpt4.run(model, input, settings, apiToken);

			return;
		}

		catch (e) {
			this.error(e.message);
			return;
		}
	}
}

// Export
//----------------------------------------------------------------------------------------------------

export const gpt4 = {
	template,
	process,
	icon,
	title,
	description
}