export const loadingSVG = (
    <svg width="20" height="20" viewBox="0 0 50 50" className="loading-svg">
      <circle cx="25" cy="25" r="20" stroke="currentColor" strokeWidth="5" fill="none" />
      <style>
        {`
          @keyframes rotate {
            100% {
              transform: rotate(360deg);
            }
          }
          .loading-svg circle {
            stroke-dasharray: 80;
            stroke-dashoffset: 0;
            transform-origin: center;
            animation: rotate 1.5s linear infinite;
          }
        `}
      </style>
    </svg>
  );