// Icon
import icon from './icon.svg';

// Util
import { util } from '../../../nodeMapProcessing/util.js';

const title = 'Combine';
const description = '';

// JSON template
//----------------------------------------------------------------------------------------------------

// Node JSON template
const template = {
      
	category: "control",
    name: "combine",
    type: "combine",
    position: null,

    settings: {
		canEditInputs: true
    },

    contents: null,

    inputs: {

      	"Input 1": {
        
			name: null,
			type: 'text',
			dataType: 'string',
			requireContent: true,
			allowStreaming: true,
			contents: null,
      	},
      
		"Input 2": {
			
			name: null,
			type: 'text',
			dataType: 'string',
			requireContent: true,
			allowStreaming: true,
			contents: null,
		}
    },

    outputs: {
		"Output 1": {
			
			name: null,
			type: 'text',
			dataType: 'string',
			contents: null,
			destinations: {},
			clearOnSend: true
		}
    }
}

// Processing function
//----------------------------------------------------------------------------------------------------

class process {

	// Callbacks
	//--------------------------------------------------

	constructor() {

		this.onUpdateCallback =     () => { console.warn('no onUpdate callback')};
		this.onStreamCallback =     () => { console.warn('no onStream callback')};
		this.onCompleteCallback =   () => { console.warn('no onComplete callback')};
		this.onWarningCallback =    () => { console.warn('no onWarning callback')};
		this.onErrorCallback =      () => { console.warn('no onError callback')};
		this.onGiveUpCallback =     () => { console.warn('no onGiveUp callback')};

	}

	// Set callback functions
	onUpdate(callback) { this.onUpdateCallback = callback; }
	onStream(callback) { this.onStreamCallback = callback; }
	onComplete(callback) { this.onCompleteCallback = callback; }
	onWarning(callback) { this.onWarningCallback = callback; }
	onError(callback) { this.onErrorCallback = callback; }
	onGiveUp(callback) { this.onGiveUpCallback = callback; }

	// Fire functions easily
	update(node) { this.onUpdateCallback(node); }
	stream(node) { this.onStreamCallback(node); }
	finish(node) { this.onCompleteCallback(node); }
	warn(message) { this.onWarningCallback(message); }
	error(message) { this.onErrorCallback(message); }
	giveUp(message) { this.onGiveUpCallback(message); }


	// Run node
	//--------------------------------------------------

	// Node processing function
	run (node, extras) {

      	try {

          	// Give up if required inputs aren't full
		    if (util.hasEmptyRequiredInputs(node)) {
				this.giveUp();
				return;
          	}

			const combinedInputs = util.concatenateInputContents(node);

			util.setContents(node, combinedInputs);
			util.clearInputs(node);
			util.fillOutputs(node);

			this.finish(node);
			return;
      	}

		catch (e) {
			this.error(e.message);
			this.giveUp();
			return;
		}
  	}
}

// Export
//----------------------------------------------------------------------------------------------------

export const combine = {
    template,
    process,
	icon,
	title,
	description
}