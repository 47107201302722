import config from '../config.js';

class EdisonAI {
    
    constructor() {

        this.onUpdateCallback = null;
        this.onCompleteCallback = null;
        this.onErrorCallback = null;

        this.staticFileUploadUrl = 'staticUrl/upload'
        this.urlBase = config.apiUrl;

        this.models = {

            // Open AI

            'gpt-3': {
                url: 'models/text/OpenAI/gpt3/prompt',
            },

            'gpt-3.5': {
                url: 'models/text/OpenAI/gpt3_5/prompt',
            },

            'gpt-4': {
                url: 'models/text/OpenAI/gpt4/prompt',
            },

            'dall-e-2': {
                url: 'models/image/OpenAI/Dall-E-2/generate',
            },

            // Replicate

            'vicuna-13b': {
                url: 'models/text/replicate/vicuna-13b/prompt',
            },

            'stable-diffusion': {
                url: 'models/image/replicate/stable-diffusion/generate',
            },

            'blip': {
                url: 'models/image/replicate/blip/describe',
                needsFile: true,
            },

            // Eleven labs

            'prime-voice': {
                url: 'models/audio/elevenLabs/primeVoice/generate'
            }
        }
    }

    // Function for fetching continuously
    async fetchStream(url, options) {

        // Make request
        const response = await fetch(url, options);
        if (!response.ok) throw new Error(response.status);
        const reader = response.body.getReader();

        // Read the stream
        while (true) {

            // Get value and determine if request is done
            const { done, value } = await reader.read();
            if (done) break;

            // Decode chunk and separate into descrete objects
            const str = new TextDecoder("utf-8").decode(value);
            const objects = this.convertToObjects(str);

            // Loop through objects
            for (let item of objects) {

                // <u> update </u>
                if (item.status === 'update') {
                    if (this.onUpdateCallback) { this.onUpdateCallback(item.data); }
                    continue;
                }

                // <c> complete </c>
                if (item.status === 'complete') {
                    if (this.onCompleteCallback) { this.onCompleteCallback(item.data); }
                    continue;
                }

                // <e> error </e>
                if (item.status === 'error') {
                    if (this.onErrorCallback) { this.onErrorCallback(item.data); }
                    continue;
                }
            }
        }

        // Finish the request
        reader.releaseLock();
    }

    // Send a request to the server to run the model
    async run(model, input, settings, apiToken) {

        try {

            console.log('EdisonAI: running...');

            // Get static url for input if neccecary
            //------------------------------------------------------------

            if (this.models[model].needsFile) {

                console.log('Model needs file! Getting static url...')

                // Attempt to get static url for file
                const file = await this.getFile(input);
                const staticUrl = await this.getStaticUrl(file, apiToken);

                input = staticUrl;
            }


            // Start request
            //------------------------------------------------------------

            console.log('Model: ', model);
            console.log('Input: ', input);
            console.log('Settings: ', settings);
            console.log('Api token: ', apiToken);

            const url = this.urlBase + this.models[model].url;

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': apiToken
                },
                body: JSON.stringify({ model, input, settings })
            };

            await this.fetchStream(url, options);
        }

        catch (e) {
            if (this.onErrorCallback) {
                this.onErrorCallback({error: e.message});
            }
        }
    }

    // Function that will be called when streamed data is recieved
    onUpdate(callback) {
        this.onUpdateCallback = callback;
    }

    // Function that will be called when the stream ends
    onComplete(callback) {
        this.onCompleteCallback = callback;
    }

    onError(callback) {
        console.log('setting callback')
        this.onErrorCallback = callback;
    }

    // Helper functions
    //--------------------------------------------------

    convertToObjects(inputString) {

        // Define regular expressions for update and complete
        const updateRegex = /<u>(.*?)<\/u>/g;
        const completeRegex = /<c>(.*?)<\/c>/g;
        const errorRegex = /<e>(.*?)<\/e>/g;

        const result = [];

        // Extract update data
        let updateMatches;
        while ((updateMatches = updateRegex.exec(inputString)) !== null) {
            try {
                let data = JSON.parse(updateMatches[1]);
                result.push({
                    status: 'update',
                    data: data
                });
            } catch (e) {
                console.error('Error parsing JSON: ', e);
            }
        }

        // Extract complete data
        let completeMatches;
        while ((completeMatches = completeRegex.exec(inputString)) !== null) {
            try {
                let data = JSON.parse(completeMatches[1]);
                result.push({
                    status: 'complete',
                    data: data
                });
            } catch (e) {
                console.error('Error parsing JSON: ', e);
            }
        }

        // Extract update data
        let errorMatches;
        while ((errorMatches = errorRegex.exec(inputString)) !== null) {
            try {
                let data = JSON.parse(errorMatches[1]);
                result.push({
                    status: 'error',
                    data: data
                });
            } catch (e) {
                console.error('Error parsing JSON: ', e);
            }
        }

        return result;
    }

    // Utility functions
    //------------------------------------------------------------

    // Determine if an object is a file (url, blob, blob url, b64, etc...)
    async isFile (object) {
        
        try {

            // Check if object is a File or Blob
            if (object instanceof File || object instanceof Blob) { return true; }
    
            // If the object is a string, check if it's a URL or base64 string
            if (typeof object === "string") {
                
                // Check if it's a base64 string
                if (/^(data:[a-z-]+\/[a-z-]+;base64,)?[a-z0-9+/]+={0,2}$/i.test(object)) { return true; }
                
                // Try to construct a URL. If it fails, it's not a valid URL
                try { new URL(object); } catch (e) { return false; }
    
                // Try to make a HEAD request, falling back to a GET request if it fails
                let response;
                try {
                    response = await fetch(object, { method: "HEAD" });
                } catch (e) {
                    response = await fetch(object, { method: "GET" });
                }

                const contentType = response.headers.get('content-type');
    
                // If the content type suggests a file, return true
                if (contentType.includes('application') || 
                    contentType.includes('image') || 
                    contentType.includes('audio') || 
                    contentType.includes('video') || 
                    contentType.includes('text') || 
                    contentType.includes('json')) {
                    return true;
                }
            }
    
            // If none of the above conditions were met, return false
            return false;
        }

        // In case of any error, return false
        catch (e) {
            return false;
        }
    }

    // Get a file from a file, url, blob, b64, etc...
    async getFile(input) {

        if (input instanceof File) {
            // If input is already a file, just return it
            return input;
        }
    
        if (input instanceof Blob) {
            // If input is a Blob, convert it to a File
            return new File([input], "file");
        }
    
        if (typeof input === "string") {
            try {
                // Check if input is a Base64 string
                const base64Match = input.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,(.*)/);
                if (base64Match) {
                    const contentType = base64Match[1];
                    const base64Data = base64Match[2];
                    const blob = await (await fetch(`data:${contentType};base64,${base64Data}`)).blob();
                    return new File([blob], "file");
                }
    
                // Check if input is a URL
                new URL(input);
                const response = await fetch(input);
                const blob = await response.blob();
                return new File([blob], "file");
            } catch (e) {
                // Not a Base64 string or a URL
                throw new Error("Input string must be a valid URL or a Base64 string");
            }
        }
    
        throw new Error("Unsupported input type. Input must be a File, Blob, URL string, Blob URL string, or Base64 string");
    }

    // Get a static url for a file
    async getStaticUrl (file, apiToken) {

        console.log('getting static url!');
        console.log(file);

        try {

            // Create form data
            let formData = new FormData();
            formData.append('file', file);
            formData.append('lifeTime', 300000); //lifeTime of 5 minutes

            const url = this.urlBase + this.staticFileUploadUrl;

            // Send request
            const response = await fetch(url, {
                method: 'POST',
                headers: {'apiToken': apiToken },
                body: formData
            });

            // Get file name and build static url
            const responseJson = await response.json();

            // Sometimes there is a serverside error
            if (responseJson.error) {
                throw new Error(responseJson.error);
            }

            const staticUrl = responseJson.staticUrl;

            return staticUrl;
        }

        catch (e) {
            throw new Error(e);
        }
    }

}

export { EdisonAI };