// Modules
import { useState, useEffect } from 'react';

// Components
import AudioPlayer from '../../IoVariableField/IoVariableFieldComponents/AudioPlayer/AudioPlayer';

export default function ChatMessage({message}) {

    // Determine if chatMessage is a long imageUrl
    //----------------------------------------------------------------------------------------------

    const [fileType, setFileType] = useState(null);

    useEffect(() => {

        const fetchData = async () => {
            try {
                if (isValidUrl(message.content)) {

                    try {
                        const response = await fetch(message.content);
                
                        if (response.ok) { // if HTTP status is 200-299
                            // get the content type from headers
                            const contentType = response.headers.get("content-type");
                    
                            if (contentType && contentType.startsWith("image/")) {
                                // it's an image
                                setFileType("image");
                            } else if (contentType && contentType.startsWith("audio/")) {
                                // it's an audio file
                                setFileType("audio");
                            } else {
                                // it's something else
                                setFileType("other");
                            }
                        } else {
                            //console.log("HTTP-Error: " + response.status);
                            setFileType("error");
                        }
                    } catch {
                        return;
                    }
                } else {
                    setFileType(null);
                }
                
            } catch {
                //console.log('Could not fetch data!');
        
                // If fetch failed, test if the URL is an image by loading it into an Image object
                const img = new Image();
                img.src = message.content;
        
                img.onload = function() {
                    setFileType("image");
                };
        
                img.onerror = function() {
                    setFileType(null);
                };
            }
        };

        fetchData();
    }, [message.content]);

    // Determine if a string is a valid url
    function isValidUrl(string) {
        //console.log("checking if valid url");
        try {
            new URL(string);
        } catch (_) {
            return false;  
        }

        console.log(string)
    
        return true;
    }

    // component logic
    //----------------------------------------------------------------------------------------------

    // No message means no content
    if (!message) {
        //console.log('no message provided to chatMessage!')
        return null;
    }

    let className = 'chat-message-container';

    if (message.role === 'assistant') {
        className = className + ' chat-message-container-ai'
    }

    function formatTime(timestamp) {
        const date = new Date(timestamp);
        return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
    }

    // Component
    //----------------------------------------------------------------------------------------------

    return (
        <div className={className}>
            <div className={'chat-message-name-time-container'}>
                <div className={'chat-message-name'}>{message.name || message.role || 'message'}</div>
                <div className={'chat-message-time'}>{message.time ? formatTime(message.time) : ''}</div>
            </div>
            <div className={'chat-message-content'}>

                { fileType === 'image' ? <img className={'chat-message-image'} src={message.content} alt={''}></img> : null }

                { fileType === 'audio' ? <AudioPlayer audioUrl={message.content}/> : null }

                { !fileType ? message.content.toString() || '' : null }

            </div>
        </div>
    );
}