// Icon
import icon from './icon.svg';

// Util
import { util } from '../../../nodeMapProcessing/util.js';

const title = 'Text';
const description = '';

// Node JSON template
const template = {

	category: "control",
	name: "text",
	type: "text",
	position: null,
	contents: "This is a text node - edit the prompt at your leisure.",

	settings: {
		defaultTextContent: "This is a text node - edit the prompt at your leisure.",
		textBoxDimensions: {width: 275, height: 115}
	},

	gui: [
		{
			group: 'horizontal',
			elements: [
				{ element: 'nodeTextArea', setting: 'defaultTextContent' }
			]
		}
	],

	outputs: {
		"Output 1": {

			name: null,
			type: 'text',
			dataType: 'string',
			contents: null,
			destinations: {}
		}
	}
}

// Processing function
//----------------------------------------------------------------------------------------------------

class process {

	// Callbacks
	//--------------------------------------------------

	constructor() {

		this.onUpdateCallback = () => { console.warn('no onUpdate callback') };
		this.onStreamCallback = () => { console.warn('no onStream callback') };
		this.onCompleteCallback = () => { console.warn('no onComplete callback') };
		this.onWarningCallback = () => { console.warn('no onWarning callback') };
		this.onErrorCallback = () => { console.warn('no onError callback') };
		this.onGiveUpCallback = () => { console.warn('no onGiveUp callback') };

	}

	// Set callback functions
	onUpdate(callback) { this.onUpdateCallback = callback; }
	onStream(callback) { this.onStreamCallback = callback; }
	onComplete(callback) { this.onCompleteCallback = callback; }
	onWarning(callback) { this.onWarningCallback = callback; }
	onError(callback) { this.onErrorCallback = callback; }
	onGiveUp(callback) { this.onGiveUpCallback = callback; }

	// Fire functions easily
	update(node) { this.onUpdateCallback(node); }
	stream(node) { this.onStreamCallback(node); }
	finish(node) { this.onCompleteCallback(node); }
	warn(message) { this.onWarningCallback(message); }
	error(message) { this.onErrorCallback(message); }
	giveUp(message) { this.onGiveUpCallback(message); }


	// Run node
	//--------------------------------------------------

	// Node processing function
	run(node, extras) {

		try {

			// Get the defaultTextContent from the node settings
			const defaultTextContent = node.settings?.defaultTextContent || '';

			// Set the node's contents to the defaultTextContent
			util.setContents(node, defaultTextContent);
			util.clearInputs(node);
			util.fillOutputs(node);
			this.finish(node);

			return;
		}

		catch (e) {
			this.error(e.message);
			this.giveUp();
			return;
		}
	}
}

export const text = {
	template,
	process,
	icon,
	title,
	description
}