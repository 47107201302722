// Modules
import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateNodeSetting } from '../../../../../../../redux/slices/nodeMapSlice.js';

// Styles
import './Slider.css';

export default function Slider({nodeId, element, value}) {

    // Define important variables

    const setting = element.setting;
    const label = element.label;
    const title = element.title;
    const options = element.options;
    const min = element.min;
    const max = element.max;
    const step = element.step;

    // Define dispatch function
    const dispatch = useDispatch();
    
    // Updating node setting
    //----------------------------------------------------------------------------------------------------

    function handleChangeNodeSetting(e) {

        const newValue = e.currentTarget.value;
        dispatch(updateNodeSetting({nodeId, setting, newValue}));
    }

    // Component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={'slider-container'}>

            <label className={'node-setting-label'}>{label + ': ' + (Math.round(value * 100) / 100).toFixed(2)}</label>

            <input

                type={'range'}

                className={''}
                title={title || label || setting || ''}
                
                value={value}
                min={min || ''}
                max={max || ''}
                step={step || ''}

                onChange={handleChangeNodeSetting}
            ></input>
        </div>
    );
}