import { useDispatch } from 'react-redux';
import { showSnackbar } from '../redux/slices/snackBarSlice.js';

const useSnackbar = () => {
  const dispatch = useDispatch();

  const snackbar = (message, type, custom = null) => {
    const snackBarPayload = {
      message,
      type,
    };

    for (const key in custom) {
       
      if(custom[key] !== null) {
        snackBarPayload[key] = custom[key];
      }
    }

    dispatch(showSnackbar(snackBarPayload));
  };

  return snackbar;
};

export default useSnackbar;