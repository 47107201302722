import { useEffect, useState } from 'react';
import { useNavigate, useParams} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateNodeMap, resetSlice } from '../redux/slices/nodeMapSlice.js';

import TopBar from './TopBar/TopBar.js';
import Menu from './Menu/Menu.js';
import Sandbox from './Sandbox/Sandbox.js';
import IoPanel from './IoPanel/IoPanel.js';

import { useFetch } from '../utils/useFetch.js';
// import { useFetchWrapper } from '../utils/useFetchWrapper.js';
//import { fetchWrapper } from '../utils/fetchWrapper.js';

import './FusionEditor.css';

export default function FusionEditor() {
    

    const navigate = useNavigate();
    const dispatch = useDispatch();
    

    // get id on every routing change
    const { nodeMapId } = useParams();
    //console.log('Fusion nodeMapId: ', nodeMapId);


    // Define fetch instances
    let fetchNodeMap = useFetch();

    // Subscribe to fetched data
    let nodeMapData = useSelector((state) => state.request.nodeMapData);

    //console.log('Fusion nodeMapData: ', nodeMapData);

    useEffect(() => {

        //console.log('nodemap id changed!');

        dispatch(resetSlice())

        if (!nodeMapId) {
            return; 
        }

        let payload = { id: nodeMapId };
        fetchNodeMap('POST', 'nodeMaps', {key: 'nodeMapData'}, payload);

    }, [nodeMapId]);


    // subscribe to nodeMapData changes
    useEffect(() => {

        //console.log('Fusion nodeMapData: ', nodeMapData);

        if(!nodeMapData) return;

        if (nodeMapData.error) {
            navigate('/sandbox/');
            return;
        }

        if(nodeMapData.success) {
            let nodeMap = nodeMapData.nodeMap;
        
            // update current nodemap
            dispatch(updateNodeMap({nodeMap}));
            return;
        }

    }, [nodeMapData]);

    
    

    return (
        <>
        <div id="tooltip-portal"></div>
        <TopBar />
        <div className={'menu-and-sandbox-container'}>
            <Menu />
            <Sandbox />
            <IoPanel />
        </div> 
        </>
    );
};