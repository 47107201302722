// Modules
import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateNodeSetting } from '../../../../../../../redux/slices/nodeMapSlice.js';

// Styles
import './TextArea.css';

export default function TextArea({nodeId, element, value, dimensions}) {

    // Define important variables

    const setting = element.setting;
    const label = element.label;
    const title = element.title;
    const minLength = element.minLength;
    const maxLength = element.maxLength;

    // Correct empty value (causes issues)
    if (!value) {
        value = '';
    }

    // Define dispatch function
    const dispatch = useDispatch();

    // Clear text content
    //----------------------------------------------------------------------------------------------------

    function handleClearNodeTextContent(e) {
        const newValue = '';
        dispatch(updateNodeSetting({nodeId, setting, newValue}));
    } 

    // Defined functions
    //----------------------------------------------------------------------------------------------------

    function handleChangeNodeSetting(e) {
        const newValue = e.currentTarget.value;
        dispatch(updateNodeSetting({nodeId, setting, newValue}));
    }

    function handleResize(e) {

        const textBoxDimensions = {
            width: e.currentTarget.clientWidth,
            height: e.currentTarget.clientHeight
        }

        dispatch(updateNodeSetting({nodeId, setting: 'textBoxDimensions', newValue: textBoxDimensions}));
    }

    //force redraw connections when dragging/resizing
    function forceRedrawConnections(e) {
        if (e.button === 0) {
            const redrawConnectionsEvent = new CustomEvent('redrawConnections');
            window.dispatchEvent(redrawConnectionsEvent);
        }
    }

    // Component
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={'node-setting-container'}>

            <div className={'io-variable-clear-button'} style={{marginLeft: 'auto', marginBottom: '5px'}} onClick={handleClearNodeTextContent}>clear</div>

            <textarea
                style={{width: dimensions?.width+'px' || 'auto', height: dimensions?.height+'px' || 'auto'}}
                className={'textarea'}
                title={title || label || setting || ''}
                value={value}
                onChange={handleChangeNodeSetting}
                onClick={handleResize}
                onMouseMove={forceRedrawConnections}
            ></textarea>

        </div>
    );
}