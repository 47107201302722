// Modules
import config from '../../../../config.js';
import icon from './icon.png';
import { util } from '../../../../nodeMapProcessing/util.js';
import { EdisonAI } from '../../../../EdisonAI/EdisonAI.js';

const title = 'Stable Diffusion';
const description = '';

// JSON template
//----------------------------------------------------------------------------------------------------

// Node JSON template
const template = {
      
    category: "image",
    name: "Stable Diffusion",
    type: "stableDiffusion",
    position: null,

    settings: {
      showIcon: true,
      infoLink: 'https://replicate.com/stability-ai/stable-diffusion',

      canEditInputs: false,

      modelType: 'image-generation-model',
      modelProvider: 'StabilityAI',
      modelName: 'stable-diffusion',
      defaultImage: null,
      
      image_dimentions: '512x512',
      negative_prompt: null,
      num_outputs: 1,
      num_inference_steps: 50,
      guidance_scale: 7.5,
      scheduler: 'DPMSolverMultistep',
      seed: null,
    },

    stats: {
      processing: {
        meanTime: 5000
      }
    },

    gui: [
      {
        group: 'horizontal',
        elements: [
            { element: 'numberbox', label: 'number', setting: 'num_outputs', min: 0, step: 1},
            { element: 'dropdown', label: 'size', setting: 'image_dimensions', options: ['512x512', '768x768']},
            { element: 'dropdown', label: 'format', setting: 'responseFormat', options: ['url','b64_json']},
        ]
      },
      {
        group: 'horizontal',
        elements: [
          { element: 'imageDisplay', setting: 'defaultImage' },
        ]
      },
      // Advanced settings
      {
        group: 'horizontal',
        elements: [
          { element: 'toggleShowAdvanced', setting: 'showAdvanced' },
        ]
      },
      {
        group: 'horizontal',
        elements: [
            { element: 'textbox', label: 'negative prompt', setting: 'negative_prompt', advanced: true},
            { element: 'numberbox', label: 'seed', setting: 'seed', advanced: true},
        ]
      },
      {
        group: 'vertical',
        elements: [
            { element: 'slider', label: 'inference steps', setting: 'num_inference_steps', min: 1, max: 100, step: 1, advanced: true},
            { element: 'slider', label: 'guidance scale', setting: 'guidance_scale', min: 1, max: 20, step: 0.01, advanced: true},
        ]
      },
      {
        group: 'horizontal',
        elements: [
            { element: 'dropdown', label: 'scheduler', setting: 'scheduler', options: ['DDIM','K_EULER','DPMSolverMultistep','K_EULER_ANCESTRAL','PNDM','KLMS'], advanced: true},
        ]
      },
      {
        group: 'horizontal',
        elements: [
          { element: 'checkbox', label: 'tolerate errors', setting: 'tolerateErrors', advanced: true },
          { element: 'numberbox', label: 'max retries', setting: 'maxRetries', min: 0, advanced: true, displayCondition: {setting: 'tolerateErrors', value: [true]} },
        ]
      },
      
    ],

    contents: "",

    inputs: {
      "prompt": {
        
        name: 'prompt',
        type: 'text',
        dataType: 'string',
        requireContent: true,
        contents: null,
      },

      "negative prompt": {
        
        name: 'negPrompt',
        type: 'text',
        dataType: 'string',
        requireContent: true,
        contents: null,
      }
    },

    outputs: {
      "image": {
        
        name: 'image',
        type: 'image',
        dataType: 'imageUrl',
        contents: null,
        destinations: {},
        clearOnSend: true
      }
    }
  }

// Processing function
//----------------------------------------------------------------------------------------------------

class process {

	// Callbacks
	//--------------------------------------------------

	constructor() {

		this.onUpdateCallback =     () => { console.warn('no onUpdate callback')};
		this.onStreamCallback =     () => { console.warn('no onStream callback')};
		this.onCompleteCallback =   () => { console.warn('no onComplete callback')};
		this.onWarningCallback =    () => { console.warn('no onWarning callback')};
		this.onErrorCallback =      () => { console.warn('no onError callback')};
		this.onGiveUpCallback =     () => { console.warn('no onGiveUp callback')};

	}

	// Set callback functions
	onUpdate(callback) { this.onUpdateCallback = callback; }
	onStream(callback) { this.onStreamCallback = callback; }
	onComplete(callback) { this.onCompleteCallback = callback; }
	onWarning(callback) { this.onWarningCallback = callback; }
	onError(callback) { this.onErrorCallback = callback; }
	onGiveUp(callback) { this.onGiveUpCallback = callback; }

	// Fire functions easily
	update(node) { this.onUpdateCallback(node); }
	stream(node) { this.onStreamCallback(node); }
	finish(node) { this.onCompleteCallback(node); }
	warn(message) { this.onWarningCallback(message); }
	error(message) { this.onErrorCallback(message); }
	giveUp(message) { this.onGiveUpCallback(message); }


  	// Run node
  	//--------------------------------------------------

	// Node processing function
	async run (node, extras) {

		const apiToken = extras.apiToken;

		try {

			if (!node.inputs['prompt'].contents) {
				this.warn('prompt input is empty');
				this.giveUp();
				return;
			}

			// Convert prompt input to string
			node.inputs['prompt'].contents = util.convertToString(node.inputs['prompt'].contents);

			// Set input and construct settings
			const input = util.convertToString(node.inputs['prompt'].contents);
			const settings = {
				image_dimensions: node.settings.image_dimensions || '512x512',
				num_outputs: parseInt(node.settings.num_outputs) || 1,
				num_inference_steps: parseInt(node.settings.num_inference_steps) || 50,
				guidance_scale: parseFloat(node.settings.guidance_scale) || 7.5,
				scheduler: node.settings.scheduler || 'DPMSolverMultistep',
				seed: parseInt(node.settings.seed) || undefined
			}

			const stableDiffusion = new EdisonAI();

			stableDiffusion.onComplete((data) => {

				console.log('complete: ', data);

				const result = data.result;

				util.setContents(node, result);
				util.clearInputs(node);
				util.fillOutputs(node);
				this.finish(node);
				
				return;
			});

			stableDiffusion.onError((data) => {
				console.warn('STABLE DIFFUSION ERROR: ', data.error);
				this.error(data.error);
				return;
			});

			stableDiffusion.run('stable-diffusion', input, settings, apiToken);
		}

		catch (e) {
			this.error(e.message);
			return;
		}
  	}
}

// Export
//----------------------------------------------------------------------------------------------------

export const stableDiffusion = {
    template,
    process,
    icon,
    title,
    description
}