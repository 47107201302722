// Modules
import { useSmoothScroll } from '../../../utils/useSmoothScroll.js';

// Components
import NodeMapInfo from './NodeMapInfo/NodeMapInfo';
import NodeMapVariables from './NodeMapVariables/NodeMapVariables';
import ControlNodes from './ControlNodes/ControlNodes';
import LanguageModelNodes from './LanguageModelNodes/LanguageModelNodes';
import ImageModelNodes from './ImageModelNodes/ImageModelNodes';
import AudioModelNodes from './AudioModelNodes/AudioModelNodes';

// Resources
import hamburgerLight from './hamburgerLight.svg';
import hamburgerDark from './hamburgerDark.svg';

//styles
import './EditMenu.css';

export default function EditMenu() {

    // Smooth Scrolling
    //----------------------------------------------------------------------------------------------

    const scrollRef = useSmoothScroll();

    // Component
    //----------------------------------------------------------------------------------------------

    return (
        <div className={'edit-menu-container'}>
            <div className={'edit-menu'}>
                <div className={'scroll-container'}>
                    <div className={'scroll-content'} ref={scrollRef}>
                        <NodeMapInfo />
                        <NodeMapVariables />
                        <ControlNodes />
                        <LanguageModelNodes />
                        <ImageModelNodes />
                        <AudioModelNodes />
                    </div>
                </div>
            </div>
        </div>
    );
}