import { useState } from "react";

// custom hooks
import useSnackbar from "../../SnackBar/useSnackBar.js";

import { loadingSVG } from '../../loadingSVG.js';

import config from '../../config.js';
let api = config.apiUrl;


export const NewEmailToken = () => {

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailStatus, setEmailStatus] = useState(false);
   
    const snackbar = useSnackbar();


    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!email) {
            return;
        }

        setLoading(true);
    
        let url = api + 'users/generateConfirmationEmail/';
    
        try {

            let request = await fetch(url, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({email}),
                });
    
            let response = await request.json();
            //console.log('response', response);

            setLoading(false);
    
            if(response.success) {
                snackbar('New confirmation email sent', 'success');
                setEmailStatus(true);
    
            } else {
                snackbar(response.message, 'error');
            }

        } catch(err) {
        setLoading(false);
        snackbar(err.message, 'error');
        }

    };
      

   

    return (
        <div className="app-root">
             <div className="signIn-container">

             { emailStatus && <p>Please follow the instructions in your email</p> }
             { !emailStatus && <div className="auth-form-container">
                <h2 className="auth-title">New Confirmation Email</h2>
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="input-container">
                        <label htmlFor="email">email</label>
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            minLength="4"
                            placeholder="youremail@gmail.com"
                            id="email"
                            name="email"
                            requireContent // Add this attribute for validation
                        />
                    </div>
                    <button
                        className="auth-button"
                        type="submit"
                        disabled={!email}
                    >
                       {loading ? loadingSVG : 'Send'}
                    </button>
                </form>
            </div> }
            </div>
        </div>
    );

};