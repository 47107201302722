import { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateNodeSetting } from '../../../../../../redux/slices/nodeMapSlice.js';

export default function MaxTokensTextbox({nodeId, setting, value, label}) {

    const dispatch = useDispatch();

    // component logic
    //----------------------------------------------------------------------------------------------------

    const inputNameRef = useRef(null);

    useEffect(() => {
        const input = inputNameRef.current;
        if (!input) { return; }
        input.style.width = input.value.length + 2 + 'ch';
    });

    
    // defined functions
    //----------------------------------------------------------------------------------------------------

    function handleChangeNodeSetting(e) {

        let stringValue = e.currentTarget.value;

        //manually constrain both length and value
        if (stringValue.length > 6) {
            stringValue = stringValue.slice(0, 6);
        }

        //replace 'e' in number inputs
        stringValue = stringValue.replace('e','');

        const newValue = parseInt(stringValue);

        dispatch(updateNodeSetting({nodeId, setting, newValue}));
    }

    // component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={'node-setting-container'}>

            <label className={'node-setting-label'}>{label}</label>

            <input
                type={'number'}
                min={'0'}

                className={"max-tokens-textbox"}
                title="Maximum number of tokens to use"
                value={value}

                onChange={handleChangeNodeSetting}
            ></input>

        </div>
    );
}