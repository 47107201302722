//modules
import { useSelector, useDispatch } from 'react-redux';
import { addInputVariable } from '../../../../../redux/slices/nodeMapSlice.js';

//components
import InputVariable from './InputVariable/InputVariable'

//resources
import plusIconLight from './plusIconLight.svg';
import plusIconDark from './plusIconDark.svg';

//styles
import './InputVariables.css';

export default function InputVariables(props) {
    //restore: //console.log('props', props);

    const nodeMap = props.nodeMap;
    
    const dispatch = useDispatch();

    let inputVariables = useSelector(state => state?.nodeMap?.nodeMap?.io?.inputVariables);

    if (!inputVariables) { inputVariables = {}; }

    // defined functions
    //----------------------------------------------------------------------------------------------

    function handleAddInputVariable(e) {
        dispatch(addInputVariable());
    }

    // component structure
    //----------------------------------------------------------------------------------------------

    return (
        <div className={'input-variables-container'}>

            <div className={'input-variables-section-title'}>Input Variables</div>

            <div className={'input-variables'}>
                { Object.keys(inputVariables).map((key, index) => { return <InputVariable inputVariable={inputVariables[key]} key={index}/> })}
            </div>

            <div className={'add-input-variable-button'} onClick={handleAddInputVariable}>
                <img className={'add-input-variable-button-icon'} src={plusIconDark}></img>
                <div className={'add-input-variable-button-text'}>Add Input Variable</div>
            </div>

        </div>
    );
}