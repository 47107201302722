//modules
import {React, useState, useRef} from 'react';
import ReactDOM from 'react-dom';

//resources
import questionDark from './questionDark.svg';
import questionLight from './questionLight.svg';

//styles
import './ToolTipComponent.css';

const tooltips = {
    'maxRecursion': 'Set the maximum level of recursion a nodemap can have before halting.',
    'flowSpeed': 'Set the speed at which information is sent from node to node. This can allow you to clearly see how your nodemap works.',
    'nodeMapVariables': 'Define the variables your nodemap recieves as input. This will be reflected in the input/output panel.',
    'controlNodes': 'Use control nodes to supply text or control the flow of information in your nodemap.',
    'languageModelNodes': 'Natural Langugage Models can be generative (continually predicting the next word), or fine-tuned for chat. Some are expensive, some are cheap - some are wise, some are stupid. Its up to you to choose the best one for the job'
}

export default function ToolTipComponent ({ children, text, tooltip }) {

    // Display State
    //----------------------------------------------------------------------------------------------------

    const [display, setDisplay] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const hoverIconRef = useRef(null);

    let displayTimeout = null;

    function handleMouseEnter(e) {
        const hoverIconRect = hoverIconRef.current.getBoundingClientRect();
        
        const offsetX = 10; // Horizontal offset value (in pixels)
        const offsetY = 10; // Vertical offset value (in pixels)
        
        let tooltipTop = hoverIconRect.top + window.pageYOffset - offsetY;
        let tooltipLeft = hoverIconRect.right + window.pageXOffset + offsetX;
        
        // Check if the tooltip goes beyond the right edge of the viewport
        const viewportWidth = window.innerWidth;
        if (tooltipLeft + 200 > viewportWidth) {
            tooltipLeft = viewportWidth - 200 - offsetX;
        }
        
        // Check if the tooltip goes beyond the top edge of the viewport
        if (tooltipTop < 0) {
            tooltipTop = offsetY;
        }
        
        const tooltipPosition = {
            top: tooltipTop,
            left: tooltipLeft,
        };
        
        displayTimeout = setTimeout(() => {
            setTooltipPosition(tooltipPosition);
            setDisplay(true);
        }, 500);
    }
    
    function handleMouseLeave(e) {
        //console.log('mouse leave!');
        clearTimeout(displayTimeout);
        setDisplay(false);
    }

    let tooltipText = text || tooltips[tooltip];

    // Component
    //----------------------------------------------------------------------------------------------------

    const tooltipContent = (
        <div
          className={`tooltip-content ${display ? 'visible' : ''}`}
          style={{ top: tooltipPosition.top, left: tooltipPosition.left }}
        >
          <div className={'tooltip-text'}>{tooltipText}</div>
        </div>
      );

    try {
      return (

        <div className={'tooltip'} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <img
                ref={hoverIconRef}
                className={'tooltip-hover-icon'}
                src={questionDark}
                alt={''}
            ></img>
            {ReactDOM.createPortal(tooltipContent, document.getElementById('tooltip-portal'))}
        </div>
      );
    }
    catch {
        return (

            <div className={'tooltip'} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <img
                    ref={hoverIconRef}
                    className={'tooltip-hover-icon'}
                    src={questionDark}
                    alt={''}
                ></img>
            </div>
          );
    }
    
};