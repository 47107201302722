// Modules
import React from 'react';

// Elements
import ToggleShowAdvanced from './SettingElements/ToggleShowAdvanced/ToggleShowAdvanced.js';
import NumberBox from './SettingElements/NumberBox/NumberBox.js';
import TextBox from './SettingElements/TextBox/TextBox.js';
import DropDown from './SettingElements/DropDown/DropDown.js';
import Slider from './SettingElements/Slider/Slider.js';
import TextArea from './SettingElements/TextArea/TextArea.js';
import ChatMessages from './SettingElements/ChatMessages/ChatMessages.js';
import CheckBox from './SettingElements/CheckBox/CheckBox.js';
import LogViewer from './SettingElements/LogViewer/LogViewer.js';

// Legacy Elements
import InputVariableSelect from './NodeMiddleComponents/InputVariableSelect';
import OutputVariableSelect from './NodeMiddleComponents/OutputVariableSelect';
import TextContentBox from './NodeMiddleComponents/TextContentBox';
import ModelSelect from './NodeMiddleComponents/ModelSelect';
import MaxTokensTextbox from './NodeMiddleComponents/MaxTokensTextbox';
import NumberInputTextbox from './NodeMiddleComponents/MaxTokensTextbox';
import ModelParamSlider from './NodeMiddleComponents/ModelParamSlider';
import StopSequencesTextbox from './NodeMiddleComponents/StopSequencesTextbox';
import SettingCheckbox from './NodeMiddleComponents/SettingCheckbox';
import CustomCodeBox from './NodeMiddleComponents/CustomCodeBox'
import SelectDropdown from './NodeMiddleComponents/SelectDropdown';
import ImageDisplay from './NodeMiddleComponents/ImageDisplay';

export default function NodeMiddle({nodeId, node, type, settings}) {

    // component logic
    //----------------------------------------------------------------------------------------------------

    //no node means do not render
    if (!settings){
        return null;
    }

    let nodeMiddleClassName = 'node-middle';

    //if it's a language model node
    if (node.settings?.modelType === 'natural-language-model') {
        nodeMiddleClassName = nodeMiddleClassName + ' natural-language-model-middle';
    }

    //if it's a language model node
    if (node.type === 'memory') {
        nodeMiddleClassName = nodeMiddleClassName + ' memory-middle';
    }

    //ryanlog: //console.log(settings);

    const gui = node.gui;
    
    // component
    //----------------------------------------------------------------------------------------------------

    return (

        <>
            { typeof node.gui !== 'undefined' ? 

                <div className={'node-middle'}>

                    {/* map each group in the gui groups list */}
                    { Object.keys(gui).map((groupId) => {

                        const group = gui[groupId];
                        const elements = group.elements;
                        const direction = group.group;

                        return (
                            <div className={direction === 'horizontal' ? 'horizontal-group' : 'vertical-group'} key={node.id + '_' + groupId}>

                                {/* map each setting to its appropriate gui element */}
                                { elements.map((element, key) => {

                                    const type = element.element;
                                    const setting = element.setting;

                                    // Only show advanced setting under certain circumstances
                                    if ( element.advanced && !node.settings.showAdvanced) {return null; }

                                    // Check if there is a display condition
                                    if (element.displayCondition) {

                                        // Inits for clarity
                                        let settingValue = node.settings[element.displayCondition.setting];
                                        let compareValues = element.displayCondition.value;

                                        // Make sure values are an array
                                        if (!Array.isArray(compareValues)) {
                                            compareValues = [compareValues]
                                        }

                                        // Check if the settings value matches any of the requirements
                                        let displayElement = false;
                                        for (let value of compareValues) {
                                            if (settingValue === value) {
                                                displayElement = true;
                                                break;
                                            }
                                        }

                                        // Don't display element if it doesn't match the conditions
                                        if (!displayElement) {
                                            return null;
                                        }
                                    }

                                    return (
                                        <React.Fragment key={node.id + '_' + setting}>
                                            {/* new elements */}
                                            { type === 'toggleShowAdvanced' ? <ToggleShowAdvanced element={element} value={node.settings[setting]} nodeId={nodeId} />: null}
                                            { type === 'checkbox' ? <CheckBox element={element} value={node.settings[setting]} nodeId={nodeId} />: null}
                                            { type === 'numberbox' ? <NumberBox element={element} value={node.settings[setting]} nodeId={nodeId} />: null}
                                            { type === 'slider' ? <Slider element={element} value={node.settings[setting]} nodeId={nodeId} />: null}
                                            { type === 'textbox' ? <TextBox element={element} value={node.settings[setting]} nodeId={nodeId} />: null}
                                            { type === 'dropdown' ? <DropDown element={element} value={node.settings[setting]} nodeId={nodeId} />: null}
                                            { type === 'textarea' ? <TextArea element={element} value={node.settings[setting]} nodeId={nodeId} dimensions={settings['textBoxDimensions']} />: null}
                                            { type === 'chatMessages' ? <ChatMessages element={element} value={node.settings[setting]} nodeId={nodeId} dimensions={settings['textBoxDimensions']} />: null}
                                            { type === 'logViewer' ? <LogViewer element={element} value={node.logs} nodeId={nodeId} />: null}

                                            {/* legacy elements */}
                                            { type === 'nodeTextArea' ? <TextContentBox nodeId={nodeId} setting={element.setting} value={node.settings[setting]} dimensions={settings['textBoxDimensions']} /> : null }
                                            { type === 'stopSequencesTextbox' ? <StopSequencesTextbox nodeId={nodeId} setting={element.setting} value={settings[element.setting]} />: null }
                                            { type === 'imageDisplay' ? <ImageDisplay nodeId={nodeId} setting={element.setting} value={settings['defaultImage']} size={settings['size']} images={settings['defaultImage']} /> : null }

                                            {/* deprecated elements */}
                                            { !type && setting === 'nodeTextArea' ? <TextContentBox nodeId={nodeId} setting={'defaultTextContent'} value={node.memoryContents || settings['defaultTextContent']} dimensions={settings['textBoxDimensions']} /> : null }
                                            { !type && setting === 'memoryTextContent'? <TextContentBox nodeId={nodeId} setting={'memoryTextContent'} value={node.memoryContents || settings['memoryTextContent']} dimensions={settings['textBoxDimensions']} /> : null }
                                            { !type && setting === 'customCode' ? <CustomCodeBox nodeId={nodeId} setting={'customCode'} value={settings['customCode']} dimensions={settings['textBoxDimensions']} /> : null }
                                            { !type && setting === 'inputVariable' ? <InputVariableSelect nodeId={nodeId} setting={'inputVariable'} value={settings['inputVariable']} /> : null }
                                            { !type && setting === 'outputVariable' ? <OutputVariableSelect nodeId={nodeId} setting={'outputVariable'} value={settings['outputVariable']} /> : null }
                                            { !type && setting === 'modelName' ? <ModelSelect nodeId={nodeId} setting={'modelName'} value={settings['modelName']} label={'AI Model'} /> : null }
                                            { !type && setting === 'maxTokens' ? <MaxTokensTextbox nodeId={nodeId} setting={'maxTokens'} value={settings['maxTokens']} label={'Max tokens'} /> : null }
                                            { !type && setting === 'temperature' ? <ModelParamSlider nodeId={nodeId} setting={'temperature'} value={settings['temperature']} />: null }
                                            { !type && setting === 'frequencyPenalty' ? <ModelParamSlider nodeId={nodeId} setting={'frequencyPenalty'} value={settings['frequencyPenalty']} />: null }
                                            { !type && setting === 'presencePenalty' ? <ModelParamSlider nodeId={nodeId} setting={'presencePenalty'} value={settings['presencePenalty']} />: null }
                                            { !type && setting === 'stopSequences' ? <StopSequencesTextbox nodeId={nodeId} setting={'stopSequences'} value={settings['stopSequences']} />: null }
                                            { !type && setting === 'useNewLine' ? <SettingCheckbox nodeId={nodeId} setting={'useNewLine'} value={settings['useNewLine']} /> : null }
                                            { !type && setting === 'number' ? <NumberInputTextbox nodeId={nodeId} setting={'number'} value={settings['number']} label={'number'} /> : null }
                                            { !type && setting === 'size' ? <SelectDropdown nodeId={nodeId} setting={'size'} value={settings['size']} options={settings['availableSizes']} label={'size'} /> : null }
                                            { !type && setting === 'responseFormat' ? <SelectDropdown nodeId={nodeId} setting={'responseFormat'} value={settings['responseFormat']} options={settings['availableResponseFormats']} label={'response format'} /> : null }
                                            { !type && setting === 'defaultImage' ? <ImageDisplay nodeId={nodeId} setting={'defaultImage'} value={settings['defaultImage']} size={settings['size']} images={settings['defaultImage']} /> : null }

                                            {/* astica */}
                                            { !type && setting === 'modelVersion' ? <SelectDropdown nodeId={nodeId} setting={'modelVersion'} value={settings['modelVersion']} options={settings['availableModelVersions']} label={'Model version'} /> : null }
                                            { !type && setting === 'mode' ? <SelectDropdown nodeId={nodeId} setting={'mode'} value={settings['mode']} options={settings['availableModes']} label={'mode'} /> : null }
                                        </React.Fragment>
                                    )
                                } )}
                            </div>
                        )
                    })}
                </div>
            : null }
        </>
    );
}