// Modules
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFetch } from '../../../utils/useFetch.js';

// Components
import UsageChart from './UsageChart/UsageChart.js';
import RecentsList from './RecentsList/RecentsList.js';

// Styles
import './Usage.css';

// Component
export default function Usage() {

    // Init userData and userName
    let userData = useSelector((state) => state.user.user);
    let userName = userData ? userData.userName : null;

    // Fetch usage data
    //----------------------------------------------------------------------------------------------------

    // Define fetch instance
    let fetchUsageData = useFetch();

    // State for fetched data
    const usageData = useSelector((state) => state.request.usageData);
    const [fetchedData, setFetchedData] = useState(null);

    // Fetching function
    const fetchData = async () => {
        if(userName) {
            console.log('fetching data');
            fetchUsageData('GET', `users/usage/${userName}`, {key: 'usageData'});
        }
    }

    useEffect(() => {

        if (usageData === 'loading' || !usageData) {
            return;
        }

        setFetchedData(usageData);

    }, [usageData]);

    // Refresh data when event is triggered
    //----------------------------------------------------------------------------------------------------

    useEffect(() => {
        let timeoutId = null;
    
        const handleUpdate = () => {
            if (timeoutId !== null) {
                clearTimeout(timeoutId);
            }
    
            timeoutId = setTimeout(() => {
                fetchData();
            }, 5000);
        };

        const handleRefresh = () => {
            fetchData();
        };
    
        handleRefresh();
    
        window.addEventListener('refreshUsageHistory', handleUpdate);
        return () => {
            window.removeEventListener('refreshUsageHistory', handleUpdate);
            if (timeoutId !== null) {
                clearTimeout(timeoutId);
            }
        };
    }, []);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={`usage-container`}>
            <div className={'usage'}>

                {fetchedData?.usage ?
                <>
                    <div className={'usage-section-label'}>Usage by Day</div>
                    <UsageChart days={fetchedData?.usage}/>
                </>
                : null}

                {fetchedData?.recent ?
                <>
                    <div className={'usage-section-label'}>Recent Usage</div>
                    <RecentsList recents={fetchedData?.recent}/>
                </>
                : null}

            </div>
        </div>
    );
}
