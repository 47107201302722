// Icon
import icon from './icon.svg';

// Util
import { util } from '../../../nodeMapProcessing/util.js';

const title = 'Input';
const description = '';

// JSON template
//----------------------------------------------------------------------------------------------------

// Node JSON template
const template = {
      
	category: "control",
    name: "input",
    type: "input",
    position: null,

    settings: {
        inputVariable: null,
    },

    gui: [
        {
            group: 'horizontal',
            elements: [
                {setting: 'inputVariable'}
            ]
        }
    ],

    contents: null,

    inputs: {
        "Input Variable": {
            name: null,
            type: null,
            contents: null,
            invisible: true,
        }
    },

    outputs: {
        "Output 1": {
            name: null,
            type: null,
            contents: null,
            destinations: {},
            clearOnSend: true
        }
    }
}

// Processing function
//----------------------------------------------------------------------------------------------------

class process {

    // Callbacks
    //--------------------------------------------------

    constructor() {

        this.onUpdateCallback =     () => { console.warn('no onUpdate callback')};
        this.onStreamCallback =     () => { console.warn('no onStream callback')};
        this.onCompleteCallback =   () => { console.warn('no onComplete callback')};
        this.onWarningCallback =    () => { console.warn('no onWarning callback')};
        this.onErrorCallback =      () => { console.warn('no onError callback')};
        this.onGiveUpCallback =     () => { console.warn('no onGiveUp callback')};

    }

    // Set callback functions
    onUpdate(callback) { this.onUpdateCallback = callback; }
    onStream(callback) { this.onStreamCallback = callback; }
    onComplete(callback) { this.onCompleteCallback = callback; }
    onWarning(callback) { this.onWarningCallback = callback; }
    onError(callback) { this.onErrorCallback = callback; }
    onGiveUp(callback) { this.onGiveUpCallback = callback; }

    // Fire functions easily
    update(node) { this.onUpdateCallback(node); }
    stream(node) { this.onStreamCallback(node); }
    finish(node) { this.onCompleteCallback(node); }
    warn(message) { this.onWarningCallback(message); }
    error(message) { this.onErrorCallback(message); }
    giveUp(message) { this.onGiveUpCallback(message); }


    // Run node
    //--------------------------------------------------

    // Node processing function
    run (node, extras) {

        try {

            const nodeMap = extras.nodeMap;

            const inputVariableId = node.settings?.inputVariable;
            const inputVariable = nodeMap.io?.inputVariables?.[inputVariableId];

            // If there's no inputVariableId
            if (!inputVariableId) {
                this.warn('warning: no input variable selected');
                this.giveUp();
                return;
            }

            // If there's no inputVariable
            if (!inputVariable) {
                this.warn(`could not find input variable '${inputVariableId}' in node map`);
                this.giveUp();
                return;
            }

            // Set contents as input variable contents, continue
            util.setContents(node, inputVariable.contents);
            util.clearInputs(node);
            util.fillOutputs(node);
            this.finish(node);

            return;

        }

        catch (e) {
            this.error(e.message);
            this.giveUp();
            return;
        }
    }
}

// Export
//----------------------------------------------------------------------------------------------------

export const input = {
    template,
    process,
	icon,
	title,
	description
}