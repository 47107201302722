import { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateNodeSetting } from '../../../../../../redux/slices/nodeMapSlice.js';

export default function ModelParamSlider({nodeId, setting, value}) {

    const dispatch = useDispatch();
    
    // defined functions
    //----------------------------------------------------------------------------------------------------

    function handleChangeNodeSetting(e) {
        const newValue = parseFloat(e.currentTarget.value);
        dispatch(updateNodeSetting({nodeId, setting, newValue}));
    }


    // component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={'model-param-slider-container'}>

            <label className={'node-setting-label'}>{setting + ': ' + value}</label>

            { setting === 'temperature' ?
                <input
                    type={'range'}
                    
                    min={'0'}
                    max={'2'}
                    step={'0.1'}

                    className={'model-param-slider'}
                    title="Maximum number of tokens to use"
                    value={value}

                    onChange={handleChangeNodeSetting}
                ></input>
            : null }

            { setting !== 'temperature' ?
                <input
                    type={'range'}
                    
                    min={'-2'}
                    max={'2'}
                    step={'0.1'}

                    className={'model-param-slider'}
                    title="Maximum number of tokens to use"
                    value={value}

                    onChange={handleChangeNodeSetting}
                ></input>
            : null }
        </div>
    );
}