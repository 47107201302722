//modules
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateNodePosition, deleteNode } from '../../../../redux/slices/nodeMapSlice.js';

//component
import NodeInputs from './NodeInputs/NodeInputs';
import NodeMiddle from './NodeMiddle/NodeMiddle';
import NodeOutputs from './NodeOutputs/NodeOutputs';
import LogViewer from './NodeMiddle/SettingElements/LogViewer/LogViewer.js';

//resources
import { nodes } from '../../../../nodes/nodes.js';
import deleteIconDark from './deleteIconDark.svg';

//styles
import './SandboxNode.css'

export default function SandboxNode({ nodeId, node, viewportPosition, viewportZoom }) {

	const dispatch = useDispatch();

	// Node state and message
	//----------------------------------------------------------------------------------------------------

	let state = node.state;
	let message = node.message;

	// dragging
	//----------------------------------------------------------------------------------------------------

	const viewportZoomRef = useRef(viewportZoom);
	viewportZoomRef.current = viewportZoom || 1.00;

	const nodeNameRef = useRef(null);
	const menuRef = useRef(null);

	//various drag variables
	const [position, setPosition] = useState(node.position);
	const dragging = useRef(false);
	const dragStartPos = useRef({ x: 0, y: 0 });
	const dragPos = useRef({ x: 0, y: 0 });
	const dragOffset = useRef({ x: 0, y: 0 });
	const nodeStartPos = useRef(position);
	const boundary = 40;

	const [zIndex, setZIndex] = useState(1);

	const renderPosition = { x: position.x - viewportPosition.x, y: position.y - viewportPosition.y };

	const nodeRef = useRef();

	//process dragging as it happens
	useEffect(() => {

		menuRef.current = document.querySelector('.menu-content-and-save-button-container');

		const handleMouseDown = (e) => {

			if (isElementOrChild(e.target, nodeNameRef.current) && e.button === 0) {

				dragging.current = true;
				nodeStartPos.current = position;
				dragPos.current = getMousePos(e);
				dragStartPos.current = dragPos.current;

				////console.log('Mouse Down: ', dragStartPos.current);

				setZIndex(2);
			} else {
				setZIndex(1);
			}
		}

		const handleMouseMove = (e) => {

			// Must be dragging over node or sandbox. Not menu or IO panel.

			if (!isNodeOrChild(e.target) && e.target !== document.getElementsByClassName("sandbox-nodes")[0]) {
				return;
			}

			if (dragging.current && e.button === 0) {

				dragPos.current = getMousePos(e);
				dragOffset.current = getDragOffset(dragStartPos.current, dragPos.current);

				const newPosition = {
					x: nodeStartPos.current.x + dragOffset.current.x,
					y: nodeStartPos.current.y + dragOffset.current.y
				}

				// Update the position while dragging
				setPosition(newPosition);

				const redrawConnectionsEvent = new CustomEvent('redrawConnections');
				window.dispatchEvent(redrawConnectionsEvent);
			}
		};

		const handleMouseUp = (e) => {

			if (dragging.current && e.button === 0) {

				//get drag stuff
				dragPos.current = getMousePos(e);
				dragOffset.current = getDragOffset(dragStartPos.current, dragPos.current);

				//calculate new position
				let newPosition = position;

				//reset all variables
				dragStartPos.current = { x: 0, y: 0 };
				dragPos.current = { x: 0, y: 0 };
				dragOffset.current = { x: 0, y: 0 };

				//set dragging false and set new position
				dragging.current = false;
				setPosition(newPosition);
				dispatch(updateNodePosition({ nodeId, position: newPosition }));
			}
		};

		window.addEventListener('mousedown', handleMouseDown);
		window.addEventListener('mousemove', handleMouseMove);
		window.addEventListener('mouseup', handleMouseUp);

		return () => {
			window.removeEventListener('mousedown', handleMouseDown);
			window.removeEventListener('mousemove', handleMouseMove);
			window.removeEventListener('mouseup', handleMouseUp);
		};

	}, [position]);

	//dragging functions

	function getMousePos(e) {

		const sandbox = document.getElementsByClassName('sandbox-zoom-container')[0];

		if (!sandbox) {
			return { x: 0, y: 0 }
		}

		const sandboxRect = document.getElementsByClassName('sandbox-zoom-container')[0].getBoundingClientRect();

		// RPM: I have no idea why the 0.5 is needed, but it is needed.
		return {
			x: (e.clientX) / viewportZoomRef.current,
			y: (e.clientY) / viewportZoomRef.current
		}
	}

	function getDragOffset(a, b) {
		return { x: b.x - a.x, y: b.y - a.y };
	}

	function getDragSum(a, b) {
		return { x: b.x + a.x, y: b.y + a.y };
	}

	function isElementOrChild(target, element) {
		return (element.contains(target));
	}

	function isNodeOrChild(target) {
		return (nodeRef.current.contains(target));
	}

	// Progress bar style
	//----------------------------------------------------------------------------------------------------

	const progressBarRef = useRef(null);

	const progressBar = progressBarRef.current;
	const meanTime = node.stats?.processing?.meanTime || 1;

	if (progressBar) {

		if (state === 'processing') {
			progressBar.style.width = '100%';
			progressBar.style.transition = `width linear ${meanTime / 1000}s`;
		} else if (state === 'processed') {
			progressBar.style.transition = ``;
			progressBar.style.width = '100%';
		} else {
			progressBar.style.width = '0%';
			progressBar.style.transition = '';
		}
	}

	// Defined functions
	//----------------------------------------------------------------------------------------------------

	function handleDeleteNode(e) {
		//console.log(`Deleting ${nodeId}!`);
		dispatch(deleteNode({ nodeId }));
	}

	// Message handling
	//----------------------------------------------------------------------------------------------------

	if (node.type === 'input' && !node.settings.inputVariable) {
		state = 'warning';
		message = 'No input variable selected';
	}

	if (node.type === 'output' && !node.settings.outputVariable) {
		state = 'warning';
		message = 'No input variable selected';
	}

	// Icon Display
	//----------------------------------------------------------------------------------------------------

	const icon = nodes[node.type].icon;
	const showIcon = node.settings.showIcon;

	// Component
	//----------------------------------------------------------------------------------------------------

	return (
		<div ref={nodeRef} className={`node-container`} style={{ transform: `translate(${renderPosition.x}px, ${renderPosition.y}px)`, zIndex: zIndex }}>

			<div className={'node'}>
				<div className={'node-name'} ref={nodeNameRef}>

					{showIcon ? <img className={'node-name-icon'} src={icon} alt={''} draggable={'false'}></img> : null}

					<div className={'node-name-text'}>{node.name}</div>

					{node.settings.infoLink ?
						<a className={'node-info-link'} href={node.settings.infoLink} target="_blank" rel="noopener noreferrer">about</a>
						: null}

					<img
						className={'node-delete-button'}
						src={deleteIconDark}
						alt={''}
						onClick={handleDeleteNode}
					></img>
				</div>

				<div className={

					`node-body
				${state === 'processing' ? 'node-body-pending' : ''}
				${state === 'processed' ? 'node-body-processed' : ''}
				${state === 'error' ? 'node-body-error' : ''}
				${state === 'streaming' ? 'node-body-streaming' : ''}
			`}>

					<div className={'node-body-upper'}>
						<NodeInputs
							nodeId={nodeId}
							inputs={node.inputs}
							editable={node.settings?.canEditInputs}
						/>

						<NodeMiddle nodeId={nodeId} node={node} type={node.type} settings={node.settings} />

						<NodeOutputs
							nodeId={nodeId}
							outputs={node.outputs}
							editable={node.settings?.canEditOutputs}
						/>
					</div>

					<div className={'node-body-lower'}>

						{state === 'processing' ?
							<div className={'node-progress-bar-container'}>
								<div className={'node-progress-bar'} ref={progressBarRef}></div>
							</div>
							: null}

						{state === 'streaming' ?
							<div className={'node-progress-bar-container'}>
								<div className={'node-progress-bar-marching'}></div>
							</div>
							: null}

					</div>

				</div>

				{message ?
					<div className={'node-message-container-relative'}>
						<div className={'node-message-container-absolute'}>
							{state === 'warning' ? <div className={'node-warning-message'}>{message}</div> : null}
							{state === 'error' ? <div className={'node-error-message'}>{message}</div> : null}
							{state === 'processed' ? <div className={'node-generic-message'}>{message}</div> : null}
						</div>
					</div>
				: null}

				{node.settings.showLogs ?
					<div className={'log-viewer-container'}>
						<LogViewer logs={node.logs} />
					</div>
				: null}

			</div>
		</div>
	);
}