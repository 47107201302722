// Modules
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addNode } from '../../../../redux/slices/nodeMapSlice.js';

// Tooltip
import ToolTipComponent from '../../../../tooltip/ToolTipComponent.js';

// Resources
import chevronLight from './chevronLight.svg';
import chevronDark from './chevronDark.svg';

// Audio model nodes
import { audioNodes } from '../../../../nodes/nodes.js';

// Styles
import './AudioModelNodes.css';

export default function AudioModelNodes() {

    const dispatch = useDispatch();

    // component logic
    //----------------------------------------------------------------------------------------------

    const [expanded, setExpanded] = useState(true);
    useEffect( () => {}, [expanded]);


    // defined functions
    //----------------------------------------------------------------------------------------------

    function handleAddNode(e) {
        let template = e.currentTarget.getAttribute('template');
        ////console.log(`Button: Adding ${type} node!`);
        dispatch(addNode({template}));
    }

    const nodes = audioNodes;

    // component structure
    //----------------------------------------------------------------------------------------------

    return (
        <div className={'edit-menu-section-container'}>

            <div className={'edit-menu-section-label'} onClick={() => setExpanded(!expanded)}>
                <div className={'edit-menu-section-label-line'}></div>
                <img className={`edit-menu-section-label-icon ${!expanded ? 'edit-menu-section-label-icon-collapsed' : ''}`} src={chevronDark} alt={''}></img>
                <div className={'edit-menu-section-label-text'}>Audio Models</div>
                <ToolTipComponent tooltip={'controlNodes'}/>
                <div className={'edit-menu-section-label-line'}></div>
            </div>

            <div className={`edit-menu-section-expanded ${!expanded ? 'edit-menu-section-collapsed' : ''}`}>
                
                { Object.keys(nodes).map( (key) => {

                    const node = nodes[key];
                    const templateId = key;
                    const category = node.template.category;
                    const title = node.title;
                    const description = node.description;
                    const icon = node.icon;

                    return (
                        <button className={`add-${category}-node-button`} template={key} title={`Add ${title} node`} onClick={handleAddNode} key={key}>
                            <img className={'add-language-node-button-icon'} src={icon} alt={''}></img>
                            <div className={'add-language-node-button-text'}>{title}</div>
                        </button>
                    )
                })}

            </div>
        </div>
    );
}