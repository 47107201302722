// Modules
import { useDispatch } from 'react-redux';
import { updateIoVariableContents } from '../../../../redux/slices/nodeMapSlice.js'

// Components
import AudioPlayer from './AudioPlayer/AudioPlayer.js';

// Resources
import audioIcon from './audioIcon.svg'

export default function IoAudioField({variable, ioType}) {

    // Update nodemap variable value
    //----------------------------------------------------------------------------------------------

    const dispatch = useDispatch();

    function handleUpdateValue(e) {

        //console.log(e.currentTarget.files[0]);

        const audio = e.currentTarget.files[0];
        let audioUrl = null;

        // Sometimes this fails when canceling
        try { audioUrl = URL.createObjectURL(audio); } catch { return; }

        const variableName = variable.name;
        const contents = audioUrl;

        dispatch(updateIoVariableContents({ioType, variableName, contents}))
    }

    function handleClearIoVariableContents(e) {

        const variableName = variable.name;
        const contents = null;

        dispatch(updateIoVariableContents({ioType, variableName, contents}))
    }

    // Component logic
    //----------------------------------------------------------------------------------------------

    console.log(structuredClone(variable))

    let audio = variable.contents || null;

    // Component
    //----------------------------------------------------------------------------------------------

    return (
        <>
            <div className={'io-variable-field-label-and-clear-button'}>
                <div className={'io-variable-field-label'}>{variable.name || ''}</div>
                <div className={'io-variable-clear-button'} onClick={handleClearIoVariableContents}>clear</div>
            </div>

            <div className={'audio-input-field-container'}>

                {!audio && ioType === 'input' ? <div className={'audio-input-field-placeholder'}> <img className={'audio-input-field-placeholder-icon'} src={audioIcon} alt={''}></img> Upload audio </div> : null}

                {!audio && ioType === 'output' ? <div className={'audio-input-field-placeholder'}> <img className={'audio-input-field-placeholder-icon'} src={audioIcon} alt={''}></img> Audio Displays Here... </div> : null}

                { audio ? <AudioPlayer audioUrl={audio}/> : null }

                { ioType === 'input' && !audio ? <input className={'audio-input-field-upload'} type={'file'} accept={'audio/*'} variablename={variable.name} iotype={ioType} onChange={handleUpdateValue}></input> : null }
            </div>
        </>
    );

}