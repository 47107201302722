 import { Link } from "react-router-dom";

 export default function UserProfileNotLoggedIn() {

 return (
        <div className={'user-guest'}>
            <Link className={'button-link'} to="/login"><button className={'login-button'}>Login</button></Link>
            <Link className={'button-link'} to="/register"><button className={'register-button'}>Register</button></Link>
        </div>
    );

 };