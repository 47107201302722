//Modules
import { useDispatch } from 'react-redux';
import { updateIoVariableContents } from '../../../../redux/slices/nodeMapSlice.js'

// Resources
import imageIcon from './imageIcon.svg';

export default function IoImageField({variable, ioType}) {

    // Update nodemap variable value
    //----------------------------------------------------------------------------------------------

    const dispatch = useDispatch();

    function handleUpdateValue(e) {

        //console.log(e.currentTarget.files[0]);

        const image = e.currentTarget.files[0];
        let imageUrl = null;

        // Sometimes this fails when canceling
        try { imageUrl = URL.createObjectURL(image); } catch { return; }

        const variableName = variable.name;
        const contents = imageUrl;

        dispatch(updateIoVariableContents({ioType, variableName, contents}))
    }

    function handleClearIoVariableContents(e) {

        const variableName = variable.name;
        const contents = null;

        dispatch(updateIoVariableContents({ioType, variableName, contents}))
    }

    // Component logic
    //----------------------------------------------------------------------------------------------

    let image = variable.contents || null;


    // component
    //----------------------------------------------------------------------------------------------

    return (
        <>
            <div className={'io-variable-field-label-and-clear-button'}>
                <div className={'io-variable-field-label'}>{variable.name || ''}</div>
                <div className={'io-variable-clear-button'} onClick={handleClearIoVariableContents}>clear</div>
            </div>

            <div className={'image-input-field-container'}>
                {!image && ioType === 'input' ? <div className={'image-input-field-placeholder'}> <img className={'image-input-field-placeholder-icon'} src={imageIcon} alt={''}></img> Upload Image </div> : null}
                
                {!image && ioType === 'output' ? <div className={'image-input-field-placeholder'}>Image displays here...</div> : null}
                
                { image ? <img className={'image-input-field-image'} src={image} alt={''}></img> : null }
                { ioType === 'input' ? <input className={'image-input-field-upload'} type={'file'} accept={'image/*'} variablename={variable.name} iotype={ioType} onChange={handleUpdateValue}></input> : null }
            </div>
        </>
    );

}