 import { useState, useEffect, useRef } from 'react';
 // import { useDispatch } from 'react-redux';
 import { Link, useNavigate } from 'react-router-dom';
 import { useFetch } from '../../../utils/useFetch';

 import UserBalanceDisplay from './UserBalanceDisplay/UserBalanceDisplay.js';

 export default function UserProfileLoggedIn({user}) {

    const userName = user ? user.userName : 'Guest';
    const color = user ? user.color : "rgb(37, 195, 121)";
    const accountBalance = user ? user.accountBalance : null;

    const [menuVisible, setMenuVisible] = useState(false);
    const menuRef = useRef(null);

    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const fetchLogout = useFetch();
    

    ////console.log('menu visible', menuVisible);

    useEffect(() => {

        const handleOutsideClick = (e) => {
            ////console.log(menuVisible, menuRef.current, e.target);

            if(e.target !== menuRef.current) {
                ////console.log('not equal');
                setMenuVisible(false);
                return;
            }

            // handled by handleMenuClick
            // if(e.target === menuRef.current) {
            //     //console.log('handled by handleMenuClick');
            //     return;
            // }
            

            // if no menu do nothing on click
            // if(!menuRef.current) {
            //     //console.log('if no menu do nothing on click');
            //     return;
            // }


            // check if click happened outside of the menu
            // if(!menuListRef.current.contains(e.target)) {
            //     //console.log('check if click happened outside of the menu');
            //     setMenuVisible(false);
            //     return;
            // }
    }



    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);

  }, []);




    const handleMenuClick = () => {
        setMenuVisible(!menuVisible);
    };


    const handleMenuItemClick = (item) => {
        //console.log('Menu item clicked:', item);

        if(item === 'Logout') {

            let url = 'users/logout';
            fetchLogout('POST', url, { key: 'logoutData'});
        }

        if(item === 'Profile') {
            navigate('/profile');
        }

        setMenuVisible(false);
    };


    return (
    <>
        <UserBalanceDisplay />
        <div
        className="user-profile"
        ref={menuRef}
        style={{ backgroundColor: color }}
        onClick={handleMenuClick}
        >
        {(userName || 'T').toUpperCase()[0]}
        {menuVisible && (
        <ul className="user-menu show">
        <li
            className="user-menu-item"
            onClick={() => handleMenuItemClick('Profile')}
        >
            Profile
        </li>
        <li
            className="user-menu-item"
        onClick={() => handleMenuItemClick('Logout')}
        >
            Logout
        </li>
        </ul>
        )}
        </div>
    </>
    );

 };