import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUpToDate } from '../../../redux/slices/nodeMapSlice.js';
import useSnackbar from "../../../SnackBar/useSnackBar.js";
import { useNavigate, useParams } from "react-router-dom";
import { fetchWrapper } from '../../../utils/fetchWrapper.js'
import { nodeMapUtils } from '../../../nodeMapProcessing/nodeMapUtils.js';

import './SaveButton.css';

export default function SaveButton() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const upToDate = useSelector(state => state.nodeMap.upToDate);
    const nodeMap = useSelector(state => state.nodeMap.nodeMap);

    let user = useSelector((state) => state.user.user);

    let outOfDate = "";
    if (!upToDate) {
        outOfDate = "save-button-out-of-date"
    }

    useEffect(() => {
        const handleKeyDown = async (e) => {
            if (e.ctrlKey && e.key === 's') {
                e.preventDefault();
                await handleClick();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <button className={ upToDate ? 'save-button' : 'save-button-out-of-date' } onClick={handleClick}>Save</button>
    );

    async function handleClick () {

        if(!user) {
            snackbar('You need an account to save nodemaps', 'error');
            return;
        }

        if(upToDate) {
            return;
        }

        const nodeMapCopy = structuredClone(nodeMap);
        nodeMapUtils.resetNodes(nodeMapCopy.nodes);

        //make request to server with new nodeMap
        //console.log('Nodemap: ', nodeMap);
        let url = 'nodeMaps/updateNodeMap';
        let req = await fetchWrapper('POST', url, {user, nodeMap: nodeMapCopy});

        // If the save was sucessful
        if (req.success) {

            dispatch(setUpToDate({upToDate: true}));
            snackbar('Nodemap saved', 'success');

            //Navigate to new nodeMap if present
            if (req.nodeMapId) {
                navigate('/sandbox/'+req.nodeMapId);
            }

            return true;
        }

    };

}