import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

// const ProtectedRoutes = () => {
//  const loggedInStatus = useSelector((state) => state.user.loggedIn);

//   return !loggedInStatus ? <Outlet/> : <Navigate to="/login" replace />;
// }

const ProtectedRoutes = () => {
  const loggedInStatus = useSelector((state) => state.user.loggedIn);
 
   return !loggedInStatus ? <Outlet/> : <Navigate to="/" replace />;
 }

export default ProtectedRoutes;