//modules
import { useState, useEffect, useRef, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateNodeInputName, updateNodeInputRequired, deleteNodeInput, addNodeConnection, deleteNodeConnection } from '../../../../../../redux/slices/nodeMapSlice';

//context
import SandboxContext from '../../../../SandboxContext';

//resources
import deleteIconDark from './deleteIconDark.svg';
import deleteIconLight from './deleteIconLight.svg';

// Styles
import './NodeInput.css';

export default function NodeInput({nodeId, inputId, input, editable}) {

    // effects
    //----------------------------------------------------------------------------------------------------

    const nodeMap = useSelector((state) => state.nodeMap?.nodeMap);

    let dispatch = useDispatch();

    const inputNameRef = useRef(null);

    useEffect(() => {
        const input = inputNameRef.current;
        if (!input) { return; }
        input.style.width = input.value.length + 2 + 'ch';
    });

    // connection dragging functionality
    //----------------------------------------------------------------------------------------------------

    const { dragConnectionState, setDragConnectionState } = useContext(SandboxContext);

    function handleMarkerMouseDown(e) {

        // Left click = set drag connection state with origin = origin and target = null.
        if (e.button === 0) {
            let newDragConnectionState = {...dragConnectionState};
            //console.log(nodeMap);

            // Init target and origin
            let origin = null;
            let target = {nodeId: nodeId, inputId: inputId};

            // Find origin for this target
            // nodeMap -> nodes -> outputs -> destinations -> target ( does it match? )
            for (let nodeId in nodeMap.nodes) { const node = nodeMap.nodes[nodeId];
                for (let outputId in node.outputs) { const output = node.outputs[outputId];
                    for (let destinationId in output.destinations) { const destination = output.destinations[destinationId];
                        if (JSON.stringify(destination.target) === JSON.stringify(target)) {
                            origin = {nodeId: nodeId, outputId: outputId};
                        }
                    }
                }
            }

            newDragConnectionState.draggingConnection = true;
            newDragConnectionState.origin = origin;
            newDragConnectionState.target = target;

            setDragConnectionState(newDragConnectionState);
            dispatch(deleteNodeConnection({ origin, target }));
        }
    }

    //mouse up on a marker sets the connection, if requireContent
    function handleMarkerMouseUp(e) {

        if (dragConnectionState.draggingConnection) {

            let newDragConnectionState = {...dragConnectionState};
            newDragConnectionState.draggingConnection = true;
            newDragConnectionState.target = {nodeId, inputId};

            ////console.log(newDragConnectionState);

            const origin = newDragConnectionState.origin;
            const target = newDragConnectionState.target;
                
            dispatch(addNodeConnection({ origin, target }));

            setDragConnectionState(newDragConnectionState);
        }
    }

    // component logic
    //----------------------------------------------------------------------------------------------------
    
    const [name, setName] = useState(input.name);
    const [showDeleteButton, setShowDeleteButton] = useState(true);

    const target_id = maketarget_id(nodeId, inputId);

    
    // Changing input mode
    //----------------------------------------------------------------------------------------------------

    function handleUpdateRequired(e) {

        if (!editable) {
            return;
        }

        if (input.requireContent === true ) {
            if (input.allowStreaming === true) {
                dispatch(updateNodeInputRequired({ nodeId, inputId, requireContent: 'stream' }));
                return;
            }

            dispatch(updateNodeInputRequired({ nodeId, inputId, requireContent: false }));
            return;
        }

        if (input.requireContent === 'stream' ) {
            dispatch(updateNodeInputRequired({ nodeId, inputId, requireContent: false }));
            return;
        }

        if (input.requireContent === false ) {
            dispatch(updateNodeInputRequired({ nodeId, inputId, requireContent: true }));
            return;
        }

        dispatch(updateNodeInputRequired({ nodeId, inputId, requireContent: true }));
    }


    // defined functions
    //----------------------------------------------------------------------------------------------------

    function handleUpdateName(e) {
        let newName = e.target.value;
        setName(newName);
        dispatch(updateNodeInputName({nodeId, inputId, newName}));
    }

    function handleDelete(e) {
        dispatch(deleteNodeInput({nodeId, inputId}));
    }

    // example: "Output 1 of Input Node 2"
    function maketarget_id(nodeId, inputId) {
        return inputId + ' of ' + nodeId;
    }

    // component
    //----------------------------------------------------------------------------------------------------

    if (input.invisible) {
        return null;
    }

    return (
        <div className={'node-input'}>
            <div className={'node-input-marker'} title={inputId} target_id={target_id} onMouseDown={handleMarkerMouseDown} onMouseUp={handleMarkerMouseUp}></div>

            {/* Input content requirement button */}
            <div className={'node-input-requirecontent-container'} onClick={handleUpdateRequired} style={{cursor: editable ? 'pointer' : 'no-drop'}}>
                { input.requireContent === true ? <div className={'require-content'} title={'complete input required'}>✓</div> : null}
                { input.requireContent === 'stream' ? <div className={'allow-stream'} title={'partial / streamed data allowed'}>S</div> : null}
                { input.requireContent === false ? <div className={'not-required'} title={'no data required'}>O</div> : null}
            </div>

            {(input.name !== null) ? <input className={'node-input-name'} ref={inputNameRef} value={name} type={'text'} title={'Input Name'} placeholder={input.namePlaceholder || 'name'} onChange={handleUpdateName} disabled={!editable}></input> : null}
            
            {(showDeleteButton && editable) ? 
                <div className={'delete-node-input-button-container'}>
                    <img className={'delete-node-input-button'} src={deleteIconDark} alt={''} title={'Delete Input'} onClick={handleDelete}></img>
                </div>
            : null }
        </div>
    );
}